import axios from 'axios';
import {useState} from 'react';
import {Container} from 'react-bootstrap';
import {Row} from 'react-bootstrap';
import {Form} from 'react-bootstrap';
import {Col} from 'react-bootstrap';
import {getRestAPiUrl,getMailuploadUrl} from "../Utils.js"
import {FloatingLabel,Button} from 'react-bootstrap';
import {Accordion} from 'react-bootstrap';
import { useEffect } from 'react';
import {Toast} from 'react-bootstrap';
import { flushSync } from 'react-dom';
import FilePondUpload from './FileUpload.js'
import { useAuthState } from "./context/AuthContext.js";

export default function Admin() {
  const [error, setError] = useState(false);
  const [body, setBody] = useState();
  const [command, setCommand] = useState();
  const [resultCommand, setResultCommand] = useState();
  const [resultTracks, setResultTracks] = useState();
  const [emailTo, setEmailTo] = useState();
  const [show, setShow] = useState(false);
  const { user, status } = useAuthState();

  console.debug("Status: "+status);
  console.debug("Error: "+error);
  console.debug("User: "+ user);

  const shellCommand = async (event) => {

    event.preventDefault(); // Prevent default submission

        
      const url = getRestAPiUrl + 'shell/'
      let bodyFormData = new FormData();

      setCommand(event.target.command.value)

      bodyFormData.append("command",command)
      bodyFormData.append("userid","1")

      axios.post(url,bodyFormData)
      .then((response) => {
    
       setResultCommand(response.data.strOutput + response.data.strError);
      })
      .then (data =>{
            console.log(data);}
           
      )    
      .catch(error => {console.log(error)})    
    }
  
      
   
  
  const handleUpdateTracks= async (event) => {
    event.preventDefault();
    
    const url = getRestAPiUrl +'tracks/encode'
      axios.post(url)
      .then((response) => {
       return (response.data);
      })
      .then (data =>
        setResultTracks(data))

    .catch(error => {console.log(error)});
  }

  const sendmail = async (event) => {

    event.preventDefault();
  
      let url =getMailuploadUrl;
      let bodyFormData2 = new FormData();
      
      bodyFormData2.append("strRecipients", emailTo)
      bodyFormData2.append("strSubject",emailTo)
      bodyFormData2.append("strBody",body)
      bodyFormData2.append("files", FilePondUpload)
     
axios({
      method: 'post',
      url: url,
      data: bodyFormData2,
      headers: {'Content-Type': 'multipart/form-data',
      "Access-Control-Allow-Origin": "*" }
})
      .then((response) => {
        setShow(true)
      })
      .then (data =>
        { 
          console.log(data);
        } )

    .catch(error => { 
      setError(true)
      
      console.log(error)})
  
    }
    

return (
  
  <Container fluid >
    <Accordion defaultActiveKey="0"  variant="dark">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Shell Command</Accordion.Header>
        <Accordion.Body>
        <Form onSubmit={shellCommand}>
      <Row>
             
              <Col>
                    <FloatingLabel
                        controlId="command"
                        name="command"
                        label="Command"  >

                        <Form.Control type="text" placeholder="...enter command here" name="command" value={command} />
                  </FloatingLabel>
                  </Col>
                  <Col >
                                 
                                 <Button type="submit"  >execute!</Button>
                         </Col>
                  </Row>
              <Row>
                    <Col lg={true}>
                    <FloatingLabel
                        controlId="result"
                        label="result"
                    >
                        <Form.Control type="textarea"  style={{ height: '200px' }}  value={resultCommand} />
                  </FloatingLabel>
                    </Col>
                
                  </Row>
                  </Form>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>encode Tracks</Accordion.Header>
        <Accordion.Body>
        <Form onSubmit={handleUpdateTracks} >
      <Row>
              <Col>
                                 
                      <Button type="submit">Update (encode) Tracks</Button>
              </Col>
                   </Row>
              <Row>
                    <Col>
                    <FloatingLabel
                        controlId="result"
                        label="result"
                   >
                        <Form.Control type="textarea"  style={{ height: '200px' }} placeholder="...enter result here" value={resultTracks} />
                  </FloatingLabel>
                    </Col>
                
                  </Row>
                  </Form>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>send Mail</Accordion.Header>
        <Accordion.Body>
        <Form onSubmit={sendmail} encType="multipart/form-data">
      <Row>
        <Col  lg={true} >          
          <Button type="submit">send email</Button>
          <input type="hidden" value={user.userid} />
          <Toast onClose={() => setShow(false)} show={show} delay={5000} autohide>
            <Toast.Header>
              <img  src="holder.js/20x20?text=%20"   className="rounded me-2"   alt=""    />
              <strong className="me-auto">Bootstrap</strong>
              <small>11 mins ago</small>
            </Toast.Header>
            <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body></Toast>

            <Toast onClose={() => setError(false)} show={error} delay={5000} autohide>
            <Toast.Header>
              <img  src="holder.js/20x20?text=%20"   className="rounded me-2"   alt=""    />
              <strong className="me-error">Bootstrap</strong>
              <small>11 mins ago</small>
            </Toast.Header>
            <Toast.Body>Error Toast</Toast.Body></Toast>
        </Col>
      </Row>
      <Col>
              <FilePondUpload />
              </Col>
                      <Row>
        <Col>
              <FloatingLabel   controlId="recipients"  label="Recipients" >
                        <Form.Control type="input"  onChange={e => setEmailTo(e.target.value)}  placeholder="...enter email adress"  value={emailTo} />
                  </FloatingLabel>
        </Col>
                
      </Row>
      <Row>
        <Col>
              <FloatingLabel   controlId="body"  label="Email Body" >
                        <Form.Control type="input"  onChange={e => setBody(e.target.value)}  placeholder="...enter text message"  value={body} />
                  </FloatingLabel>
        </Col>
                
      </Row>
      
        </Form>
        </Accordion.Body>
      </Accordion.Item>
    
    
    </Accordion>   
     
  </Container>
  
  
  );

} 