import {Container} from 'react-bootstrap';
import {Row} from 'react-bootstrap';
import {Col} from 'react-bootstrap';


function Impressum() {
    return (
        <Container>
        <Row>
          <Col> 
       
          <h2>Impressum</h2>
       
  
        </Col>
    </Row>
    
  
    <Row>
      <Col>
          ..........       
    </Col>
    </Row>
  </Container>
    );
  }
  export default Impressum;