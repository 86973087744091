
import Home from './Home.js';
import About from './About.js';
import App from '../App.js';
import Contact from './Contact.js';
import Register from './Register.js';
import Admin from './Admin.js';
import Upload from './FileUpload.js';
import CanvasAnim from '../CanvasAnim.js';
import UserData from './UserData.js'
import Impressum from './Impressum.js'
import FileMailUpload from './FileMailUpload.js'
import SpringUpload from './SpringUpload.js'
import ErrorBoundary from '../ErrorBoundary.js';
import Dsvgo from './Dsvgo.js';
import {createBrowserRouter } from "react-router-dom";
import MyTracks from "./MyTracks.js";
import MyMap from "./Map.js";

const router = createBrowserRouter([
  {
    element: <App /> ,
    children: [
  { path: "/", element: <Home /> },    
  { path: "/about", element: <About /> },
  { path: "/impressum", element: <Impressum /> },
  { path: "/dsvgo", element: <Dsvgo /> },
  { path: "/contact", element: <Contact /> },
  { path: "/register", element: <Register />},
  { path: "/admin", element: <Admin /> },
  { path: "/userdata", element: <UserData /> },
  { path: "/springupload", element: <SpringUpload /> },
  { path:"/fileupload",element:<Upload/>},
  { path: "/gmaps",element:<MyTracks/> },
  { path: "/mymap",element:<MyMap/> },
  {
     path: "/admin",
        async lazy() {
            // Multiple routes in lazy file
        let { Admin } = await import("./Admin.js");
        return { Component: Admin };
                }
              },
   /* {
        path: "/gmaps",
         lazy() {
                       // Multiple routes in lazy file
                       lazy: () => import("./GoogleMaps.js")
         
     
         }           
          }, */
          {
            path:"/fileupload",
            async lazy() {
              let { FilePondUpload } = await import("./FileUpload.js");
              return { Component: FilePondUpload };
            },
          }
]
  }
]
);


export default router;
     /*    {
          path: "/about",
          // Single route in lazy file
          lazy: () => import("./About.js"),
        },
         {
          path: "/admin",
          async lazy() {
            // Multiple routes in lazy file
            let { Admin } = await import("./Admin.tsx");
            return { Component: Admin };
          }, */
         /*  children: [
            {
              index: true,
              async lazy() {
                let { FilePondUpload } = await import("./FileUpload.js");
                return { Component: FilePondUpload };
              },
            },
            {
              path: "/tracks",
              async lazy() {
                let { Tracks } = await import("./Tracks.tsx" );
                return {Component: Tracks };
              },
            },
          ], */
       /*  }, {
        path: "/contact",
        // Single route in lazy file
        lazy: () => import("./Contact.js")
      }, */
  /*       {
          path: "*",
          element: <Error />,
        }  
    
  ]);
 */



/* const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "/about",
          // Single route in lazy file
          lazy: () => import("./About.js"),
        },
        {
          path: "./admin",
          async lazy() {
            // Multiple routes in lazy file
            let { DashboardLayout } = await import("./Admin.tsx");
            return { Component: Admin };
          },
          children: [
            {
              index: true,
              async lazy() {
                let { FilePondUpload } = await import("./FileUpload.js");
                return { Component: FilePondUpload };
              },
            },
            {
              path: "./tracks",
              async lazy() {
                let { Tracks } = await import("./Tracks.tsx" );
                return {Component: Tracks };
              },
            },
          ],
        },
        {
          path: "*",
          element: <Error />,
        },
      ],
    },
  ]); */
  
