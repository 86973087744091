import GLogin from './GLogin.js';
import {Container} from 'react-bootstrap';
import {Row} from 'react-bootstrap';
import {Col} from 'react-bootstrap';
//import { Suspense } from 'react';
//import Loading from '../Loading.js'

function Home() {
     
    return (
     
        <Container>
          <Row>
            <Col> 
              <h2>Home</h2> 
              <GLogin></GLogin>
            </Col>
          </Row>
        </Container>
    );
  }
       
  export default Home;
