import App from './App.js';
import './App.scss';

import {StrictMode}from "react";
import {googleClientID} from "./Utils.js"
import {GoogleOAuthProvider} from '@react-oauth/google';
import {createRoot} from 'react-dom/client';
import {AuthProvider}  from  "./components/context/AuthContext.js";
import ErrorBoundary  from  "./ErrorBoundary.js";
import {ThemeProvider} from 'react-bootstrap'
import {RouterProvider} from "react-router-dom";
import router from './components/NavigationRoutes.js'
import theme from './Themes.css';
import reportWebVitals from './reportWebVitals.ts';

const domNode = document.getElementById('root')
const root = createRoot(domNode);
domNode.style.display = "none";
  
root.render(    
   
    <StrictMode>

        <AuthProvider>

          <GoogleOAuthProvider clientId={googleClientID} >

              <ThemeProvider
                  breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
                  minBreakpoint="xxs"
                  theme={theme}>
                  
                  <ErrorBoundary fallback={<p>Something went wrong</p>}> 
                      <RouterProvider router={router} >
                        <App />                 
                      </RouterProvider>
                  </ErrorBoundary >

             </ThemeProvider>

          </GoogleOAuthProvider>

        </AuthProvider>

    </StrictMode>
);

setTimeout(() => {
domNode.style.display = "flex";
},1000);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

  
  
  
  
  
  
  
  
  