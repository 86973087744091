import {Nav, Navbar,Image} from "react-bootstrap";
import {Link,useLocation} from "react-router-dom";
import {useAuthState } from "./context/AuthContext.js";
import {Container} from 'react-bootstrap';
import {Row} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {Offcanvas} from 'react-bootstrap'; 
import {NavDropdown} from 'react-bootstrap';
import {Form} from 'react-bootstrap';
import {useEffect} from 'react';

//    Routes !!!

function Navigation()
{   
    const { user, status, error } = useAuthState();
    console.debug("Status: "+status);
    console.debug("Error: "+error);
    console.debug("User: "+ user);
    const location = useLocation();
   

    useEffect(() => {
              console.log('Location changed!', location.pathname);
    }, [location]);
    
return (    
        <>  
    
        <Navbar  expand='false' bg="dark" data-bs-theme="dark" >
          <Container fluid>
          <Navbar.Brand to="/">
              <Image src="../logo.svg" 
              width="50"
               height="50"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"></Image>
              </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-false`}  />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-false`}
              aria-labelledby={`offcanvasNavbarLabel-expand-false`}
              placement="start" 
              data-bs-theme="dark"
              top='70px'
              width='200px'
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-false`}>
                  lazycoelabs.com
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link to="/" as={Link} >Home</Nav.Link>
                <Nav.Link to="/contact" as={Link}>Contact</Nav.Link>
                {user.loginid && 
                    <Nav.Link to="/about" as={Link}>About</Nav.Link>
                }
                {user.loginid && 
                <Nav.Link to="/fileupload" as={Link} >upload</Nav.Link>
                }
                <Nav.Link to="/admin" as={Link}>admin</Nav.Link>
                <Nav.Link to="/register"as={Link} >register</Nav.Link>
                <Nav.Link to="/userdata" as={Link}>userdata</Nav.Link>
                <Nav.Link to="/springupload"as={Link} >Upload</Nav.Link>
                  <NavDropdown
                    title="Test"
                    id={`offcanvasNavbarDropdown-expand-false`}
                  >
                    <NavDropdown.Item > <Nav.Link to="/gmaps"as={Link} >Tracks</Nav.Link></NavDropdown.Item>
                    <NavDropdown.Item>
                    <Nav.Link to="/mymap" as={Link} >TestMap</Nav.Link>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item >
                    <Nav.Link to="/userdata" as={Link} >userdata</Nav.Link>
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button variant="outline-success">Search</Button>
                </Form>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      
    
        </>
      );
    }
    
export default Navigation;
  
   