// Contact.js

import React, { Component } from 'react';
import CarouselFadeExample from './CarouselFade.js';
import {Container} from 'react-bootstrap';
import {Row} from 'react-bootstrap';
import {Col} from 'react-bootstrap';

class Contact extends Component {

    render() {
        return (
           <Container> 
                <h2>Contact</h2>


<CarouselFadeExample></CarouselFadeExample>
</Container>
      );
    }
}

export default Contact;