import React, {useMemo,useState, useEffect} from 'react';
import {APIProvider, Map, useMap} from '@vis.gl/react-google-maps';
import {GeoJsonLayer, ArcLayer} from 'deck.gl';
import {GoogleMapsOverlay as DeckOverlay} from '@deck.gl/google-maps';
import { Polyline } from './gmapcomponents/polyline';
import Toaster from '../Toast.js'
import Tracklist from './Tracklist.js'
import {useAuthState,useAuthDispatch, removeTrack}  from "./context/AuthContext.js";
// source: Natural Earth http://www.naturalearthdata.com/ via geojson.xyz
const AIR_PORTS =
  'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_populated_places.geojson';

// Set your Google Maps API key here or via environment variable
const GOOGLE_MAPS_API_KEY = 'AIzaSyCdovhW2CkZqrEpPyBll8t98WNloW05ibE'; // eslint-disable-line
const GOOGLE_MAP_ID = '7a299c272d194583'; // eslint-disable-line
const chicago = { lat: 41.85, lng: -87.65 };


/* function DeckGLOverlay(props) {
  const map = useMap();
  const overlay = useMemo(() => new DeckOverlay(props));

  useEffect(() => {
    overlay.setMap(map);

    return () => overlay.setMap(null);
  }, [map]);

  overlay.setProps(props);
  return null;
} */

export default function MyMap(props) {
  const {user}= useAuthState();
  const [tracks, setTracks]=useState(user.tbltracks);

  function onPolylineClick(){
console.debug('PolylineClick happened');

  }
    const showInfo=(trackid) =>
    {
      return Toaster();
    }
    
    
    function initMap(){
console.log('initMap on Load')

    }
    
    const onClick = info => {
    if (info.object) {
      // eslint-disable-next-line
      alert(`${info.object.properties.name} (${info.object.properties.abbrev})`);
    }
  };

  const layers = [
    new GeoJsonLayer({
      id: 'airports',
      data: AIR_PORTS ,
      // Styles
      filled: true,
      pointRadiusMinPixels: 2,
      pointRadiusScale: 2000,
      getPointRadius: f => 11 - f.properties.scalerank,
      getFillColor: [200, 0, 80, 180],
      // Interactive props
      pickable: true,
      autoHighlight: true,
      onClick
    }),
    new ArcLayer({
      id: 'arcs',
      data: AIR_PORTS,
      dataTransform: d => d.features.filter(f => f.properties.scalerank < 4),
      // Styles
      getSourcePosition: f => [-0.4531566, 51.4709959], // London
      getTargetPosition: f => f.geometry.coordinates,
      getSourceColor: [0, 128, 200],
      getTargetColor: [200, 0, 80],
      getWidth: 1
    })
  ];

  return (
    <APIProvider apiKey={GOOGLE_MAPS_API_KEY} onLoad={() => initMap()}>
     
      <Map id='map' defaultCenter={chicago}  defaultZoom={14} mapId={GOOGLE_MAP_ID} onLoad='initMap()'  >
        {/*  <DeckGLOverlay layers={layers} />  */}
         {/* <CenterControl></CenterControl>  */}
         <Tracklist tracks={user.tbltracks} ></Tracklist>
         {user.tbltracks.map(track => (
     <Polyline key={'p_'+track.id}
       strokeWeight={10}
       strokeColor={'#ff22cc88'}
       encodedPath={track.google_encoded_polyline}
       onClick={(ev)=>onPolylineClick}
     ></Polyline> 
         ))}
      </Map>
    </APIProvider>
  );
 
  //window.initMap = initMap;
  // [END maps_move_camera_ease]
}
