
import React from 'react';
import  { useState , useRef } from "react";
// Import React FilePond
import { FilePond , registerPlugin, File,files } from "react-filepond";
import  setOptions  from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {getMultipleGPXFileUploadUrl, getRestAPiUrl} from "../Utils.js"
//import getMultipleGPXFileUploadUrl from "../Utils.js"
import {useAuthState} from "./context/AuthContext.js";
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);



export default function FilePondUpload(props) {
 
  const [files, setFiles] = useState([]);
  const {user}= useAuthState();

  let url ;
  let ext;

if (props.extension) ext= props.extension;
if (props.uploadurl) url =props.uploadurl;

const ondata=(formData) => {
  console.log('ondata()');
  formData.append('userid', user.id);
  return formData;
}

const oninit=() => {
 console.log('FilePond oninit()');
 console.log(getMultipleGPXFileUploadUrl);
}
const file = useRef(null);
const onprocessfiles=() => {
  console.log('FilePond onprocessfiles()');
 }
 const onChangefiles=() => {
  console.log('FilePond onprocessfiles()');
 }

    return (
      <div>
        <h2>React FilePond File Upload Example</h2>
        <FilePond
        files={files}
        updatefiles={setFiles(files)}
        allowMultiple={true}
        maxFiles={3}
        server={url}
        name="files"
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
      </div>
    )
}


  