import {useAuthState, useAuthDispatch, doLogout, doLogin } from "./context/AuthContext.js";
import React from 'react';
import  { useState , useRef } from "react";

import { FilePond , registerPlugin, File } from "react-filepond";
import  setOptions  from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import {getMultipleGPXFileUploadUrl, getRestAPiUrl} from "../Utils.js"
import {Container,Row,Col} from 'react-bootstrap'
import {APIProvider, Map, google,useMap,Marker,AdvancedMarker} from '@vis.gl/react-google-maps';
//import {Polyline} from './gmapcomponents/polyline.tsx';
import Tracklist from './Tracklist.js'

export default function MyMap() {

  const mapstyles={
    width:"100%",
    height:"100%",
    position:'absolute'    
  }

  const onGoogleMapsLoad=() =>{
     console.log('Maps API has loaded.')
     console.log('Maps API has loaded.')

      
  }

    return (
     <>
                      
        
              
        <APIProvider apiKey={'AIzaSyCdovhW2CkZqrEpPyBll8t98WNloW05ibE'} onLoad={onGoogleMapsLoad()}>
        <div style={{height:'100%', width:'100%'}}>
        <Map id='mymap'
        style={mapstyles}
        defaultZoom={3}
        defaultCenter={{lat: 22.54992, lng: 0}}
        gestureHandling={'greedy'
     
        }
        />
   </div>
        </APIProvider>
      </>
   
    )  
}

   

