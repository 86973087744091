import React from "react";

const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();


function reducer(currentState, newState) {
  return { ...currentState, ...newState };
}

function useAuthState() {
  const context = React.useContext(AuthStateContext);
  if (!context) throw new Error("useAuthState must be used in AuthProvider");
 
  return context;
}

function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (!context) throw new Error("useAuthDispatch must be used in AuthProvider");

  return context;
}
//for debug puposes only
const user = {
  "@id": 2,
  "id": 2,
  "date": 1709467154.000000000,
  "loginid": "guest@lazycodelabs.com",
  "statusActive": null,
  "avatarPicture": "profile.gif",
  "test": null,
  "strpwd": null,
  "name": "Guest",
  "surname": "User",
  "appinstall": "guestuser",
  "tbltracks": [
    {
        "@id": 2,
        "id": 1,
        "date": "2024-07-09T18:05:17.209176Z",
        "descr": "Vorderer Geißlkopf oder Hohe Geißel 2974mTrack Description",
        "name": "Vorderer Geißlkopf oder Hohe Geißel 2974m",
        "zoomCenterPoint": "lat:47.06115650001463, lng:13.057751000003808",
        "trackDistance": 14.871965284409585,
        "google_encoded_polyline": "esf~GijunAdIbAtDpFzFVrAv@jALhCvDjBCdBh@`N}L`DKdBmA|CLrAkBfDe@dJsI~M_S`KqCbIgHtFoJdBkOnD{I~GiBxEeItAc@IcC|AwBV}FdB?W}HbBqGhHkFlFuPxB{Ms@kFgH}HvG}DdJrBhKcAtCbC}@~G^hIvB|BGzD|BtCbL`CpAvFrKxFfF|NbDcBrBbDpJlGlEhGnFlCTjD`A_AH~BpA`@d@wDe@vDqAa@I_CaA~@UkDoFmCmEiGqJmGsBcDcDbBgF}NsKyFqAwFcLaC}BuCkCOqArA{AW{Al@mAlA[`@qAPFpEsCR}DsAgEhBwChEiHjFcBpGV|HeB?W|F}AvBHbCuAb@yEdI_HhBoDzIeBjOuFnJcIfHaKpC_N~ReJrIgDd@sAjB}CMeBlAaDJaN|LeBi@kBBiCwDkAMsAw@{FWuDqFgIk@",
        "tblgps": [
            {
                "@id": 3,
                "id": 1,
                "date": "2024-07-09T18:05:17.209176Z",
                "descr": null,
                "lat": 47.0612,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1581.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 4,
                "id": 2,
                "date": "2024-07-09T18:05:17.209176Z",
                "descr": null,
                "lat": 47.0595,
                "lng": 13.0575,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 5,
                "id": 3,
                "date": "2024-07-09T18:05:17.209176Z",
                "descr": null,
                "lat": 47.0586,
                "lng": 13.0563,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1582.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 6,
                "id": 4,
                "date": "2024-07-09T18:05:17.209176Z",
                "descr": null,
                "lat": 47.0574,
                "lng": 13.0561,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 7,
                "id": 5,
                "date": "2024-07-09T18:05:17.209176Z",
                "descr": null,
                "lat": 47.0569,
                "lng": 13.0559,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 8,
                "id": 6,
                "date": "2024-07-09T18:05:17.209176Z",
                "descr": null,
                "lat": 47.0565,
                "lng": 13.0558,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 9,
                "id": 7,
                "date": "2024-07-09T18:05:17.209176Z",
                "descr": null,
                "lat": 47.0559,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1588.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 10,
                "id": 8,
                "date": "2024-07-09T18:05:17.209176Z",
                "descr": null,
                "lat": 47.0553,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1590.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 11,
                "id": 9,
                "date": "2024-07-09T18:05:17.209176Z",
                "descr": null,
                "lat": 47.0548,
                "lng": 13.0547,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1592.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 12,
                "id": 10,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0524,
                "lng": 13.0569,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1604.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 13,
                "id": 11,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0516,
                "lng": 13.057,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1606.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 14,
                "id": 12,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0511,
                "lng": 13.0574,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1608.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 15,
                "id": 13,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0503,
                "lng": 13.0573,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 16,
                "id": 14,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0499,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.41,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 17,
                "id": 15,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.049,
                "lng": 13.058,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 18,
                "id": 16,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0472,
                "lng": 13.0597,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 19,
                "id": 17,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0448,
                "lng": 13.0629,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1621.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 20,
                "id": 18,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0429,
                "lng": 13.0636,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1630.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 21,
                "id": 19,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0413,
                "lng": 13.0651,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 22,
                "id": 20,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0401,
                "lng": 13.067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1638.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 23,
                "id": 21,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0395,
                "lng": 13.0696,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1662.82,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 24,
                "id": 22,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0387,
                "lng": 13.0713,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1709.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 25,
                "id": 23,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0372,
                "lng": 13.0719,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1772.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 26,
                "id": 24,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0361,
                "lng": 13.0735,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 27,
                "id": 25,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0357,
                "lng": 13.0737,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1880.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 28,
                "id": 26,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0358,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1902.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 29,
                "id": 27,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0353,
                "lng": 13.0749,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1937.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 30,
                "id": 28,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0352,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1991.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 31,
                "id": 29,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0347,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1999.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 32,
                "id": 30,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0348,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2047.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 33,
                "id": 31,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0343,
                "lng": 13.0792,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2112.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 34,
                "id": 32,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0328,
                "lng": 13.0803,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2126.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 35,
                "id": 33,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0316,
                "lng": 13.0832,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2165.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 36,
                "id": 34,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.031,
                "lng": 13.0856,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2209.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 37,
                "id": 35,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0313,
                "lng": 13.0867,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2239.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 38,
                "id": 36,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0327,
                "lng": 13.0883,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2316.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 39,
                "id": 37,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0313,
                "lng": 13.0893,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2380.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 40,
                "id": 38,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0295,
                "lng": 13.0887,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2371.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 41,
                "id": 39,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0276,
                "lng": 13.089,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2443.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 42,
                "id": 40,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0268,
                "lng": 13.0884,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2425.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 43,
                "id": 41,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0271,
                "lng": 13.0869,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2445.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 44,
                "id": 42,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.027,
                "lng": 13.0853,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2432.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 45,
                "id": 43,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0847,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2437.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 46,
                "id": 44,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0837,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2442.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 47,
                "id": 45,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.083,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2459.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 48,
                "id": 46,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.0823,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2534.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 49,
                "id": 47,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0233,
                "lng": 13.0811,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2601.39,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 50,
                "id": 48,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.0798,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2657.15,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 51,
                "id": 49,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0201,
                "lng": 13.0773,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2711.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 52,
                "id": 50,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0193,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2731.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 53,
                "id": 51,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.0769,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2752.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 54,
                "id": 52,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0168,
                "lng": 13.0756,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2796.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 55,
                "id": 53,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0158,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2831.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 56,
                "id": 54,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.0736,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2879.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 57,
                "id": 55,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0145,
                "lng": 13.0727,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2877.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 58,
                "id": 56,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0142,
                "lng": 13.073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2905.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 59,
                "id": 57,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0141,
                "lng": 13.0724,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2908.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 60,
                "id": 58,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.0722,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2935.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 61,
                "id": 59,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0135,
                "lng": 13.0731,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2972.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 62,
                "id": 60,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.0722,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2935.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 63,
                "id": 61,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0141,
                "lng": 13.0724,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2908.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 64,
                "id": 62,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0142,
                "lng": 13.073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2905.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 65,
                "id": 63,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0145,
                "lng": 13.0727,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2877.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 66,
                "id": 64,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.0736,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2879.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 67,
                "id": 65,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0158,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2831.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 68,
                "id": 66,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0168,
                "lng": 13.0756,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2796.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 69,
                "id": 67,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.0769,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2752.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 70,
                "id": 68,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0193,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2731.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 71,
                "id": 69,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0201,
                "lng": 13.0773,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2711.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 72,
                "id": 70,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.0798,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2657.15,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 73,
                "id": 71,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0233,
                "lng": 13.0811,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2601.39,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 74,
                "id": 72,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.0823,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2534.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 75,
                "id": 73,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.083,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2459.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 76,
                "id": 74,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0837,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2442.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 77,
                "id": 75,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0271,
                "lng": 13.0838,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2433.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 78,
                "id": 76,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0275,
                "lng": 13.0834,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2419.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 79,
                "id": 77,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.028,
                "lng": 13.0835,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2390.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 80,
                "id": 78,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0284,
                "lng": 13.0833,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2361.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 81,
                "id": 79,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0288,
                "lng": 13.0829,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2334.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 82,
                "id": 80,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.029,
                "lng": 13.0827,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2321.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 83,
                "id": 81,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0294,
                "lng": 13.0826,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2297.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 84,
                "id": 82,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0293,
                "lng": 13.0816,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2271.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 85,
                "id": 83,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0301,
                "lng": 13.0815,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2216.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 86,
                "id": 84,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.031,
                "lng": 13.0819,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2165.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 87,
                "id": 85,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.032,
                "lng": 13.0813,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2135.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 88,
                "id": 86,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0328,
                "lng": 13.0803,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2126.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 89,
                "id": 87,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0343,
                "lng": 13.0792,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2112.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 90,
                "id": 88,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0348,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2047.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 91,
                "id": 89,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0347,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1999.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 92,
                "id": 90,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0352,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1991.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 93,
                "id": 91,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0353,
                "lng": 13.0749,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1937.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 94,
                "id": 92,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0358,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1902.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 95,
                "id": 93,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0357,
                "lng": 13.0737,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1880.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 96,
                "id": 94,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0361,
                "lng": 13.0735,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 97,
                "id": 95,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0372,
                "lng": 13.0719,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1772.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 98,
                "id": 96,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0387,
                "lng": 13.0713,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1709.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 99,
                "id": 97,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0395,
                "lng": 13.0696,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1662.82,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 100,
                "id": 98,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0401,
                "lng": 13.067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1638.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 101,
                "id": 99,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0413,
                "lng": 13.0651,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 102,
                "id": 100,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0429,
                "lng": 13.0636,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1630.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 103,
                "id": 101,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0448,
                "lng": 13.0629,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1621.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 104,
                "id": 102,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0472,
                "lng": 13.0597,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 105,
                "id": 103,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.049,
                "lng": 13.058,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 106,
                "id": 104,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0499,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.41,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 107,
                "id": 105,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0503,
                "lng": 13.0573,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 108,
                "id": 106,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0511,
                "lng": 13.0574,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1608.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 109,
                "id": 107,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0516,
                "lng": 13.057,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1606.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 110,
                "id": 108,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0524,
                "lng": 13.0569,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1604.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 111,
                "id": 109,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0548,
                "lng": 13.0547,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1592.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 112,
                "id": 110,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0553,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1590.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 113,
                "id": 111,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0559,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1588.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 114,
                "id": 112,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0565,
                "lng": 13.0558,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 115,
                "id": 113,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0569,
                "lng": 13.0559,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 116,
                "id": 114,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0574,
                "lng": 13.0561,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 117,
                "id": 115,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0586,
                "lng": 13.0563,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1582.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 118,
                "id": 116,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0595,
                "lng": 13.0575,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            },
            {
                "@id": 119,
                "id": 117,
                "date": "2024-07-09T18:05:17.210174Z",
                "descr": null,
                "lat": 47.0612,
                "lng": 13.0577,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1581.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2
            }
        ],
        "tblUser": 1
    },
    {
        "@id": 120,
        "id": 2,
        "date": "2024-07-09T18:05:17.305794Z",
        "descr": "Rameterspitz oder Romatenspitz 2696mTrack Description",
        "name": "Rameterspitz oder Romatenspitz 2696m",
        "zoomCenterPoint": "lat:47.00098400000015,lng:13.132766999999552",
        "trackDistance": 18.485583513710154,
        "google_encoded_polyline": "c{z}Gw~coAs@DgAtSEjP^vCdBnC?l@m@?eBiAeA}AOcEw@}Ck@oIYG@~Jj@rEe@vEZbCGfCvF`IX~A{@|EPxAOzA^xCEzCnArDb@dDp@fBMd@_@EcBqFsAuBaBwAk@iCSKM^Z|BFfCnArCF`A[`DLlAhD~DnA`ANf@_@LaAaAc@APn@nCdCHb@UF_BmAiB}@gAyA[FLhAjBvD{AdMOHAq@a@[a@fAy@Gm@[]l@k@o@k@A{BbAuA`CiAJuBjB_BnDoAhA]z@IlAXlCOx@a@Rc@QWqBUq@YVIo@gA\\g@_@}ANMk@o@g@cBUkAmCV}CKo@}@M_BZ_Ab@w@D_Bp@gAfAw@nAg@HS\\]LoA}AoAe@qA}AoBcJ_AmCs@[Yc@c@v@c@NuCmDs@CWwAqAwD{AgB{@g@{BkC}BUgAyAmAOcFJ}AgA_BqC]wAFkBkBQCm@MOLs@s@uBTgC{@sBJ_BCkA_Ay@MkA}@oAUcBk@kA_@yAI_BTsBXcANkB?o@]o@}AKK_@mCbAC_AaCbAY`@]?Wq@?_DRaCcA{@cAVm@hAGhCX_AC{@TGJq@PTPWLb@Xa@Vb@CfAl@lACpAZCJT`@U^z@b@]`@^z@K\\^^[`@NXUPP`@ELZ|@?Nf@ZFJb@h@MBl@d@ALTZWA`@RAUfA`@ROj@Nv@Gh@W@Id@e@BYj@UDAd@X^ULDn@Mb@d@RvB[L\\[dARZcAl@Ep@XN@|@TJa@|ATh@h@FFh@l@DHt@\\t@O\\Df@]F?l@Yl@Jr@Of@PR^AKzAh@nH`@e@H|B`@s@LnAX}@JjA`@k@^jCfBdFZm@?kCXmCm@eD\\RTSV`@\\WR^f@QP`@ZUXd@\\INlAZRD|@ZJU~@RVH`BMbBBjAd@QCbAd@MWlBCdCp@c@f@@z@pF^nEzApHEvAZ@B`@\\B@f@LFF^VEJd@n@{@j@HC`@ZNg@~@`AZg@n@pA\\W^LZOx@`@GHx@`@LGbAt@vAb@pAFtB^~@No@^TDg@l@`AV~BLg@d@BAs@^C@g@ZBRe@XLf@a@Xd@^]VTTk@^R^Ip@`@f@U`@LZQd@ZPc@O{@ZYBgAf@z@Tk@^FBg@d@FTU?g@rBeAvC_@lAm@An@x@A?jAnDfClAX`D?a@]rAi@Re@|@ClD{CzDuB^z@j@Mn@Rj@{@tBqE~ByAVkAlAZk@cAPGaBeB{@e@AYl@R~BDzAd@sAyBmC}BJkBe@sAzAgMmB{DOcA^KbA|AlBz@xAlAVGGa@qCgCOo@b@?`A`A\\MKe@qAaAkD_EMoAZ}CEeAqAqCGeC[_CL_@TLj@jC|AtArApBfBtF^@Ja@q@gBc@gDmAsD@yC[{CN}AQsAz@_FYcByF_IDeCYcCd@{Ek@mE?eKTDn@pIr@|CNdEfA|AdBhAj@@?o@cBqC_@sCDoPfAoSp@I",
        "tblgps": [
            {
                "@id": 121,
                "id": 118,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1328,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1288.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 122,
                "id": 119,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1327,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1289.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 123,
                "id": 120,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1294,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1312.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 124,
                "id": 121,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1266,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1336.32,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 125,
                "id": 122,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1259,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1342.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 126,
                "id": 123,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1352.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 127,
                "id": 124,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1354.12,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 128,
                "id": 125,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1358.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 129,
                "id": 126,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1363.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 130,
                "id": 127,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1258,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1368.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 131,
                "id": 128,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1267,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1375.52,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 132,
                "id": 129,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1275,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1381.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 133,
                "id": 130,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1292,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1392.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 134,
                "id": 131,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0028,
                "lng": 13.1293,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1393.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 135,
                "id": 132,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0028,
                "lng": 13.1273,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1408.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 136,
                "id": 133,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.1263,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1414.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 137,
                "id": 134,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0027,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1422.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 138,
                "id": 135,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1427.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 139,
                "id": 136,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1239,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1427.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 140,
                "id": 137,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1223,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1448.03,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 141,
                "id": 138,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1218,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1452.48,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 142,
                "id": 139,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1207,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1463.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 143,
                "id": 140,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1202,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1467.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 144,
                "id": 141,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1197,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1470.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 145,
                "id": 142,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1477.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 146,
                "id": 143,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1182,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1484.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 147,
                "id": 144,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1173,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1489.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 148,
                "id": 145,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1165,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1497.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 149,
                "id": 146,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1159,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1500.87,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 150,
                "id": 147,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1502.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 151,
                "id": 148,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1505.6,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 152,
                "id": 149,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.117,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1513.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 153,
                "id": 150,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1176,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1521.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 154,
                "id": 151,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0022,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1526.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 155,
                "id": 152,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1187,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1531.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 156,
                "id": 153,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.1188,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1533.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 157,
                "id": 154,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1536.75,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 158,
                "id": 155,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1539.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 159,
                "id": 156,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1173,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1547.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 160,
                "id": 157,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1166,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1555.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 161,
                "id": 158,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1162,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1559.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 162,
                "id": 159,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1154,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1565.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 163,
                "id": 160,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1569.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 164,
                "id": 161,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1141,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 165,
                "id": 162,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1137,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1587.32,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 166,
                "id": 163,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1136,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1589.32,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 167,
                "id": 164,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0009,
                "lng": 13.1135,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1594.6,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 168,
                "id": 165,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1596.09,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 169,
                "id": 166,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1599.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 170,
                "id": 167,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.1136,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1602.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 171,
                "id": 168,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1129,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1610.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 172,
                "id": 169,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0005,
                "lng": 13.1127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 173,
                "id": 170,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1614.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 174,
                "id": 171,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0011,
                "lng": 13.1131,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1620.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 175,
                "id": 172,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1134,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1626.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 176,
                "id": 173,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1630.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 177,
                "id": 174,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0022,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 178,
                "id": 175,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1134,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1640.21,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 179,
                "id": 176,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1648.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 180,
                "id": 177,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1669.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 181,
                "id": 178,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1671.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 182,
                "id": 179,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1672.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 183,
                "id": 180,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0023,
                "lng": 13.1106,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1675.37,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 184,
                "id": 181,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1685.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 185,
                "id": 182,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0027,
                "lng": 13.1103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1684.12,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 186,
                "id": 183,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.003,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1693.45,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 187,
                "id": 184,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0031,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1702.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 188,
                "id": 185,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0033,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1709.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 189,
                "id": 186,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0035,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1718.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 190,
                "id": 187,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0042,
                "lng": 13.1101,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1740.9,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 191,
                "id": 188,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0046,
                "lng": 13.1094,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1765.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 192,
                "id": 189,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.005,
                "lng": 13.1094,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1772.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 193,
                "id": 190,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0056,
                "lng": 13.1088,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1788.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 194,
                "id": 191,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.006,
                "lng": 13.108,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1808.95,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 195,
                "id": 192,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0064,
                "lng": 13.1076,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1818.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 196,
                "id": 193,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0066,
                "lng": 13.1073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1819.07,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 197,
                "id": 194,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0066,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1821.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 198,
                "id": 195,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0065,
                "lng": 13.1062,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1832.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 199,
                "id": 196,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0066,
                "lng": 13.1059,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1839.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 200,
                "id": 197,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0068,
                "lng": 13.1058,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1839.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 201,
                "id": 198,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0069,
                "lng": 13.1059,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1844.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 202,
                "id": 199,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0071,
                "lng": 13.1065,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1855.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 203,
                "id": 200,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0072,
                "lng": 13.1067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1863.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 204,
                "id": 201,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0073,
                "lng": 13.1066,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1868.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 205,
                "id": 202,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0073,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1873.07,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 206,
                "id": 203,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0077,
                "lng": 13.1067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1887.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 207,
                "id": 204,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0079,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1894.75,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 208,
                "id": 205,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0084,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1907.6,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 209,
                "id": 206,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0084,
                "lng": 13.107,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1911.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 210,
                "id": 207,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0087,
                "lng": 13.1072,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1917.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 211,
                "id": 208,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0092,
                "lng": 13.1073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1933.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 212,
                "id": 209,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0096,
                "lng": 13.108,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1948.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 213,
                "id": 210,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0094,
                "lng": 13.1088,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1966.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 214,
                "id": 211,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0095,
                "lng": 13.109,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1968.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 215,
                "id": 212,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0098,
                "lng": 13.1091,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1974.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 216,
                "id": 213,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0103,
                "lng": 13.109,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1988.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 217,
                "id": 214,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0106,
                "lng": 13.1088,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1994.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 218,
                "id": 215,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0109,
                "lng": 13.1087,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1995.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 219,
                "id": 216,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0114,
                "lng": 13.1085,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2002.01,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 220,
                "id": 217,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0117,
                "lng": 13.1081,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2008.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 221,
                "id": 218,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.012,
                "lng": 13.1077,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2019.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 222,
                "id": 219,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0122,
                "lng": 13.1077,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2022.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 223,
                "id": 220,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0123,
                "lng": 13.1075,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2025.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 224,
                "id": 221,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0125,
                "lng": 13.1075,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2033.94,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 225,
                "id": 222,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0129,
                "lng": 13.1079,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2044.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 226,
                "id": 223,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0133,
                "lng": 13.1081,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2048.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 227,
                "id": 224,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.1086,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2052.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 228,
                "id": 225,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0142,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2070.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 229,
                "id": 226,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.1111,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2090.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 230,
                "id": 227,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0148,
                "lng": 13.1112,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2099.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 231,
                "id": 228,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0149,
                "lng": 13.1114,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2105.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 232,
                "id": 229,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0151,
                "lng": 13.1111,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2111.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 233,
                "id": 230,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0153,
                "lng": 13.111,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2115.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 234,
                "id": 231,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0161,
                "lng": 13.1119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2127.21,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 235,
                "id": 232,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0163,
                "lng": 13.1119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2130.6,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 236,
                "id": 233,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0164,
                "lng": 13.1124,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2134.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 237,
                "id": 234,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0168,
                "lng": 13.1133,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2144.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 238,
                "id": 235,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0173,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2147.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 239,
                "id": 236,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0176,
                "lng": 13.114,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2148.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 240,
                "id": 237,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0182,
                "lng": 13.1147,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2158.54,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 241,
                "id": 238,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0189,
                "lng": 13.1148,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2173.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 242,
                "id": 239,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0192,
                "lng": 13.1153,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2190.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 243,
                "id": 240,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0196,
                "lng": 13.1153,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2202.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 244,
                "id": 241,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0207,
                "lng": 13.1153,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2232.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 245,
                "id": 242,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.1157,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2248.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 246,
                "id": 243,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0217,
                "lng": 13.1164,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2272.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 247,
                "id": 244,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0219,
                "lng": 13.1168,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2283.03,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 248,
                "id": 245,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0218,
                "lng": 13.1174,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2293.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 249,
                "id": 246,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0223,
                "lng": 13.1175,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2316.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 250,
                "id": 247,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1177,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2322.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 251,
                "id": 248,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1178,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2327.87,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 252,
                "id": 249,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2331.03,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 253,
                "id": 250,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2346.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 254,
                "id": 251,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0225,
                "lng": 13.1193,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2359.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 255,
                "id": 252,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1199,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2374.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 256,
                "id": 253,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1204,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2387.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 257,
                "id": 254,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1207,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2393.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 258,
                "id": 255,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.121,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2406.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 259,
                "id": 256,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.1214,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2413.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 260,
                "id": 257,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0235,
                "lng": 13.1218,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2426.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 261,
                "id": 258,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0236,
                "lng": 13.1223,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2441.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 262,
                "id": 259,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0238,
                "lng": 13.1227,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2452.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 263,
                "id": 260,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.024,
                "lng": 13.1231,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2468.21,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 264,
                "id": 261,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.024,
                "lng": 13.1236,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2476.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 265,
                "id": 262,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0239,
                "lng": 13.1242,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2493.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 266,
                "id": 263,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0238,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2502.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 267,
                "id": 264,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.1251,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2516.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 268,
                "id": 265,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2520.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 269,
                "id": 266,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0238,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2530.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 270,
                "id": 267,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0243,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2542.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 271,
                "id": 268,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0244,
                "lng": 13.1258,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2547.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 272,
                "id": 269,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0251,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2573.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 273,
                "id": 270,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0251,
                "lng": 13.1257,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2578.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 274,
                "id": 271,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2604.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 275,
                "id": 272,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0259,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2609.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 276,
                "id": 273,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.026,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2616.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 277,
                "id": 274,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0262,
                "lng": 13.1255,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2625.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 278,
                "id": 275,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0262,
                "lng": 13.1263,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2643.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 279,
                "id": 276,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0261,
                "lng": 13.1269,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2658.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 280,
                "id": 277,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.1272,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2673.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 281,
                "id": 278,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0267,
                "lng": 13.1271,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2680.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 282,
                "id": 279,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.027,
                "lng": 13.1268,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2691.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 283,
                "id": 280,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.027,
                "lng": 13.1261,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2693.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 284,
                "id": 281,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0269,
                "lng": 13.1264,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2689.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 285,
                "id": 282,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0269,
                "lng": 13.1267,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2688.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 286,
                "id": 283,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0268,
                "lng": 13.1267,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2683.77,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 287,
                "id": 284,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0267,
                "lng": 13.127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2680.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 288,
                "id": 285,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0266,
                "lng": 13.1269,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2675.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 289,
                "id": 286,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0265,
                "lng": 13.127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2672.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 290,
                "id": 287,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0265,
                "lng": 13.1268,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2669.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 291,
                "id": 288,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0263,
                "lng": 13.127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2666.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 292,
                "id": 289,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0262,
                "lng": 13.1268,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2660.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 293,
                "id": 290,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0263,
                "lng": 13.1264,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2653.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 294,
                "id": 291,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.026,
                "lng": 13.126,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2628.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 295,
                "id": 292,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.026,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2619.98,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 296,
                "id": 293,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0259,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2613.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 297,
                "id": 294,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.1255,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2609.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 298,
                "id": 295,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0257,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2603.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 299,
                "id": 296,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0255,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2591.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 300,
                "id": 297,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0253,
                "lng": 13.1255,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2585.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 301,
                "id": 298,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0252,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2575.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 302,
                "id": 299,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0249,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2561.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 303,
                "id": 300,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0247,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2552.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 304,
                "id": 301,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0245,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2547.94,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 305,
                "id": 302,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0244,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2540.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 306,
                "id": 303,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0243,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2536.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 307,
                "id": 304,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0242,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2533.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 308,
                "id": 305,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.024,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2529.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 309,
                "id": 306,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0239,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2524.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 310,
                "id": 307,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0236,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2516.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 311,
                "id": 308,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0235,
                "lng": 13.125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2510.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 312,
                "id": 309,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0234,
                "lng": 13.125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2504.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 313,
                "id": 310,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0233,
                "lng": 13.1248,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2498.77,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 314,
                "id": 311,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2495.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 315,
                "id": 312,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1246,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2490.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 316,
                "id": 313,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0229,
                "lng": 13.1246,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2481.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 317,
                "id": 314,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2474.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 318,
                "id": 315,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1246,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2473.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 319,
                "id": 316,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2472.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 320,
                "id": 317,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2472.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 321,
                "id": 318,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1241,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2469.15,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 322,
                "id": 319,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0225,
                "lng": 13.124,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2462.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 323,
                "id": 320,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1238,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2460.77,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 324,
                "id": 321,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1235,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2457.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 325,
                "id": 322,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1233,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2457.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 326,
                "id": 323,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1233,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2454.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 327,
                "id": 324,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1231,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2451.54,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 328,
                "id": 325,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0229,
                "lng": 13.1231,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2448.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 329,
                "id": 326,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1229,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2444.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 330,
                "id": 327,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.1228,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2444.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 331,
                "id": 328,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.1227,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2442.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 332,
                "id": 329,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1225,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2439.37,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 333,
                "id": 330,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.1224,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2437.54,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 334,
                "id": 331,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1222,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2432.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 335,
                "id": 332,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.122,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2428.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 336,
                "id": 333,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.023,
                "lng": 13.1219,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2424.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 337,
                "id": 334,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1221,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2416.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 338,
                "id": 335,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1219,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2412.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 339,
                "id": 336,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0225,
                "lng": 13.1215,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2413.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 340,
                "id": 337,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1214,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2410.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 341,
                "id": 338,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1212,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2407.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 342,
                "id": 339,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1209,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2397.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 343,
                "id": 340,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1209,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2393.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 344,
                "id": 341,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1205,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2392.95,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 345,
                "id": 342,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0225,
                "lng": 13.1205,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2391.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 346,
                "id": 343,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.12,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2378.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 347,
                "id": 344,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1198,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2368.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 348,
                "id": 345,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1198,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2363.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 349,
                "id": 346,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0223,
                "lng": 13.1196,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2357.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 350,
                "id": 347,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0221,
                "lng": 13.1195,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2352.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 351,
                "id": 348,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.022,
                "lng": 13.1193,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2351.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 352,
                "id": 349,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0219,
                "lng": 13.119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2350.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 353,
                "id": 350,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.022,
                "lng": 13.1188,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2347.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 354,
                "id": 351,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0219,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2342.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 355,
                "id": 352,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0221,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2342.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 356,
                "id": 353,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0221,
                "lng": 13.1184,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2336.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 357,
                "id": 354,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0222,
                "lng": 13.1181,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2331.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 358,
                "id": 355,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0222,
                "lng": 13.1179,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2325.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 359,
                "id": 356,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0222,
                "lng": 13.1177,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2318.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 360,
                "id": 357,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0222,
                "lng": 13.1176,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2312.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 361,
                "id": 358,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.022,
                "lng": 13.1176,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2307.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 362,
                "id": 359,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0221,
                "lng": 13.1171,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2298.45,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 363,
                "id": 360,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0218,
                "lng": 13.1156,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2281.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 364,
                "id": 361,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0217,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2272.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 365,
                "id": 362,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0216,
                "lng": 13.1152,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2269.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 366,
                "id": 363,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0215,
                "lng": 13.1154,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2258.98,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 367,
                "id": 364,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0214,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2256.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 368,
                "id": 365,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0213,
                "lng": 13.1153,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2249.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 369,
                "id": 366,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2246.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 370,
                "id": 367,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.021,
                "lng": 13.1152,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2241.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 371,
                "id": 368,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0209,
                "lng": 13.1145,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2236.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 372,
                "id": 369,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0204,
                "lng": 13.1133,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2227.91,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 373,
                "id": 370,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0202,
                "lng": 13.1135,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2225.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 374,
                "id": 371,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0202,
                "lng": 13.1142,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2222.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 375,
                "id": 372,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0201,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2216.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 376,
                "id": 373,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0203,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2213.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 377,
                "id": 374,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0202,
                "lng": 13.1157,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2211.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 378,
                "id": 375,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.02,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2210.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 379,
                "id": 376,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0199,
                "lng": 13.1156,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2206.94,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 380,
                "id": 377,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0198,
                "lng": 13.1157,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2206.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 381,
                "id": 378,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0197,
                "lng": 13.1156,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2201.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 382,
                "id": 379,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0195,
                "lng": 13.1157,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2199.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 383,
                "id": 380,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0194,
                "lng": 13.1155,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2194.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 384,
                "id": 381,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0192,
                "lng": 13.1156,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2195.37,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 385,
                "id": 382,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0191,
                "lng": 13.1154,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2187.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 386,
                "id": 383,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.019,
                "lng": 13.1155,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2185.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 387,
                "id": 384,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0189,
                "lng": 13.1151,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2176.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 388,
                "id": 385,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2170.03,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 389,
                "id": 386,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.1147,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2166.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 390,
                "id": 387,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0186,
                "lng": 13.1146,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2162.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 391,
                "id": 388,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.1143,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2161.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 392,
                "id": 389,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0186,
                "lng": 13.1142,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2158.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 393,
                "id": 390,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0185,
                "lng": 13.1137,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2156.75,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 394,
                "id": 391,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0186,
                "lng": 13.1132,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2155.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 395,
                "id": 392,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0186,
                "lng": 13.1128,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2153.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 396,
                "id": 393,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0184,
                "lng": 13.1129,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2146.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 397,
                "id": 394,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0184,
                "lng": 13.1125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2144.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 398,
                "id": 395,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0182,
                "lng": 13.1126,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2140.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 399,
                "id": 396,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0184,
                "lng": 13.1121,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2136.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 400,
                "id": 397,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0184,
                "lng": 13.1114,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2130.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 401,
                "id": 398,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0181,
                "lng": 13.1116,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2128.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 402,
                "id": 399,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0179,
                "lng": 13.1116,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2125.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 403,
                "id": 400,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0176,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2123.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 404,
                "id": 401,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0175,
                "lng": 13.1093,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2118.41,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 405,
                "id": 402,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.017,
                "lng": 13.1078,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2111.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 406,
                "id": 403,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.017,
                "lng": 13.1074,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2107.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 407,
                "id": 404,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0169,
                "lng": 13.1073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2104.64,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 408,
                "id": 405,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0169,
                "lng": 13.1072,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2103.95,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 409,
                "id": 406,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0167,
                "lng": 13.1071,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2099.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 410,
                "id": 407,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0167,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2096.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 411,
                "id": 408,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0166,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2093.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 412,
                "id": 409,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0166,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2089.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 413,
                "id": 410,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0165,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2086.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 414,
                "id": 411,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0164,
                "lng": 13.1066,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2083.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 415,
                "id": 412,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0162,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2082.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 416,
                "id": 413,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.016,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2081.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 417,
                "id": 414,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.016,
                "lng": 13.1067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2077.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 418,
                "id": 415,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0158,
                "lng": 13.1066,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2070.95,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 419,
                "id": 416,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.016,
                "lng": 13.1063,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2066.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 420,
                "id": 417,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0157,
                "lng": 13.1061,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2063.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 421,
                "id": 418,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0159,
                "lng": 13.1059,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2057.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 422,
                "id": 419,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0155,
                "lng": 13.1057,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2056.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 423,
                "id": 420,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0156,
                "lng": 13.1056,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2050.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 424,
                "id": 421,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0155,
                "lng": 13.1054,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2047.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 425,
                "id": 422,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0156,
                "lng": 13.1052,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2046.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 426,
                "id": 423,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0155,
                "lng": 13.1052,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2041.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 427,
                "id": 424,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0154,
                "lng": 13.1049,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2039.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 428,
                "id": 425,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0152,
                "lng": 13.1048,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2034.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 429,
                "id": 426,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0153,
                "lng": 13.1045,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2034.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 430,
                "id": 427,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.015,
                "lng": 13.104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2029.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 431,
                "id": 428,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0148,
                "lng": 13.1036,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2023.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 432,
                "id": 429,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0148,
                "lng": 13.1031,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2022.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 433,
                "id": 430,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.1027,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2020.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 434,
                "id": 431,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2012.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 435,
                "id": 432,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0144,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2005.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 436,
                "id": 433,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0144,
                "lng": 13.1031,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1999.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 437,
                "id": 434,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0141,
                "lng": 13.1027,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1993.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 438,
                "id": 435,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.014,
                "lng": 13.1021,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1986.01,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 439,
                "id": 436,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0139,
                "lng": 13.1023,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1980.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 440,
                "id": 437,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.1023,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1975.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 441,
                "id": 438,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0138,
                "lng": 13.1025,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1972.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 442,
                "id": 439,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0136,
                "lng": 13.1025,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1966.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 443,
                "id": 440,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0136,
                "lng": 13.1028,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1964.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 444,
                "id": 441,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0135,
                "lng": 13.1027,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1960.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 445,
                "id": 442,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0134,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1956.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 446,
                "id": 443,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0132,
                "lng": 13.1028,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1951.52,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 447,
                "id": 444,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.013,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1947.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 448,
                "id": 445,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0129,
                "lng": 13.1028,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1941.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 449,
                "id": 446,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0127,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1934.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 450,
                "id": 447,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0126,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1932.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 451,
                "id": 448,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0125,
                "lng": 13.1031,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1924.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 452,
                "id": 449,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0123,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1919.98,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 453,
                "id": 450,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0122,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1912.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 454,
                "id": 451,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0119,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1910.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 455,
                "id": 452,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0117,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1898.09,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 456,
                "id": 453,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0116,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1892.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 457,
                "id": 454,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0114,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1885.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 458,
                "id": 455,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0112,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1879.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 459,
                "id": 456,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0111,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1871.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 460,
                "id": 457,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0112,
                "lng": 13.1033,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1869.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 461,
                "id": 458,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0111,
                "lng": 13.1035,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1865.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 462,
                "id": 459,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0111,
                "lng": 13.1038,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1862.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 463,
                "id": 460,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0109,
                "lng": 13.1035,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1860.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 464,
                "id": 461,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0108,
                "lng": 13.1037,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1857.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 465,
                "id": 462,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0106,
                "lng": 13.1037,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.64,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 466,
                "id": 463,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0106,
                "lng": 13.1039,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 467,
                "id": 464,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0104,
                "lng": 13.1039,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1849.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 468,
                "id": 465,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0103,
                "lng": 13.104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1848.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 469,
                "id": 466,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0103,
                "lng": 13.1042,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1848.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 470,
                "id": 467,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0097,
                "lng": 13.1045,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1844.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 471,
                "id": 468,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0089,
                "lng": 13.1047,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1838.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 472,
                "id": 469,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0085,
                "lng": 13.1049,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1835.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 473,
                "id": 470,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0085,
                "lng": 13.1047,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1830.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 474,
                "id": 471,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0083,
                "lng": 13.1047,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1825.98,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 475,
                "id": 472,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0083,
                "lng": 13.1043,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1823.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 476,
                "id": 473,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0074,
                "lng": 13.1036,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1816.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 477,
                "id": 474,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.007,
                "lng": 13.1035,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1815.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 478,
                "id": 475,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0062,
                "lng": 13.1035,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1810.32,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 479,
                "id": 476,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0064,
                "lng": 13.1036,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1808.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 480,
                "id": 477,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0059,
                "lng": 13.1039,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1802.94,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 481,
                "id": 478,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0058,
                "lng": 13.1041,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1803.73,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 482,
                "id": 479,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0055,
                "lng": 13.1041,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1797.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 483,
                "id": 480,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0046,
                "lng": 13.1048,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1779.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 484,
                "id": 481,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0037,
                "lng": 13.1054,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1763.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 485,
                "id": 482,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0036,
                "lng": 13.1051,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1759.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 486,
                "id": 483,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0033,
                "lng": 13.1052,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1759.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 487,
                "id": 484,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0031,
                "lng": 13.1051,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1758.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 488,
                "id": 485,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0029,
                "lng": 13.1054,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1760.21,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 489,
                "id": 486,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0023,
                "lng": 13.1065,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1757.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 490,
                "id": 487,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1748.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 491,
                "id": 488,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1747.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 492,
                "id": 489,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0011,
                "lng": 13.1072,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1742.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 493,
                "id": 490,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1075,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1735.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 494,
                "id": 491,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.1075,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1730.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 495,
                "id": 492,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.108,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1723.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 496,
                "id": 493,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1082,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1721.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 497,
                "id": 494,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1084,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1718.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 498,
                "id": 495,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0018,
                "lng": 13.1083,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1717.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 499,
                "id": 496,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1082,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1705.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 500,
                "id": 497,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.108,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1698.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 501,
                "id": 498,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0011,
                "lng": 13.1087,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1690.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 502,
                "id": 499,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0019,
                "lng": 13.1093,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1677.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 503,
                "id": 500,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0018,
                "lng": 13.1098,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1671.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 504,
                "id": 501,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1669.64,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 505,
                "id": 502,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1648.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 506,
                "id": 503,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1135,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1638.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 507,
                "id": 504,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0022,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 508,
                "id": 505,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1139,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1629.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 509,
                "id": 506,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1134,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1626.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 510,
                "id": 507,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0011,
                "lng": 13.1131,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1619.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 511,
                "id": 508,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1614.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 512,
                "id": 509,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0005,
                "lng": 13.1127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.73,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 513,
                "id": 510,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1129,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1609.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 514,
                "id": 511,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.1136,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1602.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 515,
                "id": 512,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1598.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 516,
                "id": 513,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1595.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 517,
                "id": 514,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0009,
                "lng": 13.1135,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1593.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 518,
                "id": 515,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1136,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1589.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 519,
                "id": 516,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1587.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 520,
                "id": 517,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1141,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 521,
                "id": 518,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1151,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1569.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 522,
                "id": 519,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1154,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1565.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 523,
                "id": 520,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1162,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1559.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 524,
                "id": 521,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1166,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1555.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 525,
                "id": 522,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1173,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1547.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 526,
                "id": 523,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1540.01,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 527,
                "id": 524,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1536.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 528,
                "id": 525,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.1188,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1533.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 529,
                "id": 526,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1187,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1531.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 530,
                "id": 527,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0022,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1526.64,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 531,
                "id": 528,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1176,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1521.45,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 532,
                "id": 529,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.117,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1513.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 533,
                "id": 530,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1505.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 534,
                "id": 531,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1502.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 535,
                "id": 532,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1159,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1500.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 536,
                "id": 533,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1165,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1497.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 537,
                "id": 534,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1173,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1489.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 538,
                "id": 535,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1182,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1483.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 539,
                "id": 536,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1477.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 540,
                "id": 537,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1198,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1470.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 541,
                "id": 538,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1202,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1466.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 542,
                "id": 539,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1206,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1463.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 543,
                "id": 540,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1218,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1452.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 544,
                "id": 541,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1223,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1447.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 545,
                "id": 542,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1239,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1427.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 546,
                "id": 543,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1427.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 547,
                "id": 544,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0027,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1422.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 548,
                "id": 545,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.1263,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1414.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 549,
                "id": 546,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0028,
                "lng": 13.1273,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1408.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 550,
                "id": 547,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0028,
                "lng": 13.1293,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1393.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 551,
                "id": 548,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1293,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1392.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 552,
                "id": 549,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1276,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1381.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 553,
                "id": 550,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1268,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1376.07,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 554,
                "id": 551,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1258,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1369.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 555,
                "id": 552,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1363.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 556,
                "id": 553,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1358.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 557,
                "id": 554,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1354.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 558,
                "id": 555,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1352.48,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 559,
                "id": 556,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1259,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1342.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 560,
                "id": 557,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1266,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1336.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 561,
                "id": 558,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1294,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1312.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 562,
                "id": 559,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1327,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1290.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            },
            {
                "@id": 563,
                "id": 560,
                "date": "2024-07-09T18:05:17.305794Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1328,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1288.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 120
            }
        ],
        "tblUser": 1
    },
    {
        "@id": 564,
        "id": 52,
        "date": "2024-07-10T13:23:36.010641Z",
        "descr": "Vorderer Geißlkopf oder Hohe Geißel 2974mTrack Description",
        "name": "Vorderer Geißlkopf oder Hohe Geißel 2974m",
        "zoomCenterPoint": "lat:47.06115650001463,lng:13.057751000003808",
        "trackDistance": 14.871965284409585,
        "google_encoded_polyline": "esf~GijunAdIbAtDpFzFVrAv@jALhCvDjBCdBh@`N}L`DKdBmA|CLrAkBfDe@dJsI~M_S`KqCbIgHtFoJdBkOnD{I~GiBxEeItAc@IcC|AwBV}FdB?W}HbBqGhHkFlFuPxB{Ms@kFgH}HvG}DdJrBhKcAtCbC}@~G^hIvB|BGzD|BtCbL`CpAvFrKxFfF|NbDcBrBbDpJlGlEhGnFlCTjD`A_AH~BpA`@d@wDe@vDqAa@I_CaA~@UkDoFmCmEiGqJmGsBcDcDbBgF}NsKyFqAwFcLaC}BuCkCOqArA{AW{Al@mAlA[`@qAPFpEsCR}DsAgEhBwChEiHjFcBpGV|HeB?W|F}AvBHbCuAb@yEdI_HhBoDzIeBjOuFnJcIfHaKpC_N~ReJrIgDd@sAjB}CMeBlAaDJaN|LeBi@kBBiCwDkAMsAw@{FWuDqFgIk@",
        "tblgps": [
            {
                "@id": 565,
                "id": 1252,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0612,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1581.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 566,
                "id": 1253,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0595,
                "lng": 13.0575,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 567,
                "id": 1254,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0586,
                "lng": 13.0563,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1582.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 568,
                "id": 1255,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0574,
                "lng": 13.0561,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 569,
                "id": 1256,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0569,
                "lng": 13.0559,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 570,
                "id": 1257,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0565,
                "lng": 13.0558,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 571,
                "id": 1258,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0559,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1588.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 572,
                "id": 1259,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0553,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1590.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 573,
                "id": 1260,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0548,
                "lng": 13.0547,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1592.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 574,
                "id": 1261,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0524,
                "lng": 13.0569,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1604.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 575,
                "id": 1262,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0516,
                "lng": 13.057,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1606.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 576,
                "id": 1263,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0511,
                "lng": 13.0574,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1608.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 577,
                "id": 1264,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0503,
                "lng": 13.0573,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 578,
                "id": 1265,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0499,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.41,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 579,
                "id": 1266,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.049,
                "lng": 13.058,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 580,
                "id": 1267,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0472,
                "lng": 13.0597,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 581,
                "id": 1268,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0448,
                "lng": 13.0629,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1621.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 582,
                "id": 1269,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0429,
                "lng": 13.0636,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1630.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 583,
                "id": 1270,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0413,
                "lng": 13.0651,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 584,
                "id": 1271,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0401,
                "lng": 13.067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1638.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 585,
                "id": 1272,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0395,
                "lng": 13.0696,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1662.82,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 586,
                "id": 1273,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0387,
                "lng": 13.0713,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1709.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 587,
                "id": 1274,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0372,
                "lng": 13.0719,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1772.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 588,
                "id": 1275,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0361,
                "lng": 13.0735,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 589,
                "id": 1276,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0357,
                "lng": 13.0737,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1880.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 590,
                "id": 1277,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0358,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1902.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 591,
                "id": 1278,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0353,
                "lng": 13.0749,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1937.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 592,
                "id": 1279,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0352,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1991.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 593,
                "id": 1280,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0347,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1999.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 594,
                "id": 1281,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0348,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2047.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 595,
                "id": 1282,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0343,
                "lng": 13.0792,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2112.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 596,
                "id": 1283,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0328,
                "lng": 13.0803,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2126.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 597,
                "id": 1284,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0316,
                "lng": 13.0832,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2165.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 598,
                "id": 1285,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.031,
                "lng": 13.0856,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2209.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 599,
                "id": 1286,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0313,
                "lng": 13.0867,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2239.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 600,
                "id": 1287,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0327,
                "lng": 13.0883,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2316.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 601,
                "id": 1288,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0313,
                "lng": 13.0893,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2380.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 602,
                "id": 1289,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0295,
                "lng": 13.0887,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2371.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 603,
                "id": 1290,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0276,
                "lng": 13.089,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2443.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 604,
                "id": 1291,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0268,
                "lng": 13.0884,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2425.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 605,
                "id": 1292,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0271,
                "lng": 13.0869,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2445.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 606,
                "id": 1293,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.027,
                "lng": 13.0853,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2432.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 607,
                "id": 1294,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0847,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2437.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 608,
                "id": 1295,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0837,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2442.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 609,
                "id": 1296,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.083,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2459.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 610,
                "id": 1297,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.0823,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2534.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 611,
                "id": 1298,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0233,
                "lng": 13.0811,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2601.39,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 612,
                "id": 1299,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.0798,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2657.15,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 613,
                "id": 1300,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0201,
                "lng": 13.0773,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2711.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 614,
                "id": 1301,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0193,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2731.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 615,
                "id": 1302,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.0769,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2752.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 616,
                "id": 1303,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0168,
                "lng": 13.0756,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2796.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 617,
                "id": 1304,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0158,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2831.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 618,
                "id": 1305,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.0736,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2879.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 619,
                "id": 1306,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0145,
                "lng": 13.0727,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2877.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 620,
                "id": 1307,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0142,
                "lng": 13.073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2905.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 621,
                "id": 1308,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0141,
                "lng": 13.0724,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2908.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 622,
                "id": 1309,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.0722,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2935.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 623,
                "id": 1310,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0135,
                "lng": 13.0731,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2972.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 624,
                "id": 1311,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.0722,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2935.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 625,
                "id": 1312,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0141,
                "lng": 13.0724,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2908.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 626,
                "id": 1313,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0142,
                "lng": 13.073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2905.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 627,
                "id": 1314,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0145,
                "lng": 13.0727,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2877.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 628,
                "id": 1315,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.0736,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2879.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 629,
                "id": 1316,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0158,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2831.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 630,
                "id": 1317,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0168,
                "lng": 13.0756,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2796.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 631,
                "id": 1318,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.0769,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2752.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 632,
                "id": 1319,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0193,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2731.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 633,
                "id": 1320,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0201,
                "lng": 13.0773,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2711.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 634,
                "id": 1321,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.0798,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2657.15,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 635,
                "id": 1322,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0233,
                "lng": 13.0811,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2601.39,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 636,
                "id": 1323,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.0823,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2534.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 637,
                "id": 1324,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.083,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2459.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 638,
                "id": 1325,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0837,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2442.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 639,
                "id": 1326,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0271,
                "lng": 13.0838,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2433.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 640,
                "id": 1327,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0275,
                "lng": 13.0834,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2419.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 641,
                "id": 1328,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.028,
                "lng": 13.0835,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2390.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 642,
                "id": 1329,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0284,
                "lng": 13.0833,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2361.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 643,
                "id": 1330,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0288,
                "lng": 13.0829,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2334.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 644,
                "id": 1331,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.029,
                "lng": 13.0827,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2321.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 645,
                "id": 1332,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0294,
                "lng": 13.0826,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2297.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 646,
                "id": 1333,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0293,
                "lng": 13.0816,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2271.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 647,
                "id": 1334,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0301,
                "lng": 13.0815,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2216.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 648,
                "id": 1335,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.031,
                "lng": 13.0819,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2165.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 649,
                "id": 1336,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.032,
                "lng": 13.0813,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2135.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 650,
                "id": 1337,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0328,
                "lng": 13.0803,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2126.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 651,
                "id": 1338,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0343,
                "lng": 13.0792,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2112.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 652,
                "id": 1339,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0348,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2047.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 653,
                "id": 1340,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0347,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1999.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 654,
                "id": 1341,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0352,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1991.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 655,
                "id": 1342,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0353,
                "lng": 13.0749,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1937.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 656,
                "id": 1343,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0358,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1902.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 657,
                "id": 1344,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0357,
                "lng": 13.0737,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1880.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 658,
                "id": 1345,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0361,
                "lng": 13.0735,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 659,
                "id": 1346,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0372,
                "lng": 13.0719,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1772.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 660,
                "id": 1347,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0387,
                "lng": 13.0713,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1709.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 661,
                "id": 1348,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0395,
                "lng": 13.0696,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1662.82,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 662,
                "id": 1349,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0401,
                "lng": 13.067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1638.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 663,
                "id": 1350,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0413,
                "lng": 13.0651,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 664,
                "id": 1351,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0429,
                "lng": 13.0636,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1630.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 665,
                "id": 1352,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0448,
                "lng": 13.0629,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1621.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 666,
                "id": 1353,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0472,
                "lng": 13.0597,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 667,
                "id": 1354,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.049,
                "lng": 13.058,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 668,
                "id": 1355,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0499,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.41,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 669,
                "id": 1356,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0503,
                "lng": 13.0573,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 670,
                "id": 1357,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0511,
                "lng": 13.0574,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1608.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 671,
                "id": 1358,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0516,
                "lng": 13.057,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1606.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 672,
                "id": 1359,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0524,
                "lng": 13.0569,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1604.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 673,
                "id": 1360,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0548,
                "lng": 13.0547,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1592.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 674,
                "id": 1361,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0553,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1590.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 675,
                "id": 1362,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0559,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1588.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 676,
                "id": 1363,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0565,
                "lng": 13.0558,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 677,
                "id": 1364,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0569,
                "lng": 13.0559,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 678,
                "id": 1365,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0574,
                "lng": 13.0561,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 679,
                "id": 1366,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0586,
                "lng": 13.0563,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1582.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 680,
                "id": 1367,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0595,
                "lng": 13.0575,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            },
            {
                "@id": 681,
                "id": 1368,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0612,
                "lng": 13.0577,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1581.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 564
            }
        ],
        "tblUser": 1
    },
    {
        "@id": 682,
        "id": 53,
        "date": "2024-07-10T13:23:36.010641Z",
        "descr": "Vorderer Geißlkopf oder Hohe Geißel 2974mTrack Description",
        "name": "Vorderer Geißlkopf oder Hohe Geißel 2974m",
        "zoomCenterPoint": "lat:47.06115650001463,lng:13.057751000003808",
        "trackDistance": 14.871965284409585,
        "google_encoded_polyline": "esf~GijunAdIbAtDpFzFVrAv@jALhCvDjBCdBh@`N}L`DKdBmA|CLrAkBfDe@dJsI~M_S`KqCbIgHtFoJdBkOnD{I~GiBxEeItAc@IcC|AwBV}FdB?W}HbBqGhHkFlFuPxB{Ms@kFgH}HvG}DdJrBhKcAtCbC}@~G^hIvB|BGzD|BtCbL`CpAvFrKxFfF|NbDcBrBbDpJlGlEhGnFlCTjD`A_AH~BpA`@d@wDe@vDqAa@I_CaA~@UkDoFmCmEiGqJmGsBcDcDbBgF}NsKyFqAwFcLaC}BuCkCOqArA{AW{Al@mAlA[`@qAPFpEsCR}DsAgEhBwChEiHjFcBpGV|HeB?W|F}AvBHbCuAb@yEdI_HhBoDzIeBjOuFnJcIfHaKpC_N~ReJrIgDd@sAjB}CMeBlAaDJaN|LeBi@kBBiCwDkAMsAw@{FWuDqFgIk@",
        "tblgps": [
            {
                "@id": 683,
                "id": 1369,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0612,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1581.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 684,
                "id": 1370,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0595,
                "lng": 13.0575,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 685,
                "id": 1371,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0586,
                "lng": 13.0563,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1582.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 686,
                "id": 1372,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0574,
                "lng": 13.0561,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 687,
                "id": 1373,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0569,
                "lng": 13.0559,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 688,
                "id": 1374,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0565,
                "lng": 13.0558,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 689,
                "id": 1375,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0559,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1588.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 690,
                "id": 1376,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0553,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1590.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 691,
                "id": 1377,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0548,
                "lng": 13.0547,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1592.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 692,
                "id": 1378,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0524,
                "lng": 13.0569,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1604.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 693,
                "id": 1379,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0516,
                "lng": 13.057,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1606.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 694,
                "id": 1380,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0511,
                "lng": 13.0574,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1608.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 695,
                "id": 1381,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0503,
                "lng": 13.0573,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 696,
                "id": 1382,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0499,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.41,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 697,
                "id": 1383,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.049,
                "lng": 13.058,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 698,
                "id": 1384,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0472,
                "lng": 13.0597,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 699,
                "id": 1385,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0448,
                "lng": 13.0629,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1621.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 700,
                "id": 1386,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0429,
                "lng": 13.0636,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1630.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 701,
                "id": 1387,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0413,
                "lng": 13.0651,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 702,
                "id": 1388,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0401,
                "lng": 13.067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1638.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 703,
                "id": 1389,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0395,
                "lng": 13.0696,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1662.82,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 704,
                "id": 1390,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0387,
                "lng": 13.0713,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1709.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 705,
                "id": 1391,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0372,
                "lng": 13.0719,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1772.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 706,
                "id": 1392,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0361,
                "lng": 13.0735,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 707,
                "id": 1393,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0357,
                "lng": 13.0737,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1880.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 708,
                "id": 1394,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0358,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1902.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 709,
                "id": 1395,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0353,
                "lng": 13.0749,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1937.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 710,
                "id": 1396,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0352,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1991.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 711,
                "id": 1397,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0347,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1999.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 712,
                "id": 1398,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0348,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2047.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 713,
                "id": 1399,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0343,
                "lng": 13.0792,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2112.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 714,
                "id": 1400,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0328,
                "lng": 13.0803,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2126.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 715,
                "id": 1401,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0316,
                "lng": 13.0832,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2165.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 716,
                "id": 1402,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.031,
                "lng": 13.0856,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2209.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 717,
                "id": 1403,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0313,
                "lng": 13.0867,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2239.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 718,
                "id": 1404,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0327,
                "lng": 13.0883,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2316.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 719,
                "id": 1405,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0313,
                "lng": 13.0893,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2380.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 720,
                "id": 1406,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0295,
                "lng": 13.0887,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2371.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 721,
                "id": 1407,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0276,
                "lng": 13.089,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2443.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 722,
                "id": 1408,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0268,
                "lng": 13.0884,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2425.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 723,
                "id": 1409,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0271,
                "lng": 13.0869,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2445.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 724,
                "id": 1410,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.027,
                "lng": 13.0853,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2432.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 725,
                "id": 1411,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0847,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2437.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 726,
                "id": 1412,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0837,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2442.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 727,
                "id": 1413,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.083,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2459.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 728,
                "id": 1414,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.0823,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2534.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 729,
                "id": 1415,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0233,
                "lng": 13.0811,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2601.39,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 730,
                "id": 1416,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.0798,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2657.15,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 731,
                "id": 1417,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0201,
                "lng": 13.0773,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2711.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 732,
                "id": 1418,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0193,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2731.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 733,
                "id": 1419,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.0769,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2752.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 734,
                "id": 1420,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0168,
                "lng": 13.0756,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2796.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 735,
                "id": 1421,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0158,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2831.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 736,
                "id": 1422,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.0736,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2879.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 737,
                "id": 1423,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0145,
                "lng": 13.0727,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2877.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 738,
                "id": 1424,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0142,
                "lng": 13.073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2905.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 739,
                "id": 1425,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0141,
                "lng": 13.0724,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2908.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 740,
                "id": 1426,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.0722,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2935.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 741,
                "id": 1427,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0135,
                "lng": 13.0731,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2972.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 742,
                "id": 1428,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.0722,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2935.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 743,
                "id": 1429,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0141,
                "lng": 13.0724,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2908.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 744,
                "id": 1430,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0142,
                "lng": 13.073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2905.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 745,
                "id": 1431,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0145,
                "lng": 13.0727,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2877.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 746,
                "id": 1432,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.0736,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2879.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 747,
                "id": 1433,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0158,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2831.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 748,
                "id": 1434,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0168,
                "lng": 13.0756,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2796.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 749,
                "id": 1435,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.0769,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2752.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 750,
                "id": 1436,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0193,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2731.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 751,
                "id": 1437,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0201,
                "lng": 13.0773,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2711.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 752,
                "id": 1438,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.0798,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2657.15,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 753,
                "id": 1439,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0233,
                "lng": 13.0811,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2601.39,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 754,
                "id": 1440,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.0823,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2534.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 755,
                "id": 1441,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.083,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2459.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 756,
                "id": 1442,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0837,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2442.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 757,
                "id": 1443,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0271,
                "lng": 13.0838,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2433.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 758,
                "id": 1444,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0275,
                "lng": 13.0834,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2419.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 759,
                "id": 1445,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.028,
                "lng": 13.0835,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2390.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 760,
                "id": 1446,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0284,
                "lng": 13.0833,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2361.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 761,
                "id": 1447,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0288,
                "lng": 13.0829,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2334.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 762,
                "id": 1448,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.029,
                "lng": 13.0827,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2321.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 763,
                "id": 1449,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0294,
                "lng": 13.0826,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2297.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 764,
                "id": 1450,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0293,
                "lng": 13.0816,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2271.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 765,
                "id": 1451,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0301,
                "lng": 13.0815,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2216.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 766,
                "id": 1452,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.031,
                "lng": 13.0819,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2165.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 767,
                "id": 1453,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.032,
                "lng": 13.0813,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2135.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 768,
                "id": 1454,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0328,
                "lng": 13.0803,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2126.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 769,
                "id": 1455,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0343,
                "lng": 13.0792,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2112.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 770,
                "id": 1456,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0348,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2047.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 771,
                "id": 1457,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0347,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1999.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 772,
                "id": 1458,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0352,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1991.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 773,
                "id": 1459,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0353,
                "lng": 13.0749,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1937.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 774,
                "id": 1460,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0358,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1902.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 775,
                "id": 1461,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0357,
                "lng": 13.0737,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1880.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 776,
                "id": 1462,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0361,
                "lng": 13.0735,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 777,
                "id": 1463,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0372,
                "lng": 13.0719,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1772.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 778,
                "id": 1464,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0387,
                "lng": 13.0713,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1709.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 779,
                "id": 1465,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0395,
                "lng": 13.0696,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1662.82,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 780,
                "id": 1466,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0401,
                "lng": 13.067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1638.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 781,
                "id": 1467,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0413,
                "lng": 13.0651,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 782,
                "id": 1468,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0429,
                "lng": 13.0636,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1630.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 783,
                "id": 1469,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0448,
                "lng": 13.0629,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1621.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 784,
                "id": 1470,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0472,
                "lng": 13.0597,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 785,
                "id": 1471,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.049,
                "lng": 13.058,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 786,
                "id": 1472,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0499,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.41,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 787,
                "id": 1473,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0503,
                "lng": 13.0573,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 788,
                "id": 1474,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0511,
                "lng": 13.0574,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1608.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 789,
                "id": 1475,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0516,
                "lng": 13.057,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1606.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 790,
                "id": 1476,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0524,
                "lng": 13.0569,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1604.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 791,
                "id": 1477,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0548,
                "lng": 13.0547,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1592.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 792,
                "id": 1478,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0553,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1590.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 793,
                "id": 1479,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0559,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1588.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 794,
                "id": 1480,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0565,
                "lng": 13.0558,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 795,
                "id": 1481,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0569,
                "lng": 13.0559,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 796,
                "id": 1482,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0574,
                "lng": 13.0561,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 797,
                "id": 1483,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0586,
                "lng": 13.0563,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1582.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 798,
                "id": 1484,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0595,
                "lng": 13.0575,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            },
            {
                "@id": 799,
                "id": 1485,
                "date": "2024-07-10T13:23:36.010641Z",
                "descr": null,
                "lat": 47.0612,
                "lng": 13.0577,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1581.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 682
            }
        ],
        "tblUser": 1
    },
    {
        "@id": 800,
        "id": 54,
        "date": "2024-07-10T13:23:37.170794Z",
        "descr": "Rameterspitz oder Romatenspitz 2696mTrack Description",
        "name": "Rameterspitz oder Romatenspitz 2696m",
        "zoomCenterPoint": "lat:47.00098400000015,lng:13.132766999999552",
        "trackDistance": 18.485583513710154,
        "google_encoded_polyline": "c{z}Gw~coAs@DgAtSEjP^vCdBnC?l@m@?eBiAeA}AOcEw@}Ck@oIYG@~Jj@rEe@vEZbCGfCvF`IX~A{@|EPxAOzA^xCEzCnArDb@dDp@fBMd@_@EcBqFsAuBaBwAk@iCSKM^Z|BFfCnArCF`A[`DLlAhD~DnA`ANf@_@LaAaAc@APn@nCdCHb@UF_BmAiB}@gAyA[FLhAjBvD{AdMOHAq@a@[a@fAy@Gm@[]l@k@o@k@A{BbAuA`CiAJuBjB_BnDoAhA]z@IlAXlCOx@a@Rc@QWqBUq@YVIo@gA\\g@_@}ANMk@o@g@cBUkAmCV}CKo@}@M_BZ_Ab@w@D_Bp@gAfAw@nAg@HS\\]LoA}AoAe@qA}AoBcJ_AmCs@[Yc@c@v@c@NuCmDs@CWwAqAwD{AgB{@g@{BkC}BUgAyAmAOcFJ}AgA_BqC]wAFkBkBQCm@MOLs@s@uBTgC{@sBJ_BCkA_Ay@MkA}@oAUcBk@kA_@yAI_BTsBXcANkB?o@]o@}AKK_@mCbAC_AaCbAY`@]?Wq@?_DRaCcA{@cAVm@hAGhCX_AC{@TGJq@PTPWLb@Xa@Vb@CfAl@lACpAZCJT`@U^z@b@]`@^z@K\\^^[`@NXUPP`@ELZ|@?Nf@ZFJb@h@MBl@d@ALTZWA`@RAUfA`@ROj@Nv@Gh@W@Id@e@BYj@UDAd@X^ULDn@Mb@d@RvB[L\\[dARZcAl@Ep@XN@|@TJa@|ATh@h@FFh@l@DHt@\\t@O\\Df@]F?l@Yl@Jr@Of@PR^AKzAh@nH`@e@H|B`@s@LnAX}@JjA`@k@^jCfBdFZm@?kCXmCm@eD\\RTSV`@\\WR^f@QP`@ZUXd@\\INlAZRD|@ZJU~@RVH`BMbBBjAd@QCbAd@MWlBCdCp@c@f@@z@pF^nEzApHEvAZ@B`@\\B@f@LFF^VEJd@n@{@j@HC`@ZNg@~@`AZg@n@pA\\W^LZOx@`@GHx@`@LGbAt@vAb@pAFtB^~@No@^TDg@l@`AV~BLg@d@BAs@^C@g@ZBRe@XLf@a@Xd@^]VTTk@^R^Ip@`@f@U`@LZQd@ZPc@O{@ZYBgAf@z@Tk@^FBg@d@FTU?g@rBeAvC_@lAm@An@x@A?jAnDfClAX`D?a@]rAi@Re@|@ClD{CzDuB^z@j@Mn@Rj@{@tBqE~ByAVkAlAZk@cAPGaBeB{@e@AYl@R~BDzAd@sAyBmC}BJkBe@sAzAgMmB{DOcA^KbA|AlBz@xAlAVGGa@qCgCOo@b@?`A`A\\MKe@qAaAkD_EMoAZ}CEeAqAqCGeC[_CL_@TLj@jC|AtArApBfBtF^@Ja@q@gBc@gDmAsD@yC[{CN}AQsAz@_FYcByF_IDeCYcCd@{Ek@mE?eKTDn@pIr@|CNdEfA|AdBhAj@@?o@cBqC_@sCDoPfAoSp@I",
        "tblgps": [
            {
                "@id": 801,
                "id": 1486,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1328,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1288.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 802,
                "id": 1487,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1327,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1289.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 803,
                "id": 1488,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1294,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1312.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 804,
                "id": 1489,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1266,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1336.32,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 805,
                "id": 1490,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1259,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1342.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 806,
                "id": 1491,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1352.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 807,
                "id": 1492,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1354.12,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 808,
                "id": 1493,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1358.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 809,
                "id": 1494,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1363.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 810,
                "id": 1495,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1258,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1368.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 811,
                "id": 1496,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1267,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1375.52,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 812,
                "id": 1497,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1275,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1381.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 813,
                "id": 1498,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1292,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1392.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 814,
                "id": 1499,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0028,
                "lng": 13.1293,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1393.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 815,
                "id": 1500,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0028,
                "lng": 13.1273,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1408.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 816,
                "id": 1501,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.1263,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1414.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 817,
                "id": 1502,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0027,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1422.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 818,
                "id": 1503,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1427.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 819,
                "id": 1504,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1239,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1427.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 820,
                "id": 1505,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1223,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1448.03,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 821,
                "id": 1506,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1218,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1452.48,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 822,
                "id": 1507,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1207,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1463.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 823,
                "id": 1508,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1202,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1467.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 824,
                "id": 1509,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1197,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1470.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 825,
                "id": 1510,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1477.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 826,
                "id": 1511,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1182,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1484.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 827,
                "id": 1512,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1173,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1489.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 828,
                "id": 1513,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1165,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1497.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 829,
                "id": 1514,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1159,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1500.87,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 830,
                "id": 1515,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1502.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 831,
                "id": 1516,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1505.6,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 832,
                "id": 1517,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.117,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1513.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 833,
                "id": 1518,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1176,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1521.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 834,
                "id": 1519,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0022,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1526.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 835,
                "id": 1520,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1187,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1531.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 836,
                "id": 1521,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.1188,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1533.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 837,
                "id": 1522,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1536.75,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 838,
                "id": 1523,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1539.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 839,
                "id": 1524,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1173,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1547.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 840,
                "id": 1525,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1166,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1555.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 841,
                "id": 1526,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1162,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1559.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 842,
                "id": 1527,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1154,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1565.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 843,
                "id": 1528,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1569.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 844,
                "id": 1529,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1141,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 845,
                "id": 1530,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1137,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1587.32,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 846,
                "id": 1531,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1136,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1589.32,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 847,
                "id": 1532,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0009,
                "lng": 13.1135,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1594.6,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 848,
                "id": 1533,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1596.09,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 849,
                "id": 1534,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1599.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 850,
                "id": 1535,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.1136,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1602.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 851,
                "id": 1536,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1129,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1610.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 852,
                "id": 1537,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0005,
                "lng": 13.1127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 853,
                "id": 1538,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1614.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 854,
                "id": 1539,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0011,
                "lng": 13.1131,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1620.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 855,
                "id": 1540,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1134,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1626.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 856,
                "id": 1541,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1630.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 857,
                "id": 1542,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0022,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 858,
                "id": 1543,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1134,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1640.21,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 859,
                "id": 1544,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1648.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 860,
                "id": 1545,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1669.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 861,
                "id": 1546,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1671.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 862,
                "id": 1547,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1672.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 863,
                "id": 1548,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0023,
                "lng": 13.1106,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1675.37,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 864,
                "id": 1549,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1685.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 865,
                "id": 1550,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0027,
                "lng": 13.1103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1684.12,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 866,
                "id": 1551,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.003,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1693.45,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 867,
                "id": 1552,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0031,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1702.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 868,
                "id": 1553,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0033,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1709.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 869,
                "id": 1554,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0035,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1718.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 870,
                "id": 1555,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0042,
                "lng": 13.1101,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1740.9,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 871,
                "id": 1556,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0046,
                "lng": 13.1094,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1765.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 872,
                "id": 1557,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.005,
                "lng": 13.1094,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1772.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 873,
                "id": 1558,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0056,
                "lng": 13.1088,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1788.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 874,
                "id": 1559,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.006,
                "lng": 13.108,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1808.95,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 875,
                "id": 1560,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0064,
                "lng": 13.1076,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1818.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 876,
                "id": 1561,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0066,
                "lng": 13.1073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1819.07,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 877,
                "id": 1562,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0066,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1821.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 878,
                "id": 1563,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0065,
                "lng": 13.1062,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1832.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 879,
                "id": 1564,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0066,
                "lng": 13.1059,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1839.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 880,
                "id": 1565,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0068,
                "lng": 13.1058,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1839.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 881,
                "id": 1566,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0069,
                "lng": 13.1059,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1844.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 882,
                "id": 1567,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0071,
                "lng": 13.1065,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1855.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 883,
                "id": 1568,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0072,
                "lng": 13.1067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1863.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 884,
                "id": 1569,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0073,
                "lng": 13.1066,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1868.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 885,
                "id": 1570,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0073,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1873.07,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 886,
                "id": 1571,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0077,
                "lng": 13.1067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1887.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 887,
                "id": 1572,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0079,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1894.75,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 888,
                "id": 1573,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0084,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1907.6,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 889,
                "id": 1574,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0084,
                "lng": 13.107,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1911.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 890,
                "id": 1575,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0087,
                "lng": 13.1072,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1917.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 891,
                "id": 1576,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0092,
                "lng": 13.1073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1933.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 892,
                "id": 1577,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0096,
                "lng": 13.108,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1948.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 893,
                "id": 1578,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0094,
                "lng": 13.1088,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1966.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 894,
                "id": 1579,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0095,
                "lng": 13.109,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1968.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 895,
                "id": 1580,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0098,
                "lng": 13.1091,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1974.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 896,
                "id": 1581,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0103,
                "lng": 13.109,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1988.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 897,
                "id": 1582,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0106,
                "lng": 13.1088,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1994.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 898,
                "id": 1583,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0109,
                "lng": 13.1087,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1995.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 899,
                "id": 1584,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0114,
                "lng": 13.1085,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2002.01,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 900,
                "id": 1585,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0117,
                "lng": 13.1081,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2008.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 901,
                "id": 1586,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.012,
                "lng": 13.1077,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2019.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 902,
                "id": 1587,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0122,
                "lng": 13.1077,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2022.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 903,
                "id": 1588,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0123,
                "lng": 13.1075,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2025.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 904,
                "id": 1589,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0125,
                "lng": 13.1075,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2033.94,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 905,
                "id": 1590,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0129,
                "lng": 13.1079,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2044.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 906,
                "id": 1591,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0133,
                "lng": 13.1081,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2048.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 907,
                "id": 1592,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.1086,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2052.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 908,
                "id": 1593,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0142,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2070.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 909,
                "id": 1594,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.1111,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2090.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 910,
                "id": 1595,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0148,
                "lng": 13.1112,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2099.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 911,
                "id": 1596,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0149,
                "lng": 13.1114,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2105.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 912,
                "id": 1597,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0151,
                "lng": 13.1111,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2111.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 913,
                "id": 1598,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0153,
                "lng": 13.111,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2115.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 914,
                "id": 1599,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0161,
                "lng": 13.1119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2127.21,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 915,
                "id": 1600,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0163,
                "lng": 13.1119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2130.6,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 916,
                "id": 1601,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0164,
                "lng": 13.1124,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2134.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 917,
                "id": 1602,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0168,
                "lng": 13.1133,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2144.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 918,
                "id": 1603,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0173,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2147.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 919,
                "id": 1604,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0176,
                "lng": 13.114,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2148.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 920,
                "id": 1605,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0182,
                "lng": 13.1147,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2158.54,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 921,
                "id": 1606,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0189,
                "lng": 13.1148,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2173.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 922,
                "id": 1607,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0192,
                "lng": 13.1153,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2190.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 923,
                "id": 1608,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0196,
                "lng": 13.1153,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2202.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 924,
                "id": 1609,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0207,
                "lng": 13.1153,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2232.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 925,
                "id": 1610,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.1157,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2248.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 926,
                "id": 1611,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0217,
                "lng": 13.1164,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2272.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 927,
                "id": 1612,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0219,
                "lng": 13.1168,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2283.03,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 928,
                "id": 1613,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0218,
                "lng": 13.1174,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2293.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 929,
                "id": 1614,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0223,
                "lng": 13.1175,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2316.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 930,
                "id": 1615,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1177,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2322.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 931,
                "id": 1616,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1178,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2327.87,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 932,
                "id": 1617,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2331.03,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 933,
                "id": 1618,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2346.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 934,
                "id": 1619,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0225,
                "lng": 13.1193,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2359.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 935,
                "id": 1620,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1199,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2374.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 936,
                "id": 1621,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1204,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2387.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 937,
                "id": 1622,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1207,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2393.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 938,
                "id": 1623,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.121,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2406.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 939,
                "id": 1624,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.1214,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2413.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 940,
                "id": 1625,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0235,
                "lng": 13.1218,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2426.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 941,
                "id": 1626,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0236,
                "lng": 13.1223,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2441.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 942,
                "id": 1627,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0238,
                "lng": 13.1227,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2452.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 943,
                "id": 1628,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.024,
                "lng": 13.1231,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2468.21,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 944,
                "id": 1629,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.024,
                "lng": 13.1236,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2476.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 945,
                "id": 1630,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0239,
                "lng": 13.1242,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2493.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 946,
                "id": 1631,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0238,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2502.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 947,
                "id": 1632,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.1251,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2516.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 948,
                "id": 1633,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2520.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 949,
                "id": 1634,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0238,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2530.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 950,
                "id": 1635,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0243,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2542.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 951,
                "id": 1636,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0244,
                "lng": 13.1258,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2547.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 952,
                "id": 1637,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0251,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2573.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 953,
                "id": 1638,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0251,
                "lng": 13.1257,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2578.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 954,
                "id": 1639,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2604.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 955,
                "id": 1640,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0259,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2609.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 956,
                "id": 1641,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.026,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2616.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 957,
                "id": 1642,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0262,
                "lng": 13.1255,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2625.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 958,
                "id": 1643,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0262,
                "lng": 13.1263,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2643.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 959,
                "id": 1644,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0261,
                "lng": 13.1269,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2658.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 960,
                "id": 1645,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.1272,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2673.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 961,
                "id": 1646,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0267,
                "lng": 13.1271,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2680.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 962,
                "id": 1647,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.027,
                "lng": 13.1268,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2691.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 963,
                "id": 1648,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.027,
                "lng": 13.1261,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2693.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 964,
                "id": 1649,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0269,
                "lng": 13.1264,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2689.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 965,
                "id": 1650,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0269,
                "lng": 13.1267,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2688.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 966,
                "id": 1651,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0268,
                "lng": 13.1267,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2683.77,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 967,
                "id": 1652,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0267,
                "lng": 13.127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2680.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 968,
                "id": 1653,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0266,
                "lng": 13.1269,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2675.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 969,
                "id": 1654,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0265,
                "lng": 13.127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2672.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 970,
                "id": 1655,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0265,
                "lng": 13.1268,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2669.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 971,
                "id": 1656,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0263,
                "lng": 13.127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2666.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 972,
                "id": 1657,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0262,
                "lng": 13.1268,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2660.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 973,
                "id": 1658,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0263,
                "lng": 13.1264,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2653.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 974,
                "id": 1659,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.026,
                "lng": 13.126,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2628.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 975,
                "id": 1660,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.026,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2619.98,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 976,
                "id": 1661,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0259,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2613.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 977,
                "id": 1662,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.1255,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2609.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 978,
                "id": 1663,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0257,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2603.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 979,
                "id": 1664,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0255,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2591.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 980,
                "id": 1665,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0253,
                "lng": 13.1255,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2585.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 981,
                "id": 1666,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0252,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2575.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 982,
                "id": 1667,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0249,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2561.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 983,
                "id": 1668,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0247,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2552.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 984,
                "id": 1669,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0245,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2547.94,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 985,
                "id": 1670,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0244,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2540.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 986,
                "id": 1671,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0243,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2536.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 987,
                "id": 1672,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0242,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2533.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 988,
                "id": 1673,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.024,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2529.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 989,
                "id": 1674,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0239,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2524.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 990,
                "id": 1675,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0236,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2516.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 991,
                "id": 1676,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0235,
                "lng": 13.125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2510.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 992,
                "id": 1677,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0234,
                "lng": 13.125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2504.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 993,
                "id": 1678,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0233,
                "lng": 13.1248,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2498.77,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 994,
                "id": 1679,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2495.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 995,
                "id": 1680,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1246,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2490.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 996,
                "id": 1681,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0229,
                "lng": 13.1246,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2481.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 997,
                "id": 1682,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2474.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 998,
                "id": 1683,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1246,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2473.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 999,
                "id": 1684,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2472.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1000,
                "id": 1685,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2472.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1001,
                "id": 1686,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1241,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2469.15,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1002,
                "id": 1687,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0225,
                "lng": 13.124,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2462.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1003,
                "id": 1688,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1238,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2460.77,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1004,
                "id": 1689,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1235,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2457.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1005,
                "id": 1690,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1233,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2457.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1006,
                "id": 1691,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1233,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2454.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1007,
                "id": 1692,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1231,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2451.54,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1008,
                "id": 1693,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0229,
                "lng": 13.1231,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2448.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1009,
                "id": 1694,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1229,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2444.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1010,
                "id": 1695,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.1228,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2444.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1011,
                "id": 1696,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.1227,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2442.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1012,
                "id": 1697,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1225,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2439.37,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1013,
                "id": 1698,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.1224,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2437.54,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1014,
                "id": 1699,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1222,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2432.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1015,
                "id": 1700,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.122,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2428.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1016,
                "id": 1701,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.023,
                "lng": 13.1219,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2424.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1017,
                "id": 1702,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1221,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2416.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1018,
                "id": 1703,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1219,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2412.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1019,
                "id": 1704,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0225,
                "lng": 13.1215,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2413.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1020,
                "id": 1705,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1214,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2410.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1021,
                "id": 1706,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1212,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2407.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1022,
                "id": 1707,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1209,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2397.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1023,
                "id": 1708,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1209,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2393.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1024,
                "id": 1709,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1205,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2392.95,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1025,
                "id": 1710,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0225,
                "lng": 13.1205,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2391.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1026,
                "id": 1711,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.12,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2378.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1027,
                "id": 1712,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1198,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2368.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1028,
                "id": 1713,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1198,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2363.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1029,
                "id": 1714,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0223,
                "lng": 13.1196,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2357.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1030,
                "id": 1715,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0221,
                "lng": 13.1195,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2352.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1031,
                "id": 1716,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.022,
                "lng": 13.1193,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2351.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1032,
                "id": 1717,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0219,
                "lng": 13.119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2350.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1033,
                "id": 1718,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.022,
                "lng": 13.1188,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2347.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1034,
                "id": 1719,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0219,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2342.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1035,
                "id": 1720,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0221,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2342.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1036,
                "id": 1721,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0221,
                "lng": 13.1184,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2336.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1037,
                "id": 1722,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0222,
                "lng": 13.1181,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2331.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1038,
                "id": 1723,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0222,
                "lng": 13.1179,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2325.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1039,
                "id": 1724,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0222,
                "lng": 13.1177,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2318.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1040,
                "id": 1725,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0222,
                "lng": 13.1176,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2312.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1041,
                "id": 1726,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.022,
                "lng": 13.1176,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2307.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1042,
                "id": 1727,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0221,
                "lng": 13.1171,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2298.45,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1043,
                "id": 1728,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0218,
                "lng": 13.1156,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2281.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1044,
                "id": 1729,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0217,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2272.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1045,
                "id": 1730,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0216,
                "lng": 13.1152,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2269.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1046,
                "id": 1731,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0215,
                "lng": 13.1154,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2258.98,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1047,
                "id": 1732,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0214,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2256.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1048,
                "id": 1733,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0213,
                "lng": 13.1153,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2249.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1049,
                "id": 1734,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2246.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1050,
                "id": 1735,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.021,
                "lng": 13.1152,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2241.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1051,
                "id": 1736,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0209,
                "lng": 13.1145,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2236.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1052,
                "id": 1737,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0204,
                "lng": 13.1133,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2227.91,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1053,
                "id": 1738,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0202,
                "lng": 13.1135,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2225.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1054,
                "id": 1739,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0202,
                "lng": 13.1142,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2222.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1055,
                "id": 1740,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0201,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2216.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1056,
                "id": 1741,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0203,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2213.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1057,
                "id": 1742,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0202,
                "lng": 13.1157,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2211.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1058,
                "id": 1743,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.02,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2210.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1059,
                "id": 1744,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0199,
                "lng": 13.1156,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2206.94,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1060,
                "id": 1745,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0198,
                "lng": 13.1157,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2206.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1061,
                "id": 1746,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0197,
                "lng": 13.1156,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2201.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1062,
                "id": 1747,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0195,
                "lng": 13.1157,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2199.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1063,
                "id": 1748,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0194,
                "lng": 13.1155,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2194.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1064,
                "id": 1749,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0192,
                "lng": 13.1156,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2195.37,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1065,
                "id": 1750,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0191,
                "lng": 13.1154,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2187.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1066,
                "id": 1751,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.019,
                "lng": 13.1155,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2185.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1067,
                "id": 1752,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0189,
                "lng": 13.1151,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2176.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1068,
                "id": 1753,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2170.03,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1069,
                "id": 1754,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.1147,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2166.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1070,
                "id": 1755,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0186,
                "lng": 13.1146,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2162.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1071,
                "id": 1756,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.1143,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2161.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1072,
                "id": 1757,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0186,
                "lng": 13.1142,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2158.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1073,
                "id": 1758,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0185,
                "lng": 13.1137,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2156.75,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1074,
                "id": 1759,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0186,
                "lng": 13.1132,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2155.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1075,
                "id": 1760,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0186,
                "lng": 13.1128,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2153.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1076,
                "id": 1761,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0184,
                "lng": 13.1129,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2146.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1077,
                "id": 1762,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0184,
                "lng": 13.1125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2144.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1078,
                "id": 1763,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0182,
                "lng": 13.1126,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2140.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1079,
                "id": 1764,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0184,
                "lng": 13.1121,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2136.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1080,
                "id": 1765,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0184,
                "lng": 13.1114,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2130.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1081,
                "id": 1766,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0181,
                "lng": 13.1116,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2128.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1082,
                "id": 1767,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0179,
                "lng": 13.1116,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2125.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1083,
                "id": 1768,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0176,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2123.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1084,
                "id": 1769,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0175,
                "lng": 13.1093,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2118.41,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1085,
                "id": 1770,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.017,
                "lng": 13.1078,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2111.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1086,
                "id": 1771,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.017,
                "lng": 13.1074,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2107.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1087,
                "id": 1772,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0169,
                "lng": 13.1073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2104.64,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1088,
                "id": 1773,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0169,
                "lng": 13.1072,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2103.95,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1089,
                "id": 1774,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0167,
                "lng": 13.1071,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2099.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1090,
                "id": 1775,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0167,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2096.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1091,
                "id": 1776,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0166,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2093.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1092,
                "id": 1777,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0166,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2089.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1093,
                "id": 1778,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0165,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2086.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1094,
                "id": 1779,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0164,
                "lng": 13.1066,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2083.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1095,
                "id": 1780,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0162,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2082.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1096,
                "id": 1781,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.016,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2081.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1097,
                "id": 1782,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.016,
                "lng": 13.1067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2077.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1098,
                "id": 1783,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0158,
                "lng": 13.1066,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2070.95,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1099,
                "id": 1784,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.016,
                "lng": 13.1063,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2066.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1100,
                "id": 1785,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0157,
                "lng": 13.1061,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2063.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1101,
                "id": 1786,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0159,
                "lng": 13.1059,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2057.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1102,
                "id": 1787,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0155,
                "lng": 13.1057,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2056.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1103,
                "id": 1788,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0156,
                "lng": 13.1056,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2050.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1104,
                "id": 1789,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0155,
                "lng": 13.1054,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2047.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1105,
                "id": 1790,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0156,
                "lng": 13.1052,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2046.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1106,
                "id": 1791,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0155,
                "lng": 13.1052,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2041.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1107,
                "id": 1792,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0154,
                "lng": 13.1049,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2039.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1108,
                "id": 1793,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0152,
                "lng": 13.1048,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2034.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1109,
                "id": 1794,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0153,
                "lng": 13.1045,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2034.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1110,
                "id": 1795,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.015,
                "lng": 13.104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2029.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1111,
                "id": 1796,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0148,
                "lng": 13.1036,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2023.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1112,
                "id": 1797,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0148,
                "lng": 13.1031,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2022.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1113,
                "id": 1798,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.1027,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2020.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1114,
                "id": 1799,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2012.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1115,
                "id": 1800,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0144,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2005.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1116,
                "id": 1801,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0144,
                "lng": 13.1031,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1999.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1117,
                "id": 1802,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0141,
                "lng": 13.1027,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1993.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1118,
                "id": 1803,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.014,
                "lng": 13.1021,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1986.01,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1119,
                "id": 1804,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0139,
                "lng": 13.1023,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1980.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1120,
                "id": 1805,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.1023,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1975.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1121,
                "id": 1806,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0138,
                "lng": 13.1025,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1972.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1122,
                "id": 1807,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0136,
                "lng": 13.1025,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1966.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1123,
                "id": 1808,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0136,
                "lng": 13.1028,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1964.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1124,
                "id": 1809,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0135,
                "lng": 13.1027,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1960.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1125,
                "id": 1810,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0134,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1956.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1126,
                "id": 1811,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0132,
                "lng": 13.1028,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1951.52,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1127,
                "id": 1812,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.013,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1947.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1128,
                "id": 1813,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0129,
                "lng": 13.1028,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1941.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1129,
                "id": 1814,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0127,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1934.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1130,
                "id": 1815,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0126,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1932.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1131,
                "id": 1816,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0125,
                "lng": 13.1031,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1924.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1132,
                "id": 1817,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0123,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1919.98,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1133,
                "id": 1818,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0122,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1912.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1134,
                "id": 1819,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0119,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1910.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1135,
                "id": 1820,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0117,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1898.09,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1136,
                "id": 1821,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0116,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1892.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1137,
                "id": 1822,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0114,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1885.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1138,
                "id": 1823,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0112,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1879.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1139,
                "id": 1824,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0111,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1871.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1140,
                "id": 1825,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0112,
                "lng": 13.1033,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1869.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1141,
                "id": 1826,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0111,
                "lng": 13.1035,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1865.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1142,
                "id": 1827,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0111,
                "lng": 13.1038,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1862.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1143,
                "id": 1828,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0109,
                "lng": 13.1035,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1860.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1144,
                "id": 1829,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0108,
                "lng": 13.1037,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1857.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1145,
                "id": 1830,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0106,
                "lng": 13.1037,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.64,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1146,
                "id": 1831,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0106,
                "lng": 13.1039,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1147,
                "id": 1832,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0104,
                "lng": 13.1039,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1849.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1148,
                "id": 1833,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0103,
                "lng": 13.104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1848.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1149,
                "id": 1834,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0103,
                "lng": 13.1042,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1848.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1150,
                "id": 1835,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0097,
                "lng": 13.1045,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1844.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1151,
                "id": 1836,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0089,
                "lng": 13.1047,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1838.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1152,
                "id": 1837,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0085,
                "lng": 13.1049,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1835.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1153,
                "id": 1838,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0085,
                "lng": 13.1047,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1830.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1154,
                "id": 1839,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0083,
                "lng": 13.1047,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1825.98,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1155,
                "id": 1840,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0083,
                "lng": 13.1043,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1823.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1156,
                "id": 1841,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0074,
                "lng": 13.1036,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1816.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1157,
                "id": 1842,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.007,
                "lng": 13.1035,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1815.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1158,
                "id": 1843,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0062,
                "lng": 13.1035,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1810.32,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1159,
                "id": 1844,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0064,
                "lng": 13.1036,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1808.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1160,
                "id": 1845,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0059,
                "lng": 13.1039,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1802.94,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1161,
                "id": 1846,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0058,
                "lng": 13.1041,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1803.73,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1162,
                "id": 1847,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0055,
                "lng": 13.1041,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1797.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1163,
                "id": 1848,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0046,
                "lng": 13.1048,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1779.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1164,
                "id": 1849,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0037,
                "lng": 13.1054,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1763.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1165,
                "id": 1850,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0036,
                "lng": 13.1051,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1759.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1166,
                "id": 1851,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0033,
                "lng": 13.1052,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1759.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1167,
                "id": 1852,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0031,
                "lng": 13.1051,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1758.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1168,
                "id": 1853,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0029,
                "lng": 13.1054,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1760.21,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1169,
                "id": 1854,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0023,
                "lng": 13.1065,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1757.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1170,
                "id": 1855,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1748.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1171,
                "id": 1856,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1747.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1172,
                "id": 1857,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0011,
                "lng": 13.1072,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1742.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1173,
                "id": 1858,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1075,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1735.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1174,
                "id": 1859,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.1075,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1730.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1175,
                "id": 1860,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.108,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1723.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1176,
                "id": 1861,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1082,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1721.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1177,
                "id": 1862,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1084,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1718.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1178,
                "id": 1863,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0018,
                "lng": 13.1083,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1717.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1179,
                "id": 1864,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1082,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1705.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1180,
                "id": 1865,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.108,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1698.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1181,
                "id": 1866,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0011,
                "lng": 13.1087,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1690.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1182,
                "id": 1867,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0019,
                "lng": 13.1093,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1677.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1183,
                "id": 1868,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0018,
                "lng": 13.1098,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1671.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1184,
                "id": 1869,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1669.64,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1185,
                "id": 1870,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1648.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1186,
                "id": 1871,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1135,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1638.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1187,
                "id": 1872,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0022,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1188,
                "id": 1873,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1139,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1629.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1189,
                "id": 1874,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1134,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1626.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1190,
                "id": 1875,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0011,
                "lng": 13.1131,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1619.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1191,
                "id": 1876,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1614.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1192,
                "id": 1877,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0005,
                "lng": 13.1127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.73,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1193,
                "id": 1878,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1129,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1609.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1194,
                "id": 1879,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.1136,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1602.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1195,
                "id": 1880,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1598.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1196,
                "id": 1881,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1595.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1197,
                "id": 1882,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0009,
                "lng": 13.1135,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1593.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1198,
                "id": 1883,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1136,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1589.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1199,
                "id": 1884,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1587.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1200,
                "id": 1885,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1141,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1201,
                "id": 1886,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1151,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1569.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1202,
                "id": 1887,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1154,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1565.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1203,
                "id": 1888,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1162,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1559.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1204,
                "id": 1889,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1166,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1555.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1205,
                "id": 1890,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1173,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1547.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1206,
                "id": 1891,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1540.01,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1207,
                "id": 1892,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1536.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1208,
                "id": 1893,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.1188,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1533.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1209,
                "id": 1894,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1187,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1531.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1210,
                "id": 1895,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0022,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1526.64,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1211,
                "id": 1896,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1176,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1521.45,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1212,
                "id": 1897,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.117,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1513.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1213,
                "id": 1898,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1505.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1214,
                "id": 1899,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1502.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1215,
                "id": 1900,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1159,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1500.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1216,
                "id": 1901,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1165,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1497.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1217,
                "id": 1902,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1173,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1489.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1218,
                "id": 1903,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1182,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1483.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1219,
                "id": 1904,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1477.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1220,
                "id": 1905,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1198,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1470.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1221,
                "id": 1906,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1202,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1466.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1222,
                "id": 1907,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1206,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1463.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1223,
                "id": 1908,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1218,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1452.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1224,
                "id": 1909,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1223,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1447.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1225,
                "id": 1910,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1239,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1427.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1226,
                "id": 1911,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1427.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1227,
                "id": 1912,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0027,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1422.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1228,
                "id": 1913,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.1263,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1414.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1229,
                "id": 1914,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0028,
                "lng": 13.1273,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1408.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1230,
                "id": 1915,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0028,
                "lng": 13.1293,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1393.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1231,
                "id": 1916,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1293,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1392.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1232,
                "id": 1917,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1276,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1381.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1233,
                "id": 1918,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1268,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1376.07,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1234,
                "id": 1919,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1258,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1369.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1235,
                "id": 1920,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1363.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1236,
                "id": 1921,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1358.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1237,
                "id": 1922,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1354.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1238,
                "id": 1923,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1352.48,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1239,
                "id": 1924,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1259,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1342.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1240,
                "id": 1925,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1266,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1336.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1241,
                "id": 1926,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1294,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1312.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1242,
                "id": 1927,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1327,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1290.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            },
            {
                "@id": 1243,
                "id": 1928,
                "date": "2024-07-10T13:23:37.170794Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1328,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1288.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 800
            }
        ],
        "tblUser": 1
    },
    {
        "@id": 1244,
        "id": 102,
        "date": "2024-07-10T14:24:29.657360Z",
        "descr": "Vorderer Geißlkopf oder Hohe Geißel 2974mTrack Description",
        "name": "Vorderer Geißlkopf oder Hohe Geißel 2974m",
        "zoomCenterPoint": "lat:47.06115650001463,lng:13.057751000003808",
        "trackDistance": 14.871965284409585,
        "google_encoded_polyline": "esf~GijunAdIbAtDpFzFVrAv@jALhCvDjBCdBh@`N}L`DKdBmA|CLrAkBfDe@dJsI~M_S`KqCbIgHtFoJdBkOnD{I~GiBxEeItAc@IcC|AwBV}FdB?W}HbBqGhHkFlFuPxB{Ms@kFgH}HvG}DdJrBhKcAtCbC}@~G^hIvB|BGzD|BtCbL`CpAvFrKxFfF|NbDcBrBbDpJlGlEhGnFlCTjD`A_AH~BpA`@d@wDe@vDqAa@I_CaA~@UkDoFmCmEiGqJmGsBcDcDbBgF}NsKyFqAwFcLaC}BuCkCOqArA{AW{Al@mAlA[`@qAPFpEsCR}DsAgEhBwChEiHjFcBpGV|HeB?W|F}AvBHbCuAb@yEdI_HhBoDzIeBjOuFnJcIfHaKpC_N~ReJrIgDd@sAjB}CMeBlAaDJaN|LeBi@kBBiCwDkAMsAw@{FWuDqFgIk@",
        "tblgps": [
            {
                "@id": 1245,
                "id": 1952,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0612,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1581.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1246,
                "id": 1953,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0595,
                "lng": 13.0575,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1247,
                "id": 1954,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0586,
                "lng": 13.0563,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1582.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1248,
                "id": 1955,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0574,
                "lng": 13.0561,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1249,
                "id": 1956,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0569,
                "lng": 13.0559,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1250,
                "id": 1957,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0565,
                "lng": 13.0558,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1251,
                "id": 1958,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0559,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1588.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1252,
                "id": 1959,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0553,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1590.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1253,
                "id": 1960,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0548,
                "lng": 13.0547,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1592.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1254,
                "id": 1961,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0524,
                "lng": 13.0569,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1604.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1255,
                "id": 1962,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0516,
                "lng": 13.057,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1606.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1256,
                "id": 1963,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0511,
                "lng": 13.0574,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1608.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1257,
                "id": 1964,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0503,
                "lng": 13.0573,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1258,
                "id": 1965,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0499,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.41,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1259,
                "id": 1966,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.049,
                "lng": 13.058,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1260,
                "id": 1967,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0472,
                "lng": 13.0597,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1261,
                "id": 1968,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0448,
                "lng": 13.0629,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1621.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1262,
                "id": 1969,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0429,
                "lng": 13.0636,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1630.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1263,
                "id": 1970,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0413,
                "lng": 13.0651,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1264,
                "id": 1971,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0401,
                "lng": 13.067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1638.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1265,
                "id": 1972,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0395,
                "lng": 13.0696,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1662.82,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1266,
                "id": 1973,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0387,
                "lng": 13.0713,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1709.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1267,
                "id": 1974,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0372,
                "lng": 13.0719,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1772.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1268,
                "id": 1975,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0361,
                "lng": 13.0735,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1269,
                "id": 1976,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0357,
                "lng": 13.0737,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1880.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1270,
                "id": 1977,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0358,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1902.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1271,
                "id": 1978,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0353,
                "lng": 13.0749,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1937.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1272,
                "id": 1979,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0352,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1991.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1273,
                "id": 1980,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0347,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1999.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1274,
                "id": 1981,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0348,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2047.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1275,
                "id": 1982,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0343,
                "lng": 13.0792,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2112.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1276,
                "id": 1983,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0328,
                "lng": 13.0803,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2126.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1277,
                "id": 1984,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0316,
                "lng": 13.0832,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2165.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1278,
                "id": 1985,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.031,
                "lng": 13.0856,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2209.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1279,
                "id": 1986,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0313,
                "lng": 13.0867,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2239.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1280,
                "id": 1987,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0327,
                "lng": 13.0883,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2316.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1281,
                "id": 1988,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0313,
                "lng": 13.0893,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2380.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1282,
                "id": 1989,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0295,
                "lng": 13.0887,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2371.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1283,
                "id": 1990,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0276,
                "lng": 13.089,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2443.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1284,
                "id": 1991,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0268,
                "lng": 13.0884,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2425.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1285,
                "id": 1992,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0271,
                "lng": 13.0869,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2445.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1286,
                "id": 1993,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.027,
                "lng": 13.0853,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2432.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1287,
                "id": 1994,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0847,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2437.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1288,
                "id": 1995,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0837,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2442.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1289,
                "id": 1996,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.083,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2459.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1290,
                "id": 1997,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.0823,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2534.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1291,
                "id": 1998,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0233,
                "lng": 13.0811,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2601.39,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1292,
                "id": 1999,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.0798,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2657.15,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1293,
                "id": 2000,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0201,
                "lng": 13.0773,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2711.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1294,
                "id": 2001,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0193,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2731.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1295,
                "id": 2002,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.0769,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2752.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1296,
                "id": 2003,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0168,
                "lng": 13.0756,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2796.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1297,
                "id": 2004,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0158,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2831.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1298,
                "id": 2005,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.0736,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2879.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1299,
                "id": 2006,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0145,
                "lng": 13.0727,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2877.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1300,
                "id": 2007,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0142,
                "lng": 13.073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2905.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1301,
                "id": 2008,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0141,
                "lng": 13.0724,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2908.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1302,
                "id": 2009,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.0722,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2935.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1303,
                "id": 2010,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0135,
                "lng": 13.0731,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2972.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1304,
                "id": 2011,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.0722,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2935.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1305,
                "id": 2012,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0141,
                "lng": 13.0724,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2908.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1306,
                "id": 2013,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0142,
                "lng": 13.073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2905.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1307,
                "id": 2014,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0145,
                "lng": 13.0727,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2877.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1308,
                "id": 2015,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.0736,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2879.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1309,
                "id": 2016,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0158,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2831.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1310,
                "id": 2017,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0168,
                "lng": 13.0756,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2796.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1311,
                "id": 2018,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.0769,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2752.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1312,
                "id": 2019,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0193,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2731.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1313,
                "id": 2020,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0201,
                "lng": 13.0773,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2711.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1314,
                "id": 2021,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.0798,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2657.15,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1315,
                "id": 2022,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0233,
                "lng": 13.0811,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2601.39,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1316,
                "id": 2023,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.0823,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2534.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1317,
                "id": 2024,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.083,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2459.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1318,
                "id": 2025,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0837,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2442.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1319,
                "id": 2026,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0271,
                "lng": 13.0838,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2433.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1320,
                "id": 2027,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0275,
                "lng": 13.0834,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2419.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1321,
                "id": 2028,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.028,
                "lng": 13.0835,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2390.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1322,
                "id": 2029,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0284,
                "lng": 13.0833,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2361.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1323,
                "id": 2030,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0288,
                "lng": 13.0829,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2334.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1324,
                "id": 2031,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.029,
                "lng": 13.0827,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2321.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1325,
                "id": 2032,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0294,
                "lng": 13.0826,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2297.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1326,
                "id": 2033,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0293,
                "lng": 13.0816,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2271.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1327,
                "id": 2034,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0301,
                "lng": 13.0815,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2216.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1328,
                "id": 2035,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.031,
                "lng": 13.0819,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2165.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1329,
                "id": 2036,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.032,
                "lng": 13.0813,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2135.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1330,
                "id": 2037,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0328,
                "lng": 13.0803,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2126.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1331,
                "id": 2038,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0343,
                "lng": 13.0792,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2112.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1332,
                "id": 2039,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0348,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2047.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1333,
                "id": 2040,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0347,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1999.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1334,
                "id": 2041,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0352,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1991.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1335,
                "id": 2042,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0353,
                "lng": 13.0749,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1937.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1336,
                "id": 2043,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0358,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1902.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1337,
                "id": 2044,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0357,
                "lng": 13.0737,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1880.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1338,
                "id": 2045,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0361,
                "lng": 13.0735,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1339,
                "id": 2046,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0372,
                "lng": 13.0719,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1772.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1340,
                "id": 2047,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0387,
                "lng": 13.0713,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1709.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1341,
                "id": 2048,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0395,
                "lng": 13.0696,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1662.82,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1342,
                "id": 2049,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0401,
                "lng": 13.067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1638.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1343,
                "id": 2050,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0413,
                "lng": 13.0651,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1344,
                "id": 2051,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0429,
                "lng": 13.0636,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1630.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1345,
                "id": 2052,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0448,
                "lng": 13.0629,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1621.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1346,
                "id": 2053,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0472,
                "lng": 13.0597,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1347,
                "id": 2054,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.049,
                "lng": 13.058,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1348,
                "id": 2055,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0499,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.41,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1349,
                "id": 2056,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0503,
                "lng": 13.0573,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1350,
                "id": 2057,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0511,
                "lng": 13.0574,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1608.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1351,
                "id": 2058,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0516,
                "lng": 13.057,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1606.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1352,
                "id": 2059,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0524,
                "lng": 13.0569,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1604.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1353,
                "id": 2060,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0548,
                "lng": 13.0547,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1592.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1354,
                "id": 2061,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0553,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1590.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1355,
                "id": 2062,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0559,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1588.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1356,
                "id": 2063,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0565,
                "lng": 13.0558,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1357,
                "id": 2064,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0569,
                "lng": 13.0559,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1358,
                "id": 2065,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0574,
                "lng": 13.0561,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1359,
                "id": 2066,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0586,
                "lng": 13.0563,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1582.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1360,
                "id": 2067,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0595,
                "lng": 13.0575,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            },
            {
                "@id": 1361,
                "id": 2068,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0612,
                "lng": 13.0577,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1581.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1244
            }
        ],
        "tblUser": 1
    },
    {
        "@id": 1362,
        "id": 103,
        "date": "2024-07-10T14:24:29.657360Z",
        "descr": "Vorderer Geißlkopf oder Hohe Geißel 2974mTrack Description",
        "name": "Vorderer Geißlkopf oder Hohe Geißel 2974m",
        "zoomCenterPoint": "lat:47.06115650001463,lng:13.057751000003808",
        "trackDistance": 14.871965284409585,
        "google_encoded_polyline": "esf~GijunAdIbAtDpFzFVrAv@jALhCvDjBCdBh@`N}L`DKdBmA|CLrAkBfDe@dJsI~M_S`KqCbIgHtFoJdBkOnD{I~GiBxEeItAc@IcC|AwBV}FdB?W}HbBqGhHkFlFuPxB{Ms@kFgH}HvG}DdJrBhKcAtCbC}@~G^hIvB|BGzD|BtCbL`CpAvFrKxFfF|NbDcBrBbDpJlGlEhGnFlCTjD`A_AH~BpA`@d@wDe@vDqAa@I_CaA~@UkDoFmCmEiGqJmGsBcDcDbBgF}NsKyFqAwFcLaC}BuCkCOqArA{AW{Al@mAlA[`@qAPFpEsCR}DsAgEhBwChEiHjFcBpGV|HeB?W|F}AvBHbCuAb@yEdI_HhBoDzIeBjOuFnJcIfHaKpC_N~ReJrIgDd@sAjB}CMeBlAaDJaN|LeBi@kBBiCwDkAMsAw@{FWuDqFgIk@",
        "tblgps": [
            {
                "@id": 1363,
                "id": 2069,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0612,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1581.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1364,
                "id": 2070,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0595,
                "lng": 13.0575,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1365,
                "id": 2071,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0586,
                "lng": 13.0563,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1582.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1366,
                "id": 2072,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0574,
                "lng": 13.0561,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1367,
                "id": 2073,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0569,
                "lng": 13.0559,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1368,
                "id": 2074,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0565,
                "lng": 13.0558,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1369,
                "id": 2075,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0559,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1588.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1370,
                "id": 2076,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0553,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1590.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1371,
                "id": 2077,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0548,
                "lng": 13.0547,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1592.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1372,
                "id": 2078,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0524,
                "lng": 13.0569,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1604.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1373,
                "id": 2079,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0516,
                "lng": 13.057,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1606.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1374,
                "id": 2080,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0511,
                "lng": 13.0574,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1608.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1375,
                "id": 2081,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0503,
                "lng": 13.0573,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1376,
                "id": 2082,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0499,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.41,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1377,
                "id": 2083,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.049,
                "lng": 13.058,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1378,
                "id": 2084,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0472,
                "lng": 13.0597,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1379,
                "id": 2085,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0448,
                "lng": 13.0629,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1621.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1380,
                "id": 2086,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0429,
                "lng": 13.0636,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1630.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1381,
                "id": 2087,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0413,
                "lng": 13.0651,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1382,
                "id": 2088,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0401,
                "lng": 13.067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1638.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1383,
                "id": 2089,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0395,
                "lng": 13.0696,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1662.82,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1384,
                "id": 2090,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0387,
                "lng": 13.0713,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1709.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1385,
                "id": 2091,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0372,
                "lng": 13.0719,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1772.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1386,
                "id": 2092,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0361,
                "lng": 13.0735,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1387,
                "id": 2093,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0357,
                "lng": 13.0737,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1880.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1388,
                "id": 2094,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0358,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1902.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1389,
                "id": 2095,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0353,
                "lng": 13.0749,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1937.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1390,
                "id": 2096,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0352,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1991.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1391,
                "id": 2097,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0347,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1999.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1392,
                "id": 2098,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0348,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2047.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1393,
                "id": 2099,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0343,
                "lng": 13.0792,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2112.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1394,
                "id": 2100,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0328,
                "lng": 13.0803,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2126.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1395,
                "id": 2101,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0316,
                "lng": 13.0832,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2165.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1396,
                "id": 2102,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.031,
                "lng": 13.0856,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2209.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1397,
                "id": 2103,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0313,
                "lng": 13.0867,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2239.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1398,
                "id": 2104,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0327,
                "lng": 13.0883,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2316.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1399,
                "id": 2105,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0313,
                "lng": 13.0893,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2380.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1400,
                "id": 2106,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0295,
                "lng": 13.0887,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2371.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1401,
                "id": 2107,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0276,
                "lng": 13.089,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2443.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1402,
                "id": 2108,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0268,
                "lng": 13.0884,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2425.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1403,
                "id": 2109,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0271,
                "lng": 13.0869,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2445.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1404,
                "id": 2110,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.027,
                "lng": 13.0853,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2432.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1405,
                "id": 2111,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0847,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2437.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1406,
                "id": 2112,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0837,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2442.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1407,
                "id": 2113,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.083,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2459.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1408,
                "id": 2114,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.0823,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2534.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1409,
                "id": 2115,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0233,
                "lng": 13.0811,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2601.39,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1410,
                "id": 2116,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.0798,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2657.15,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1411,
                "id": 2117,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0201,
                "lng": 13.0773,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2711.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1412,
                "id": 2118,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0193,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2731.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1413,
                "id": 2119,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.0769,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2752.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1414,
                "id": 2120,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0168,
                "lng": 13.0756,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2796.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1415,
                "id": 2121,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0158,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2831.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1416,
                "id": 2122,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.0736,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2879.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1417,
                "id": 2123,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0145,
                "lng": 13.0727,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2877.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1418,
                "id": 2124,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0142,
                "lng": 13.073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2905.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1419,
                "id": 2125,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0141,
                "lng": 13.0724,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2908.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1420,
                "id": 2126,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.0722,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2935.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1421,
                "id": 2127,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0135,
                "lng": 13.0731,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2972.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1422,
                "id": 2128,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.0722,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2935.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1423,
                "id": 2129,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0141,
                "lng": 13.0724,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2908.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1424,
                "id": 2130,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0142,
                "lng": 13.073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2905.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1425,
                "id": 2131,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0145,
                "lng": 13.0727,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2877.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1426,
                "id": 2132,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.0736,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2879.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1427,
                "id": 2133,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0158,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2831.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1428,
                "id": 2134,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0168,
                "lng": 13.0756,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2796.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1429,
                "id": 2135,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.0769,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2752.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1430,
                "id": 2136,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0193,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2731.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1431,
                "id": 2137,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0201,
                "lng": 13.0773,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2711.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1432,
                "id": 2138,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.0798,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2657.15,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1433,
                "id": 2139,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0233,
                "lng": 13.0811,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2601.39,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1434,
                "id": 2140,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.0823,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2534.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1435,
                "id": 2141,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.083,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2459.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1436,
                "id": 2142,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0837,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2442.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1437,
                "id": 2143,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0271,
                "lng": 13.0838,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2433.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1438,
                "id": 2144,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0275,
                "lng": 13.0834,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2419.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1439,
                "id": 2145,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.028,
                "lng": 13.0835,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2390.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1440,
                "id": 2146,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0284,
                "lng": 13.0833,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2361.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1441,
                "id": 2147,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0288,
                "lng": 13.0829,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2334.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1442,
                "id": 2148,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.029,
                "lng": 13.0827,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2321.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1443,
                "id": 2149,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0294,
                "lng": 13.0826,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2297.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1444,
                "id": 2150,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0293,
                "lng": 13.0816,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2271.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1445,
                "id": 2151,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0301,
                "lng": 13.0815,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2216.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1446,
                "id": 2152,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.031,
                "lng": 13.0819,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2165.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1447,
                "id": 2153,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.032,
                "lng": 13.0813,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2135.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1448,
                "id": 2154,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0328,
                "lng": 13.0803,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2126.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1449,
                "id": 2155,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0343,
                "lng": 13.0792,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2112.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1450,
                "id": 2156,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0348,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2047.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1451,
                "id": 2157,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0347,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1999.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1452,
                "id": 2158,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0352,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1991.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1453,
                "id": 2159,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0353,
                "lng": 13.0749,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1937.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1454,
                "id": 2160,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0358,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1902.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1455,
                "id": 2161,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0357,
                "lng": 13.0737,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1880.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1456,
                "id": 2162,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0361,
                "lng": 13.0735,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1457,
                "id": 2163,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0372,
                "lng": 13.0719,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1772.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1458,
                "id": 2164,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0387,
                "lng": 13.0713,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1709.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1459,
                "id": 2165,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0395,
                "lng": 13.0696,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1662.82,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1460,
                "id": 2166,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0401,
                "lng": 13.067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1638.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1461,
                "id": 2167,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0413,
                "lng": 13.0651,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1462,
                "id": 2168,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0429,
                "lng": 13.0636,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1630.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1463,
                "id": 2169,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0448,
                "lng": 13.0629,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1621.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1464,
                "id": 2170,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0472,
                "lng": 13.0597,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1465,
                "id": 2171,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.049,
                "lng": 13.058,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1466,
                "id": 2172,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0499,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.41,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1467,
                "id": 2173,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0503,
                "lng": 13.0573,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1468,
                "id": 2174,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0511,
                "lng": 13.0574,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1608.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1469,
                "id": 2175,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0516,
                "lng": 13.057,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1606.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1470,
                "id": 2176,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0524,
                "lng": 13.0569,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1604.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1471,
                "id": 2177,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0548,
                "lng": 13.0547,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1592.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1472,
                "id": 2178,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0553,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1590.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1473,
                "id": 2179,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0559,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1588.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1474,
                "id": 2180,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0565,
                "lng": 13.0558,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1475,
                "id": 2181,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0569,
                "lng": 13.0559,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1476,
                "id": 2182,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0574,
                "lng": 13.0561,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1477,
                "id": 2183,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0586,
                "lng": 13.0563,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1582.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1478,
                "id": 2184,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0595,
                "lng": 13.0575,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            },
            {
                "@id": 1479,
                "id": 2185,
                "date": "2024-07-10T14:24:29.657360Z",
                "descr": null,
                "lat": 47.0612,
                "lng": 13.0577,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1581.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1362
            }
        ],
        "tblUser": 1
    },
    {
        "@id": 1480,
        "id": 104,
        "date": "2024-07-10T14:24:30.539861Z",
        "descr": "Rameterspitz oder Romatenspitz 2696mTrack Description",
        "name": "Rameterspitz oder Romatenspitz 2696m",
        "zoomCenterPoint": "lat:47.00098400000015,lng:13.132766999999552",
        "trackDistance": 18.485583513710154,
        "google_encoded_polyline": "c{z}Gw~coAs@DgAtSEjP^vCdBnC?l@m@?eBiAeA}AOcEw@}Ck@oIYG@~Jj@rEe@vEZbCGfCvF`IX~A{@|EPxAOzA^xCEzCnArDb@dDp@fBMd@_@EcBqFsAuBaBwAk@iCSKM^Z|BFfCnArCF`A[`DLlAhD~DnA`ANf@_@LaAaAc@APn@nCdCHb@UF_BmAiB}@gAyA[FLhAjBvD{AdMOHAq@a@[a@fAy@Gm@[]l@k@o@k@A{BbAuA`CiAJuBjB_BnDoAhA]z@IlAXlCOx@a@Rc@QWqBUq@YVIo@gA\\g@_@}ANMk@o@g@cBUkAmCV}CKo@}@M_BZ_Ab@w@D_Bp@gAfAw@nAg@HS\\]LoA}AoAe@qA}AoBcJ_AmCs@[Yc@c@v@c@NuCmDs@CWwAqAwD{AgB{@g@{BkC}BUgAyAmAOcFJ}AgA_BqC]wAFkBkBQCm@MOLs@s@uBTgC{@sBJ_BCkA_Ay@MkA}@oAUcBk@kA_@yAI_BTsBXcANkB?o@]o@}AKK_@mCbAC_AaCbAY`@]?Wq@?_DRaCcA{@cAVm@hAGhCX_AC{@TGJq@PTPWLb@Xa@Vb@CfAl@lACpAZCJT`@U^z@b@]`@^z@K\\^^[`@NXUPP`@ELZ|@?Nf@ZFJb@h@MBl@d@ALTZWA`@RAUfA`@ROj@Nv@Gh@W@Id@e@BYj@UDAd@X^ULDn@Mb@d@RvB[L\\[dARZcAl@Ep@XN@|@TJa@|ATh@h@FFh@l@DHt@\\t@O\\Df@]F?l@Yl@Jr@Of@PR^AKzAh@nH`@e@H|B`@s@LnAX}@JjA`@k@^jCfBdFZm@?kCXmCm@eD\\RTSV`@\\WR^f@QP`@ZUXd@\\INlAZRD|@ZJU~@RVH`BMbBBjAd@QCbAd@MWlBCdCp@c@f@@z@pF^nEzApHEvAZ@B`@\\B@f@LFF^VEJd@n@{@j@HC`@ZNg@~@`AZg@n@pA\\W^LZOx@`@GHx@`@LGbAt@vAb@pAFtB^~@No@^TDg@l@`AV~BLg@d@BAs@^C@g@ZBRe@XLf@a@Xd@^]VTTk@^R^Ip@`@f@U`@LZQd@ZPc@O{@ZYBgAf@z@Tk@^FBg@d@FTU?g@rBeAvC_@lAm@An@x@A?jAnDfClAX`D?a@]rAi@Re@|@ClD{CzDuB^z@j@Mn@Rj@{@tBqE~ByAVkAlAZk@cAPGaBeB{@e@AYl@R~BDzAd@sAyBmC}BJkBe@sAzAgMmB{DOcA^KbA|AlBz@xAlAVGGa@qCgCOo@b@?`A`A\\MKe@qAaAkD_EMoAZ}CEeAqAqCGeC[_CL_@TLj@jC|AtArApBfBtF^@Ja@q@gBc@gDmAsD@yC[{CN}AQsAz@_FYcByF_IDeCYcCd@{Ek@mE?eKTDn@pIr@|CNdEfA|AdBhAj@@?o@cBqC_@sCDoPfAoSp@I",
        "tblgps": [
            {
                "@id": 1481,
                "id": 2186,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1328,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1288.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1482,
                "id": 2187,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1327,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1289.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1483,
                "id": 2188,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1294,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1312.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1484,
                "id": 2189,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1266,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1336.32,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1485,
                "id": 2190,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1259,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1342.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1486,
                "id": 2191,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1352.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1487,
                "id": 2192,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1354.12,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1488,
                "id": 2193,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1358.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1489,
                "id": 2194,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1363.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1490,
                "id": 2195,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1258,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1368.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1491,
                "id": 2196,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1267,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1375.52,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1492,
                "id": 2197,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1275,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1381.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1493,
                "id": 2198,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1292,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1392.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1494,
                "id": 2199,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0028,
                "lng": 13.1293,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1393.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1495,
                "id": 2200,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0028,
                "lng": 13.1273,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1408.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1496,
                "id": 2201,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.1263,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1414.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1497,
                "id": 2202,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0027,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1422.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1498,
                "id": 2203,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1427.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1499,
                "id": 2204,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1239,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1427.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1500,
                "id": 2205,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1223,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1448.03,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1501,
                "id": 2206,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1218,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1452.48,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1502,
                "id": 2207,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1207,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1463.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1503,
                "id": 2208,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1202,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1467.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1504,
                "id": 2209,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1197,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1470.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1505,
                "id": 2210,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1477.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1506,
                "id": 2211,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1182,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1484.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1507,
                "id": 2212,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1173,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1489.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1508,
                "id": 2213,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1165,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1497.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1509,
                "id": 2214,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1159,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1500.87,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1510,
                "id": 2215,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1502.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1511,
                "id": 2216,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1505.6,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1512,
                "id": 2217,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.117,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1513.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1513,
                "id": 2218,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1176,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1521.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1514,
                "id": 2219,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0022,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1526.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1515,
                "id": 2220,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1187,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1531.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1516,
                "id": 2221,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.1188,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1533.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1517,
                "id": 2222,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1536.75,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1518,
                "id": 2223,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1539.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1519,
                "id": 2224,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1173,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1547.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1520,
                "id": 2225,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1166,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1555.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1521,
                "id": 2226,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1162,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1559.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1522,
                "id": 2227,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1154,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1565.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1523,
                "id": 2228,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1569.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1524,
                "id": 2229,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1141,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1525,
                "id": 2230,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1137,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1587.32,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1526,
                "id": 2231,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1136,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1589.32,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1527,
                "id": 2232,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0009,
                "lng": 13.1135,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1594.6,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1528,
                "id": 2233,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1596.09,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1529,
                "id": 2234,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1599.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1530,
                "id": 2235,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.1136,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1602.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1531,
                "id": 2236,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1129,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1610.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1532,
                "id": 2237,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0005,
                "lng": 13.1127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1533,
                "id": 2238,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1614.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1534,
                "id": 2239,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0011,
                "lng": 13.1131,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1620.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1535,
                "id": 2240,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1134,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1626.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1536,
                "id": 2241,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1630.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1537,
                "id": 2242,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0022,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1538,
                "id": 2243,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1134,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1640.21,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1539,
                "id": 2244,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1648.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1540,
                "id": 2245,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1669.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1541,
                "id": 2246,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1671.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1542,
                "id": 2247,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1672.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1543,
                "id": 2248,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0023,
                "lng": 13.1106,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1675.37,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1544,
                "id": 2249,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1685.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1545,
                "id": 2250,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0027,
                "lng": 13.1103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1684.12,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1546,
                "id": 2251,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.003,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1693.45,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1547,
                "id": 2252,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0031,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1702.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1548,
                "id": 2253,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0033,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1709.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1549,
                "id": 2254,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0035,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1718.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1550,
                "id": 2255,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0042,
                "lng": 13.1101,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1740.9,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1551,
                "id": 2256,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0046,
                "lng": 13.1094,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1765.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1552,
                "id": 2257,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.005,
                "lng": 13.1094,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1772.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1553,
                "id": 2258,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0056,
                "lng": 13.1088,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1788.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1554,
                "id": 2259,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.006,
                "lng": 13.108,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1808.95,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1555,
                "id": 2260,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0064,
                "lng": 13.1076,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1818.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1556,
                "id": 2261,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0066,
                "lng": 13.1073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1819.07,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1557,
                "id": 2262,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0066,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1821.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1558,
                "id": 2263,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0065,
                "lng": 13.1062,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1832.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1559,
                "id": 2264,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0066,
                "lng": 13.1059,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1839.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1560,
                "id": 2265,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0068,
                "lng": 13.1058,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1839.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1561,
                "id": 2266,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0069,
                "lng": 13.1059,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1844.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1562,
                "id": 2267,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0071,
                "lng": 13.1065,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1855.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1563,
                "id": 2268,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0072,
                "lng": 13.1067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1863.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1564,
                "id": 2269,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0073,
                "lng": 13.1066,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1868.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1565,
                "id": 2270,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0073,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1873.07,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1566,
                "id": 2271,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0077,
                "lng": 13.1067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1887.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1567,
                "id": 2272,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0079,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1894.75,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1568,
                "id": 2273,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0084,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1907.6,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1569,
                "id": 2274,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0084,
                "lng": 13.107,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1911.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1570,
                "id": 2275,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0087,
                "lng": 13.1072,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1917.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1571,
                "id": 2276,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0092,
                "lng": 13.1073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1933.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1572,
                "id": 2277,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0096,
                "lng": 13.108,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1948.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1573,
                "id": 2278,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0094,
                "lng": 13.1088,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1966.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1574,
                "id": 2279,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0095,
                "lng": 13.109,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1968.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1575,
                "id": 2280,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0098,
                "lng": 13.1091,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1974.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1576,
                "id": 2281,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0103,
                "lng": 13.109,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1988.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1577,
                "id": 2282,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0106,
                "lng": 13.1088,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1994.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1578,
                "id": 2283,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0109,
                "lng": 13.1087,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1995.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1579,
                "id": 2284,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0114,
                "lng": 13.1085,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2002.01,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1580,
                "id": 2285,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0117,
                "lng": 13.1081,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2008.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1581,
                "id": 2286,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.012,
                "lng": 13.1077,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2019.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1582,
                "id": 2287,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0122,
                "lng": 13.1077,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2022.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1583,
                "id": 2288,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0123,
                "lng": 13.1075,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2025.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1584,
                "id": 2289,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0125,
                "lng": 13.1075,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2033.94,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1585,
                "id": 2290,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0129,
                "lng": 13.1079,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2044.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1586,
                "id": 2291,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0133,
                "lng": 13.1081,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2048.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1587,
                "id": 2292,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.1086,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2052.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1588,
                "id": 2293,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0142,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2070.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1589,
                "id": 2294,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.1111,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2090.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1590,
                "id": 2295,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0148,
                "lng": 13.1112,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2099.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1591,
                "id": 2296,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0149,
                "lng": 13.1114,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2105.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1592,
                "id": 2297,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0151,
                "lng": 13.1111,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2111.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1593,
                "id": 2298,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0153,
                "lng": 13.111,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2115.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1594,
                "id": 2299,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0161,
                "lng": 13.1119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2127.21,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1595,
                "id": 2300,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0163,
                "lng": 13.1119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2130.6,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1596,
                "id": 2301,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0164,
                "lng": 13.1124,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2134.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1597,
                "id": 2302,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0168,
                "lng": 13.1133,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2144.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1598,
                "id": 2303,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0173,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2147.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1599,
                "id": 2304,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0176,
                "lng": 13.114,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2148.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1600,
                "id": 2305,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0182,
                "lng": 13.1147,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2158.54,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1601,
                "id": 2306,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0189,
                "lng": 13.1148,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2173.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1602,
                "id": 2307,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0192,
                "lng": 13.1153,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2190.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1603,
                "id": 2308,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0196,
                "lng": 13.1153,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2202.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1604,
                "id": 2309,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0207,
                "lng": 13.1153,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2232.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1605,
                "id": 2310,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.1157,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2248.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1606,
                "id": 2311,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0217,
                "lng": 13.1164,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2272.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1607,
                "id": 2312,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0219,
                "lng": 13.1168,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2283.03,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1608,
                "id": 2313,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0218,
                "lng": 13.1174,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2293.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1609,
                "id": 2314,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0223,
                "lng": 13.1175,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2316.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1610,
                "id": 2315,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1177,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2322.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1611,
                "id": 2316,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1178,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2327.87,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1612,
                "id": 2317,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2331.03,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1613,
                "id": 2318,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2346.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1614,
                "id": 2319,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0225,
                "lng": 13.1193,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2359.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1615,
                "id": 2320,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1199,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2374.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1616,
                "id": 2321,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1204,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2387.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1617,
                "id": 2322,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1207,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2393.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1618,
                "id": 2323,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.121,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2406.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1619,
                "id": 2324,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.1214,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2413.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1620,
                "id": 2325,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0235,
                "lng": 13.1218,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2426.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1621,
                "id": 2326,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0236,
                "lng": 13.1223,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2441.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1622,
                "id": 2327,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0238,
                "lng": 13.1227,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2452.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1623,
                "id": 2328,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.024,
                "lng": 13.1231,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2468.21,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1624,
                "id": 2329,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.024,
                "lng": 13.1236,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2476.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1625,
                "id": 2330,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0239,
                "lng": 13.1242,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2493.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1626,
                "id": 2331,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0238,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2502.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1627,
                "id": 2332,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.1251,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2516.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1628,
                "id": 2333,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2520.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1629,
                "id": 2334,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0238,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2530.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1630,
                "id": 2335,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0243,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2542.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1631,
                "id": 2336,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0244,
                "lng": 13.1258,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2547.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1632,
                "id": 2337,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0251,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2573.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1633,
                "id": 2338,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0251,
                "lng": 13.1257,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2578.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1634,
                "id": 2339,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2604.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1635,
                "id": 2340,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0259,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2609.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1636,
                "id": 2341,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.026,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2616.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1637,
                "id": 2342,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0262,
                "lng": 13.1255,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2625.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1638,
                "id": 2343,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0262,
                "lng": 13.1263,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2643.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1639,
                "id": 2344,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0261,
                "lng": 13.1269,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2658.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1640,
                "id": 2345,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.1272,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2673.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1641,
                "id": 2346,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0267,
                "lng": 13.1271,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2680.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1642,
                "id": 2347,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.027,
                "lng": 13.1268,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2691.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1643,
                "id": 2348,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.027,
                "lng": 13.1261,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2693.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1644,
                "id": 2349,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0269,
                "lng": 13.1264,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2689.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1645,
                "id": 2350,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0269,
                "lng": 13.1267,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2688.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1646,
                "id": 2351,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0268,
                "lng": 13.1267,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2683.77,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1647,
                "id": 2352,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0267,
                "lng": 13.127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2680.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1648,
                "id": 2353,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0266,
                "lng": 13.1269,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2675.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1649,
                "id": 2354,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0265,
                "lng": 13.127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2672.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1650,
                "id": 2355,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0265,
                "lng": 13.1268,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2669.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1651,
                "id": 2356,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0263,
                "lng": 13.127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2666.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1652,
                "id": 2357,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0262,
                "lng": 13.1268,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2660.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1653,
                "id": 2358,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0263,
                "lng": 13.1264,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2653.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1654,
                "id": 2359,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.026,
                "lng": 13.126,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2628.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1655,
                "id": 2360,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.026,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2619.98,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1656,
                "id": 2361,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0259,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2613.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1657,
                "id": 2362,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.1255,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2609.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1658,
                "id": 2363,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0257,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2603.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1659,
                "id": 2364,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0255,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2591.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1660,
                "id": 2365,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0253,
                "lng": 13.1255,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2585.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1661,
                "id": 2366,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0252,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2575.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1662,
                "id": 2367,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0249,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2561.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1663,
                "id": 2368,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0247,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2552.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1664,
                "id": 2369,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0245,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2547.94,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1665,
                "id": 2370,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0244,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2540.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1666,
                "id": 2371,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0243,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2536.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1667,
                "id": 2372,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0242,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2533.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1668,
                "id": 2373,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.024,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2529.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1669,
                "id": 2374,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0239,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2524.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1670,
                "id": 2375,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0236,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2516.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1671,
                "id": 2376,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0235,
                "lng": 13.125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2510.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1672,
                "id": 2377,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0234,
                "lng": 13.125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2504.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1673,
                "id": 2378,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0233,
                "lng": 13.1248,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2498.77,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1674,
                "id": 2379,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2495.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1675,
                "id": 2380,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1246,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2490.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1676,
                "id": 2381,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0229,
                "lng": 13.1246,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2481.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1677,
                "id": 2382,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2474.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1678,
                "id": 2383,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1246,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2473.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1679,
                "id": 2384,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2472.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1680,
                "id": 2385,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2472.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1681,
                "id": 2386,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1241,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2469.15,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1682,
                "id": 2387,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0225,
                "lng": 13.124,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2462.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1683,
                "id": 2388,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1238,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2460.77,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1684,
                "id": 2389,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1235,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2457.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1685,
                "id": 2390,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1233,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2457.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1686,
                "id": 2391,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1233,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2454.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1687,
                "id": 2392,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1231,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2451.54,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1688,
                "id": 2393,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0229,
                "lng": 13.1231,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2448.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1689,
                "id": 2394,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1229,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2444.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1690,
                "id": 2395,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.1228,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2444.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1691,
                "id": 2396,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.1227,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2442.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1692,
                "id": 2397,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1225,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2439.37,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1693,
                "id": 2398,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.1224,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2437.54,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1694,
                "id": 2399,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1222,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2432.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1695,
                "id": 2400,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.122,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2428.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1696,
                "id": 2401,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.023,
                "lng": 13.1219,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2424.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1697,
                "id": 2402,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1221,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2416.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1698,
                "id": 2403,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1219,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2412.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1699,
                "id": 2404,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0225,
                "lng": 13.1215,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2413.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1700,
                "id": 2405,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1214,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2410.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1701,
                "id": 2406,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1212,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2407.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1702,
                "id": 2407,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1209,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2397.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1703,
                "id": 2408,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1209,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2393.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1704,
                "id": 2409,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1205,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2392.95,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1705,
                "id": 2410,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0225,
                "lng": 13.1205,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2391.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1706,
                "id": 2411,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.12,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2378.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1707,
                "id": 2412,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1198,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2368.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1708,
                "id": 2413,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1198,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2363.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1709,
                "id": 2414,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0223,
                "lng": 13.1196,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2357.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1710,
                "id": 2415,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0221,
                "lng": 13.1195,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2352.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1711,
                "id": 2416,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.022,
                "lng": 13.1193,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2351.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1712,
                "id": 2417,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0219,
                "lng": 13.119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2350.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1713,
                "id": 2418,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.022,
                "lng": 13.1188,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2347.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1714,
                "id": 2419,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0219,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2342.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1715,
                "id": 2420,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0221,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2342.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1716,
                "id": 2421,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0221,
                "lng": 13.1184,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2336.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1717,
                "id": 2422,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0222,
                "lng": 13.1181,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2331.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1718,
                "id": 2423,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0222,
                "lng": 13.1179,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2325.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1719,
                "id": 2424,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0222,
                "lng": 13.1177,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2318.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1720,
                "id": 2425,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0222,
                "lng": 13.1176,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2312.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1721,
                "id": 2426,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.022,
                "lng": 13.1176,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2307.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1722,
                "id": 2427,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0221,
                "lng": 13.1171,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2298.45,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1723,
                "id": 2428,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0218,
                "lng": 13.1156,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2281.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1724,
                "id": 2429,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0217,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2272.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1725,
                "id": 2430,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0216,
                "lng": 13.1152,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2269.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1726,
                "id": 2431,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0215,
                "lng": 13.1154,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2258.98,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1727,
                "id": 2432,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0214,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2256.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1728,
                "id": 2433,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0213,
                "lng": 13.1153,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2249.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1729,
                "id": 2434,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2246.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1730,
                "id": 2435,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.021,
                "lng": 13.1152,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2241.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1731,
                "id": 2436,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0209,
                "lng": 13.1145,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2236.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1732,
                "id": 2437,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0204,
                "lng": 13.1133,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2227.91,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1733,
                "id": 2438,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0202,
                "lng": 13.1135,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2225.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1734,
                "id": 2439,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0202,
                "lng": 13.1142,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2222.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1735,
                "id": 2440,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0201,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2216.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1736,
                "id": 2441,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0203,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2213.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1737,
                "id": 2442,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0202,
                "lng": 13.1157,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2211.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1738,
                "id": 2443,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.02,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2210.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1739,
                "id": 2444,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0199,
                "lng": 13.1156,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2206.94,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1740,
                "id": 2445,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0198,
                "lng": 13.1157,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2206.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1741,
                "id": 2446,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0197,
                "lng": 13.1156,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2201.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1742,
                "id": 2447,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0195,
                "lng": 13.1157,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2199.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1743,
                "id": 2448,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0194,
                "lng": 13.1155,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2194.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1744,
                "id": 2449,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0192,
                "lng": 13.1156,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2195.37,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1745,
                "id": 2450,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0191,
                "lng": 13.1154,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2187.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1746,
                "id": 2451,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.019,
                "lng": 13.1155,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2185.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1747,
                "id": 2452,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0189,
                "lng": 13.1151,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2176.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1748,
                "id": 2453,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2170.03,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1749,
                "id": 2454,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.1147,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2166.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1750,
                "id": 2455,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0186,
                "lng": 13.1146,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2162.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1751,
                "id": 2456,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.1143,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2161.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1752,
                "id": 2457,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0186,
                "lng": 13.1142,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2158.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1753,
                "id": 2458,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0185,
                "lng": 13.1137,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2156.75,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1754,
                "id": 2459,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0186,
                "lng": 13.1132,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2155.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1755,
                "id": 2460,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0186,
                "lng": 13.1128,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2153.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1756,
                "id": 2461,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0184,
                "lng": 13.1129,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2146.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1757,
                "id": 2462,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0184,
                "lng": 13.1125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2144.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1758,
                "id": 2463,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0182,
                "lng": 13.1126,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2140.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1759,
                "id": 2464,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0184,
                "lng": 13.1121,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2136.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1760,
                "id": 2465,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0184,
                "lng": 13.1114,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2130.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1761,
                "id": 2466,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0181,
                "lng": 13.1116,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2128.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1762,
                "id": 2467,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0179,
                "lng": 13.1116,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2125.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1763,
                "id": 2468,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0176,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2123.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1764,
                "id": 2469,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0175,
                "lng": 13.1093,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2118.41,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1765,
                "id": 2470,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.017,
                "lng": 13.1078,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2111.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1766,
                "id": 2471,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.017,
                "lng": 13.1074,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2107.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1767,
                "id": 2472,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0169,
                "lng": 13.1073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2104.64,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1768,
                "id": 2473,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0169,
                "lng": 13.1072,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2103.95,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1769,
                "id": 2474,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0167,
                "lng": 13.1071,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2099.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1770,
                "id": 2475,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0167,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2096.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1771,
                "id": 2476,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0166,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2093.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1772,
                "id": 2477,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0166,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2089.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1773,
                "id": 2478,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0165,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2086.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1774,
                "id": 2479,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0164,
                "lng": 13.1066,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2083.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1775,
                "id": 2480,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0162,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2082.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1776,
                "id": 2481,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.016,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2081.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1777,
                "id": 2482,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.016,
                "lng": 13.1067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2077.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1778,
                "id": 2483,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0158,
                "lng": 13.1066,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2070.95,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1779,
                "id": 2484,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.016,
                "lng": 13.1063,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2066.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1780,
                "id": 2485,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0157,
                "lng": 13.1061,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2063.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1781,
                "id": 2486,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0159,
                "lng": 13.1059,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2057.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1782,
                "id": 2487,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0155,
                "lng": 13.1057,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2056.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1783,
                "id": 2488,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0156,
                "lng": 13.1056,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2050.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1784,
                "id": 2489,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0155,
                "lng": 13.1054,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2047.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1785,
                "id": 2490,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0156,
                "lng": 13.1052,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2046.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1786,
                "id": 2491,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0155,
                "lng": 13.1052,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2041.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1787,
                "id": 2492,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0154,
                "lng": 13.1049,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2039.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1788,
                "id": 2493,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0152,
                "lng": 13.1048,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2034.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1789,
                "id": 2494,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0153,
                "lng": 13.1045,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2034.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1790,
                "id": 2495,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.015,
                "lng": 13.104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2029.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1791,
                "id": 2496,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0148,
                "lng": 13.1036,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2023.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1792,
                "id": 2497,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0148,
                "lng": 13.1031,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2022.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1793,
                "id": 2498,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.1027,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2020.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1794,
                "id": 2499,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2012.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1795,
                "id": 2500,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0144,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2005.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1796,
                "id": 2501,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0144,
                "lng": 13.1031,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1999.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1797,
                "id": 2502,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0141,
                "lng": 13.1027,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1993.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1798,
                "id": 2503,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.014,
                "lng": 13.1021,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1986.01,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1799,
                "id": 2504,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0139,
                "lng": 13.1023,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1980.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1800,
                "id": 2505,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.1023,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1975.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1801,
                "id": 2506,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0138,
                "lng": 13.1025,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1972.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1802,
                "id": 2507,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0136,
                "lng": 13.1025,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1966.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1803,
                "id": 2508,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0136,
                "lng": 13.1028,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1964.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1804,
                "id": 2509,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0135,
                "lng": 13.1027,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1960.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1805,
                "id": 2510,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0134,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1956.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1806,
                "id": 2511,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0132,
                "lng": 13.1028,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1951.52,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1807,
                "id": 2512,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.013,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1947.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1808,
                "id": 2513,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0129,
                "lng": 13.1028,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1941.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1809,
                "id": 2514,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0127,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1934.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1810,
                "id": 2515,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0126,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1932.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1811,
                "id": 2516,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0125,
                "lng": 13.1031,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1924.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1812,
                "id": 2517,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0123,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1919.98,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1813,
                "id": 2518,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0122,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1912.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1814,
                "id": 2519,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0119,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1910.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1815,
                "id": 2520,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0117,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1898.09,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1816,
                "id": 2521,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0116,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1892.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1817,
                "id": 2522,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0114,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1885.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1818,
                "id": 2523,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0112,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1879.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1819,
                "id": 2524,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0111,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1871.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1820,
                "id": 2525,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0112,
                "lng": 13.1033,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1869.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1821,
                "id": 2526,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0111,
                "lng": 13.1035,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1865.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1822,
                "id": 2527,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0111,
                "lng": 13.1038,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1862.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1823,
                "id": 2528,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0109,
                "lng": 13.1035,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1860.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1824,
                "id": 2529,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0108,
                "lng": 13.1037,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1857.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1825,
                "id": 2530,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0106,
                "lng": 13.1037,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.64,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1826,
                "id": 2531,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0106,
                "lng": 13.1039,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1827,
                "id": 2532,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0104,
                "lng": 13.1039,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1849.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1828,
                "id": 2533,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0103,
                "lng": 13.104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1848.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1829,
                "id": 2534,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0103,
                "lng": 13.1042,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1848.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1830,
                "id": 2535,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0097,
                "lng": 13.1045,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1844.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1831,
                "id": 2536,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0089,
                "lng": 13.1047,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1838.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1832,
                "id": 2537,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0085,
                "lng": 13.1049,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1835.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1833,
                "id": 2538,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0085,
                "lng": 13.1047,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1830.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1834,
                "id": 2539,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0083,
                "lng": 13.1047,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1825.98,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1835,
                "id": 2540,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0083,
                "lng": 13.1043,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1823.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1836,
                "id": 2541,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0074,
                "lng": 13.1036,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1816.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1837,
                "id": 2542,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.007,
                "lng": 13.1035,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1815.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1838,
                "id": 2543,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0062,
                "lng": 13.1035,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1810.32,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1839,
                "id": 2544,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0064,
                "lng": 13.1036,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1808.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1840,
                "id": 2545,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0059,
                "lng": 13.1039,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1802.94,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1841,
                "id": 2546,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0058,
                "lng": 13.1041,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1803.73,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1842,
                "id": 2547,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0055,
                "lng": 13.1041,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1797.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1843,
                "id": 2548,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0046,
                "lng": 13.1048,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1779.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1844,
                "id": 2549,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0037,
                "lng": 13.1054,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1763.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1845,
                "id": 2550,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0036,
                "lng": 13.1051,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1759.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1846,
                "id": 2551,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0033,
                "lng": 13.1052,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1759.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1847,
                "id": 2552,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0031,
                "lng": 13.1051,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1758.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1848,
                "id": 2553,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0029,
                "lng": 13.1054,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1760.21,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1849,
                "id": 2554,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0023,
                "lng": 13.1065,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1757.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1850,
                "id": 2555,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1748.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1851,
                "id": 2556,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1747.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1852,
                "id": 2557,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0011,
                "lng": 13.1072,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1742.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1853,
                "id": 2558,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1075,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1735.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1854,
                "id": 2559,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.1075,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1730.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1855,
                "id": 2560,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.108,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1723.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1856,
                "id": 2561,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1082,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1721.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1857,
                "id": 2562,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1084,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1718.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1858,
                "id": 2563,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0018,
                "lng": 13.1083,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1717.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1859,
                "id": 2564,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1082,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1705.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1860,
                "id": 2565,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.108,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1698.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1861,
                "id": 2566,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0011,
                "lng": 13.1087,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1690.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1862,
                "id": 2567,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0019,
                "lng": 13.1093,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1677.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1863,
                "id": 2568,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0018,
                "lng": 13.1098,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1671.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1864,
                "id": 2569,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1669.64,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1865,
                "id": 2570,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1648.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1866,
                "id": 2571,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1135,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1638.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1867,
                "id": 2572,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0022,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1868,
                "id": 2573,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1139,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1629.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1869,
                "id": 2574,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1134,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1626.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1870,
                "id": 2575,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0011,
                "lng": 13.1131,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1619.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1871,
                "id": 2576,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1614.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1872,
                "id": 2577,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0005,
                "lng": 13.1127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.73,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1873,
                "id": 2578,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1129,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1609.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1874,
                "id": 2579,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.1136,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1602.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1875,
                "id": 2580,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1598.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1876,
                "id": 2581,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1595.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1877,
                "id": 2582,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0009,
                "lng": 13.1135,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1593.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1878,
                "id": 2583,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1136,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1589.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1879,
                "id": 2584,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1587.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1880,
                "id": 2585,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1141,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1881,
                "id": 2586,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1151,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1569.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1882,
                "id": 2587,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1154,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1565.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1883,
                "id": 2588,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1162,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1559.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1884,
                "id": 2589,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1166,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1555.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1885,
                "id": 2590,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1173,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1547.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1886,
                "id": 2591,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1540.01,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1887,
                "id": 2592,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1536.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1888,
                "id": 2593,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.1188,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1533.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1889,
                "id": 2594,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1187,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1531.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1890,
                "id": 2595,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0022,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1526.64,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1891,
                "id": 2596,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1176,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1521.45,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1892,
                "id": 2597,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.117,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1513.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1893,
                "id": 2598,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1505.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1894,
                "id": 2599,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1502.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1895,
                "id": 2600,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1159,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1500.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1896,
                "id": 2601,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1165,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1497.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1897,
                "id": 2602,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1173,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1489.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1898,
                "id": 2603,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1182,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1483.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1899,
                "id": 2604,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1477.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1900,
                "id": 2605,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1198,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1470.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1901,
                "id": 2606,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1202,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1466.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1902,
                "id": 2607,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1206,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1463.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1903,
                "id": 2608,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1218,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1452.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1904,
                "id": 2609,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1223,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1447.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1905,
                "id": 2610,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1239,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1427.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1906,
                "id": 2611,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1427.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1907,
                "id": 2612,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0027,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1422.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1908,
                "id": 2613,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.1263,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1414.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1909,
                "id": 2614,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0028,
                "lng": 13.1273,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1408.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1910,
                "id": 2615,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0028,
                "lng": 13.1293,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1393.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1911,
                "id": 2616,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1293,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1392.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1912,
                "id": 2617,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1276,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1381.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1913,
                "id": 2618,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1268,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1376.07,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1914,
                "id": 2619,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1258,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1369.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1915,
                "id": 2620,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1363.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1916,
                "id": 2621,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1358.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1917,
                "id": 2622,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1354.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1918,
                "id": 2623,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1352.48,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1919,
                "id": 2624,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1259,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1342.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1920,
                "id": 2625,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1266,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1336.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1921,
                "id": 2626,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1294,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1312.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1922,
                "id": 2627,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1327,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1290.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            },
            {
                "@id": 1923,
                "id": 2628,
                "date": "2024-07-10T14:24:30.539861Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1328,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1288.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1480
            }
        ],
        "tblUser": 1
    },
    {
        "@id": 1924,
        "id": 152,
        "date": "2024-07-10T16:20:29.692775Z",
        "descr": "Vorderer Geißlkopf oder Hohe Geißel 2974mTrack Description",
        "name": "Vorderer Geißlkopf oder Hohe Geißel 2974m",
        "zoomCenterPoint": "lat:47.06115650001463,lng:13.057751000003808",
        "trackDistance": 14.871965284409585,
        "google_encoded_polyline": "esf~GijunAdIbAtDpFzFVrAv@jALhCvDjBCdBh@`N}L`DKdBmA|CLrAkBfDe@dJsI~M_S`KqCbIgHtFoJdBkOnD{I~GiBxEeItAc@IcC|AwBV}FdB?W}HbBqGhHkFlFuPxB{Ms@kFgH}HvG}DdJrBhKcAtCbC}@~G^hIvB|BGzD|BtCbL`CpAvFrKxFfF|NbDcBrBbDpJlGlEhGnFlCTjD`A_AH~BpA`@d@wDe@vDqAa@I_CaA~@UkDoFmCmEiGqJmGsBcDcDbBgF}NsKyFqAwFcLaC}BuCkCOqArA{AW{Al@mAlA[`@qAPFpEsCR}DsAgEhBwChEiHjFcBpGV|HeB?W|F}AvBHbCuAb@yEdI_HhBoDzIeBjOuFnJcIfHaKpC_N~ReJrIgDd@sAjB}CMeBlAaDJaN|LeBi@kBBiCwDkAMsAw@{FWuDqFgIk@",
        "tblgps": [
            {
                "@id": 1925,
                "id": 2652,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0612,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1581.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1926,
                "id": 2653,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0595,
                "lng": 13.0575,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1927,
                "id": 2654,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0586,
                "lng": 13.0563,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1582.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1928,
                "id": 2655,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0574,
                "lng": 13.0561,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1929,
                "id": 2656,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0569,
                "lng": 13.0559,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1930,
                "id": 2657,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0565,
                "lng": 13.0558,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1931,
                "id": 2658,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0559,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1588.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1932,
                "id": 2659,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0553,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1590.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1933,
                "id": 2660,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0548,
                "lng": 13.0547,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1592.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1934,
                "id": 2661,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0524,
                "lng": 13.0569,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1604.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1935,
                "id": 2662,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0516,
                "lng": 13.057,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1606.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1936,
                "id": 2663,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0511,
                "lng": 13.0574,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1608.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1937,
                "id": 2664,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0503,
                "lng": 13.0573,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1938,
                "id": 2665,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0499,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.41,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1939,
                "id": 2666,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.049,
                "lng": 13.058,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1940,
                "id": 2667,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0472,
                "lng": 13.0597,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1941,
                "id": 2668,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0448,
                "lng": 13.0629,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1621.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1942,
                "id": 2669,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0429,
                "lng": 13.0636,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1630.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1943,
                "id": 2670,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0413,
                "lng": 13.0651,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1944,
                "id": 2671,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0401,
                "lng": 13.067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1638.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1945,
                "id": 2672,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0395,
                "lng": 13.0696,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1662.82,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1946,
                "id": 2673,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0387,
                "lng": 13.0713,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1709.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1947,
                "id": 2674,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0372,
                "lng": 13.0719,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1772.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1948,
                "id": 2675,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0361,
                "lng": 13.0735,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1949,
                "id": 2676,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0357,
                "lng": 13.0737,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1880.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1950,
                "id": 2677,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0358,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1902.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1951,
                "id": 2678,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0353,
                "lng": 13.0749,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1937.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1952,
                "id": 2679,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0352,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1991.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1953,
                "id": 2680,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0347,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1999.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1954,
                "id": 2681,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0348,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2047.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1955,
                "id": 2682,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0343,
                "lng": 13.0792,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2112.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1956,
                "id": 2683,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0328,
                "lng": 13.0803,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2126.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1957,
                "id": 2684,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0316,
                "lng": 13.0832,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2165.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1958,
                "id": 2685,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.031,
                "lng": 13.0856,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2209.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1959,
                "id": 2686,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0313,
                "lng": 13.0867,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2239.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1960,
                "id": 2687,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0327,
                "lng": 13.0883,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2316.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1961,
                "id": 2688,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0313,
                "lng": 13.0893,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2380.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1962,
                "id": 2689,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0295,
                "lng": 13.0887,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2371.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1963,
                "id": 2690,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0276,
                "lng": 13.089,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2443.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1964,
                "id": 2691,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0268,
                "lng": 13.0884,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2425.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1965,
                "id": 2692,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0271,
                "lng": 13.0869,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2445.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1966,
                "id": 2693,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.027,
                "lng": 13.0853,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2432.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1967,
                "id": 2694,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0847,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2437.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1968,
                "id": 2695,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0837,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2442.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1969,
                "id": 2696,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.083,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2459.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1970,
                "id": 2697,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.0823,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2534.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1971,
                "id": 2698,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0233,
                "lng": 13.0811,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2601.39,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1972,
                "id": 2699,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.0798,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2657.15,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1973,
                "id": 2700,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0201,
                "lng": 13.0773,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2711.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1974,
                "id": 2701,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0193,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2731.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1975,
                "id": 2702,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.0769,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2752.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1976,
                "id": 2703,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0168,
                "lng": 13.0756,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2796.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1977,
                "id": 2704,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0158,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2831.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1978,
                "id": 2705,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.0736,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2879.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1979,
                "id": 2706,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0145,
                "lng": 13.0727,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2877.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1980,
                "id": 2707,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0142,
                "lng": 13.073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2905.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1981,
                "id": 2708,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0141,
                "lng": 13.0724,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2908.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1982,
                "id": 2709,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.0722,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2935.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1983,
                "id": 2710,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0135,
                "lng": 13.0731,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2972.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1984,
                "id": 2711,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.0722,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2935.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1985,
                "id": 2712,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0141,
                "lng": 13.0724,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2908.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1986,
                "id": 2713,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0142,
                "lng": 13.073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2905.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1987,
                "id": 2714,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0145,
                "lng": 13.0727,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2877.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1988,
                "id": 2715,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.0736,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2879.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1989,
                "id": 2716,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0158,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2831.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1990,
                "id": 2717,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0168,
                "lng": 13.0756,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2796.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1991,
                "id": 2718,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.0769,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2752.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1992,
                "id": 2719,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0193,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2731.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1993,
                "id": 2720,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0201,
                "lng": 13.0773,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2711.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1994,
                "id": 2721,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.0798,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2657.15,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1995,
                "id": 2722,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0233,
                "lng": 13.0811,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2601.39,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1996,
                "id": 2723,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.0823,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2534.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1997,
                "id": 2724,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.083,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2459.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1998,
                "id": 2725,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0837,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2442.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 1999,
                "id": 2726,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0271,
                "lng": 13.0838,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2433.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2000,
                "id": 2727,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0275,
                "lng": 13.0834,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2419.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2001,
                "id": 2728,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.028,
                "lng": 13.0835,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2390.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2002,
                "id": 2729,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0284,
                "lng": 13.0833,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2361.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2003,
                "id": 2730,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0288,
                "lng": 13.0829,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2334.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2004,
                "id": 2731,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.029,
                "lng": 13.0827,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2321.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2005,
                "id": 2732,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0294,
                "lng": 13.0826,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2297.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2006,
                "id": 2733,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0293,
                "lng": 13.0816,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2271.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2007,
                "id": 2734,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0301,
                "lng": 13.0815,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2216.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2008,
                "id": 2735,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.031,
                "lng": 13.0819,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2165.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2009,
                "id": 2736,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.032,
                "lng": 13.0813,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2135.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2010,
                "id": 2737,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0328,
                "lng": 13.0803,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2126.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2011,
                "id": 2738,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0343,
                "lng": 13.0792,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2112.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2012,
                "id": 2739,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0348,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2047.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2013,
                "id": 2740,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0347,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1999.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2014,
                "id": 2741,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0352,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1991.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2015,
                "id": 2742,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0353,
                "lng": 13.0749,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1937.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2016,
                "id": 2743,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0358,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1902.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2017,
                "id": 2744,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0357,
                "lng": 13.0737,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1880.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2018,
                "id": 2745,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0361,
                "lng": 13.0735,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2019,
                "id": 2746,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0372,
                "lng": 13.0719,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1772.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2020,
                "id": 2747,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0387,
                "lng": 13.0713,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1709.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2021,
                "id": 2748,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0395,
                "lng": 13.0696,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1662.82,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2022,
                "id": 2749,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0401,
                "lng": 13.067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1638.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2023,
                "id": 2750,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0413,
                "lng": 13.0651,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2024,
                "id": 2751,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0429,
                "lng": 13.0636,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1630.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2025,
                "id": 2752,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0448,
                "lng": 13.0629,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1621.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2026,
                "id": 2753,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0472,
                "lng": 13.0597,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2027,
                "id": 2754,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.049,
                "lng": 13.058,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2028,
                "id": 2755,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0499,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.41,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2029,
                "id": 2756,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0503,
                "lng": 13.0573,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2030,
                "id": 2757,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0511,
                "lng": 13.0574,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1608.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2031,
                "id": 2758,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0516,
                "lng": 13.057,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1606.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2032,
                "id": 2759,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0524,
                "lng": 13.0569,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1604.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2033,
                "id": 2760,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0548,
                "lng": 13.0547,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1592.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2034,
                "id": 2761,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0553,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1590.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2035,
                "id": 2762,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0559,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1588.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2036,
                "id": 2763,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0565,
                "lng": 13.0558,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2037,
                "id": 2764,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0569,
                "lng": 13.0559,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2038,
                "id": 2765,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0574,
                "lng": 13.0561,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2039,
                "id": 2766,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0586,
                "lng": 13.0563,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1582.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2040,
                "id": 2767,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0595,
                "lng": 13.0575,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            },
            {
                "@id": 2041,
                "id": 2768,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0612,
                "lng": 13.0577,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1581.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 1924
            }
        ],
        "tblUser": 1
    },
    {
        "@id": 2042,
        "id": 153,
        "date": "2024-07-10T16:20:29.692775Z",
        "descr": "Vorderer Geißlkopf oder Hohe Geißel 2974mTrack Description",
        "name": "Vorderer Geißlkopf oder Hohe Geißel 2974m",
        "zoomCenterPoint": "lat:47.06115650001463,lng:13.057751000003808",
        "trackDistance": 14.871965284409585,
        "google_encoded_polyline": "esf~GijunAdIbAtDpFzFVrAv@jALhCvDjBCdBh@`N}L`DKdBmA|CLrAkBfDe@dJsI~M_S`KqCbIgHtFoJdBkOnD{I~GiBxEeItAc@IcC|AwBV}FdB?W}HbBqGhHkFlFuPxB{Ms@kFgH}HvG}DdJrBhKcAtCbC}@~G^hIvB|BGzD|BtCbL`CpAvFrKxFfF|NbDcBrBbDpJlGlEhGnFlCTjD`A_AH~BpA`@d@wDe@vDqAa@I_CaA~@UkDoFmCmEiGqJmGsBcDcDbBgF}NsKyFqAwFcLaC}BuCkCOqArA{AW{Al@mAlA[`@qAPFpEsCR}DsAgEhBwChEiHjFcBpGV|HeB?W|F}AvBHbCuAb@yEdI_HhBoDzIeBjOuFnJcIfHaKpC_N~ReJrIgDd@sAjB}CMeBlAaDJaN|LeBi@kBBiCwDkAMsAw@{FWuDqFgIk@",
        "tblgps": [
            {
                "@id": 2043,
                "id": 2769,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0612,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1581.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2044,
                "id": 2770,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0595,
                "lng": 13.0575,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2045,
                "id": 2771,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0586,
                "lng": 13.0563,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1582.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2046,
                "id": 2772,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0574,
                "lng": 13.0561,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2047,
                "id": 2773,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0569,
                "lng": 13.0559,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2048,
                "id": 2774,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0565,
                "lng": 13.0558,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2049,
                "id": 2775,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0559,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1588.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2050,
                "id": 2776,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0553,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1590.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2051,
                "id": 2777,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0548,
                "lng": 13.0547,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1592.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2052,
                "id": 2778,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0524,
                "lng": 13.0569,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1604.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2053,
                "id": 2779,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0516,
                "lng": 13.057,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1606.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2054,
                "id": 2780,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0511,
                "lng": 13.0574,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1608.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2055,
                "id": 2781,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0503,
                "lng": 13.0573,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2056,
                "id": 2782,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0499,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.41,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2057,
                "id": 2783,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.049,
                "lng": 13.058,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2058,
                "id": 2784,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0472,
                "lng": 13.0597,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2059,
                "id": 2785,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0448,
                "lng": 13.0629,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1621.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2060,
                "id": 2786,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0429,
                "lng": 13.0636,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1630.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2061,
                "id": 2787,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0413,
                "lng": 13.0651,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2062,
                "id": 2788,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0401,
                "lng": 13.067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1638.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2063,
                "id": 2789,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0395,
                "lng": 13.0696,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1662.82,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2064,
                "id": 2790,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0387,
                "lng": 13.0713,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1709.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2065,
                "id": 2791,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0372,
                "lng": 13.0719,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1772.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2066,
                "id": 2792,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0361,
                "lng": 13.0735,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2067,
                "id": 2793,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0357,
                "lng": 13.0737,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1880.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2068,
                "id": 2794,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0358,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1902.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2069,
                "id": 2795,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0353,
                "lng": 13.0749,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1937.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2070,
                "id": 2796,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0352,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1991.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2071,
                "id": 2797,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0347,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1999.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2072,
                "id": 2798,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0348,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2047.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2073,
                "id": 2799,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0343,
                "lng": 13.0792,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2112.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2074,
                "id": 2800,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0328,
                "lng": 13.0803,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2126.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2075,
                "id": 2801,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0316,
                "lng": 13.0832,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2165.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2076,
                "id": 2802,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.031,
                "lng": 13.0856,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2209.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2077,
                "id": 2803,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0313,
                "lng": 13.0867,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2239.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2078,
                "id": 2804,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0327,
                "lng": 13.0883,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2316.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2079,
                "id": 2805,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0313,
                "lng": 13.0893,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2380.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2080,
                "id": 2806,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0295,
                "lng": 13.0887,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2371.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2081,
                "id": 2807,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0276,
                "lng": 13.089,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2443.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2082,
                "id": 2808,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0268,
                "lng": 13.0884,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2425.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2083,
                "id": 2809,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0271,
                "lng": 13.0869,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2445.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2084,
                "id": 2810,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.027,
                "lng": 13.0853,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2432.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2085,
                "id": 2811,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0847,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2437.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2086,
                "id": 2812,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0837,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2442.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2087,
                "id": 2813,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.083,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2459.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2088,
                "id": 2814,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.0823,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2534.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2089,
                "id": 2815,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0233,
                "lng": 13.0811,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2601.39,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2090,
                "id": 2816,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.0798,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2657.15,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2091,
                "id": 2817,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0201,
                "lng": 13.0773,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2711.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2092,
                "id": 2818,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0193,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2731.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2093,
                "id": 2819,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.0769,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2752.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2094,
                "id": 2820,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0168,
                "lng": 13.0756,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2796.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2095,
                "id": 2821,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0158,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2831.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2096,
                "id": 2822,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.0736,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2879.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2097,
                "id": 2823,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0145,
                "lng": 13.0727,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2877.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2098,
                "id": 2824,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0142,
                "lng": 13.073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2905.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2099,
                "id": 2825,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0141,
                "lng": 13.0724,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2908.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2100,
                "id": 2826,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.0722,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2935.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2101,
                "id": 2827,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0135,
                "lng": 13.0731,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2972.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2102,
                "id": 2828,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.0722,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2935.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2103,
                "id": 2829,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0141,
                "lng": 13.0724,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2908.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2104,
                "id": 2830,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0142,
                "lng": 13.073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2905.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2105,
                "id": 2831,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0145,
                "lng": 13.0727,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2877.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2106,
                "id": 2832,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.0736,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2879.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2107,
                "id": 2833,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0158,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2831.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2108,
                "id": 2834,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0168,
                "lng": 13.0756,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2796.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2109,
                "id": 2835,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.0769,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2752.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2110,
                "id": 2836,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0193,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2731.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2111,
                "id": 2837,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0201,
                "lng": 13.0773,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2711.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2112,
                "id": 2838,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.0798,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2657.15,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2113,
                "id": 2839,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0233,
                "lng": 13.0811,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2601.39,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2114,
                "id": 2840,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.0823,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2534.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2115,
                "id": 2841,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.083,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2459.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2116,
                "id": 2842,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0837,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2442.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2117,
                "id": 2843,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0271,
                "lng": 13.0838,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2433.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2118,
                "id": 2844,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0275,
                "lng": 13.0834,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2419.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2119,
                "id": 2845,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.028,
                "lng": 13.0835,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2390.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2120,
                "id": 2846,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0284,
                "lng": 13.0833,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2361.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2121,
                "id": 2847,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0288,
                "lng": 13.0829,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2334.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2122,
                "id": 2848,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.029,
                "lng": 13.0827,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2321.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2123,
                "id": 2849,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0294,
                "lng": 13.0826,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2297.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2124,
                "id": 2850,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0293,
                "lng": 13.0816,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2271.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2125,
                "id": 2851,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0301,
                "lng": 13.0815,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2216.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2126,
                "id": 2852,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.031,
                "lng": 13.0819,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2165.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2127,
                "id": 2853,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.032,
                "lng": 13.0813,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2135.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2128,
                "id": 2854,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0328,
                "lng": 13.0803,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2126.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2129,
                "id": 2855,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0343,
                "lng": 13.0792,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2112.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2130,
                "id": 2856,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0348,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2047.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2131,
                "id": 2857,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0347,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1999.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2132,
                "id": 2858,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0352,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1991.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2133,
                "id": 2859,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0353,
                "lng": 13.0749,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1937.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2134,
                "id": 2860,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0358,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1902.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2135,
                "id": 2861,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0357,
                "lng": 13.0737,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1880.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2136,
                "id": 2862,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0361,
                "lng": 13.0735,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2137,
                "id": 2863,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0372,
                "lng": 13.0719,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1772.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2138,
                "id": 2864,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0387,
                "lng": 13.0713,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1709.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2139,
                "id": 2865,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0395,
                "lng": 13.0696,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1662.82,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2140,
                "id": 2866,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0401,
                "lng": 13.067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1638.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2141,
                "id": 2867,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0413,
                "lng": 13.0651,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2142,
                "id": 2868,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0429,
                "lng": 13.0636,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1630.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2143,
                "id": 2869,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0448,
                "lng": 13.0629,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1621.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2144,
                "id": 2870,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0472,
                "lng": 13.0597,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2145,
                "id": 2871,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.049,
                "lng": 13.058,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2146,
                "id": 2872,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0499,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.41,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2147,
                "id": 2873,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0503,
                "lng": 13.0573,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2148,
                "id": 2874,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0511,
                "lng": 13.0574,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1608.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2149,
                "id": 2875,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0516,
                "lng": 13.057,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1606.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2150,
                "id": 2876,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0524,
                "lng": 13.0569,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1604.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2151,
                "id": 2877,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0548,
                "lng": 13.0547,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1592.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2152,
                "id": 2878,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0553,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1590.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2153,
                "id": 2879,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0559,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1588.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2154,
                "id": 2880,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0565,
                "lng": 13.0558,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2155,
                "id": 2881,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0569,
                "lng": 13.0559,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2156,
                "id": 2882,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0574,
                "lng": 13.0561,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2157,
                "id": 2883,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0586,
                "lng": 13.0563,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1582.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2158,
                "id": 2884,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0595,
                "lng": 13.0575,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            },
            {
                "@id": 2159,
                "id": 2885,
                "date": "2024-07-10T16:20:29.692775Z",
                "descr": null,
                "lat": 47.0612,
                "lng": 13.0577,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1581.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2042
            }
        ],
        "tblUser": 1
    },
    {
        "@id": 2160,
        "id": 154,
        "date": "2024-07-10T16:20:30.441747Z",
        "descr": "Rameterspitz oder Romatenspitz 2696mTrack Description",
        "name": "Rameterspitz oder Romatenspitz 2696m",
        "zoomCenterPoint": "lat:47.00098400000015,lng:13.132766999999552",
        "trackDistance": 18.485583513710154,
        "google_encoded_polyline": "c{z}Gw~coAs@DgAtSEjP^vCdBnC?l@m@?eBiAeA}AOcEw@}Ck@oIYG@~Jj@rEe@vEZbCGfCvF`IX~A{@|EPxAOzA^xCEzCnArDb@dDp@fBMd@_@EcBqFsAuBaBwAk@iCSKM^Z|BFfCnArCF`A[`DLlAhD~DnA`ANf@_@LaAaAc@APn@nCdCHb@UF_BmAiB}@gAyA[FLhAjBvD{AdMOHAq@a@[a@fAy@Gm@[]l@k@o@k@A{BbAuA`CiAJuBjB_BnDoAhA]z@IlAXlCOx@a@Rc@QWqBUq@YVIo@gA\\g@_@}ANMk@o@g@cBUkAmCV}CKo@}@M_BZ_Ab@w@D_Bp@gAfAw@nAg@HS\\]LoA}AoAe@qA}AoBcJ_AmCs@[Yc@c@v@c@NuCmDs@CWwAqAwD{AgB{@g@{BkC}BUgAyAmAOcFJ}AgA_BqC]wAFkBkBQCm@MOLs@s@uBTgC{@sBJ_BCkA_Ay@MkA}@oAUcBk@kA_@yAI_BTsBXcANkB?o@]o@}AKK_@mCbAC_AaCbAY`@]?Wq@?_DRaCcA{@cAVm@hAGhCX_AC{@TGJq@PTPWLb@Xa@Vb@CfAl@lACpAZCJT`@U^z@b@]`@^z@K\\^^[`@NXUPP`@ELZ|@?Nf@ZFJb@h@MBl@d@ALTZWA`@RAUfA`@ROj@Nv@Gh@W@Id@e@BYj@UDAd@X^ULDn@Mb@d@RvB[L\\[dARZcAl@Ep@XN@|@TJa@|ATh@h@FFh@l@DHt@\\t@O\\Df@]F?l@Yl@Jr@Of@PR^AKzAh@nH`@e@H|B`@s@LnAX}@JjA`@k@^jCfBdFZm@?kCXmCm@eD\\RTSV`@\\WR^f@QP`@ZUXd@\\INlAZRD|@ZJU~@RVH`BMbBBjAd@QCbAd@MWlBCdCp@c@f@@z@pF^nEzApHEvAZ@B`@\\B@f@LFF^VEJd@n@{@j@HC`@ZNg@~@`AZg@n@pA\\W^LZOx@`@GHx@`@LGbAt@vAb@pAFtB^~@No@^TDg@l@`AV~BLg@d@BAs@^C@g@ZBRe@XLf@a@Xd@^]VTTk@^R^Ip@`@f@U`@LZQd@ZPc@O{@ZYBgAf@z@Tk@^FBg@d@FTU?g@rBeAvC_@lAm@An@x@A?jAnDfClAX`D?a@]rAi@Re@|@ClD{CzDuB^z@j@Mn@Rj@{@tBqE~ByAVkAlAZk@cAPGaBeB{@e@AYl@R~BDzAd@sAyBmC}BJkBe@sAzAgMmB{DOcA^KbA|AlBz@xAlAVGGa@qCgCOo@b@?`A`A\\MKe@qAaAkD_EMoAZ}CEeAqAqCGeC[_CL_@TLj@jC|AtArApBfBtF^@Ja@q@gBc@gDmAsD@yC[{CN}AQsAz@_FYcByF_IDeCYcCd@{Ek@mE?eKTDn@pIr@|CNdEfA|AdBhAj@@?o@cBqC_@sCDoPfAoSp@I",
        "tblgps": [
            {
                "@id": 2161,
                "id": 2886,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1328,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1288.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2162,
                "id": 2887,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1327,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1289.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2163,
                "id": 2888,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1294,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1312.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2164,
                "id": 2889,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1266,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1336.32,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2165,
                "id": 2890,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1259,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1342.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2166,
                "id": 2891,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1352.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2167,
                "id": 2892,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1354.12,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2168,
                "id": 2893,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1358.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2169,
                "id": 2894,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1363.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2170,
                "id": 2895,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1258,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1368.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2171,
                "id": 2896,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1267,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1375.52,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2172,
                "id": 2897,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1275,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1381.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2173,
                "id": 2898,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1292,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1392.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2174,
                "id": 2899,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0028,
                "lng": 13.1293,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1393.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2175,
                "id": 2900,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0028,
                "lng": 13.1273,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1408.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2176,
                "id": 2901,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.1263,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1414.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2177,
                "id": 2902,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0027,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1422.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2178,
                "id": 2903,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1427.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2179,
                "id": 2904,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1239,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1427.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2180,
                "id": 2905,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1223,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1448.03,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2181,
                "id": 2906,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1218,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1452.48,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2182,
                "id": 2907,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1207,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1463.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2183,
                "id": 2908,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1202,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1467.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2184,
                "id": 2909,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1197,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1470.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2185,
                "id": 2910,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1477.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2186,
                "id": 2911,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1182,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1484.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2187,
                "id": 2912,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1173,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1489.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2188,
                "id": 2913,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1165,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1497.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2189,
                "id": 2914,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1159,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1500.87,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2190,
                "id": 2915,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1502.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2191,
                "id": 2916,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1505.6,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2192,
                "id": 2917,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.117,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1513.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2193,
                "id": 2918,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1176,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1521.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2194,
                "id": 2919,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0022,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1526.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2195,
                "id": 2920,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1187,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1531.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2196,
                "id": 2921,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.1188,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1533.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2197,
                "id": 2922,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1536.75,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2198,
                "id": 2923,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1539.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2199,
                "id": 2924,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1173,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1547.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2200,
                "id": 2925,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1166,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1555.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2201,
                "id": 2926,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1162,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1559.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2202,
                "id": 2927,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1154,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1565.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2203,
                "id": 2928,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1569.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2204,
                "id": 2929,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1141,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2205,
                "id": 2930,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1137,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1587.32,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2206,
                "id": 2931,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1136,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1589.32,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2207,
                "id": 2932,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0009,
                "lng": 13.1135,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1594.6,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2208,
                "id": 2933,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1596.09,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2209,
                "id": 2934,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1599.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2210,
                "id": 2935,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.1136,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1602.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2211,
                "id": 2936,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1129,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1610.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2212,
                "id": 2937,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0005,
                "lng": 13.1127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2213,
                "id": 2938,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1614.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2214,
                "id": 2939,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0011,
                "lng": 13.1131,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1620.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2215,
                "id": 2940,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1134,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1626.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2216,
                "id": 2941,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1630.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2217,
                "id": 2942,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0022,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2218,
                "id": 2943,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1134,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1640.21,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2219,
                "id": 2944,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1648.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2220,
                "id": 2945,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1669.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2221,
                "id": 2946,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1671.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2222,
                "id": 2947,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1672.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2223,
                "id": 2948,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0023,
                "lng": 13.1106,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1675.37,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2224,
                "id": 2949,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1685.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2225,
                "id": 2950,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0027,
                "lng": 13.1103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1684.12,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2226,
                "id": 2951,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.003,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1693.45,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2227,
                "id": 2952,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0031,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1702.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2228,
                "id": 2953,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0033,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1709.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2229,
                "id": 2954,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0035,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1718.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2230,
                "id": 2955,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0042,
                "lng": 13.1101,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1740.9,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2231,
                "id": 2956,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0046,
                "lng": 13.1094,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1765.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2232,
                "id": 2957,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.005,
                "lng": 13.1094,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1772.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2233,
                "id": 2958,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0056,
                "lng": 13.1088,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1788.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2234,
                "id": 2959,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.006,
                "lng": 13.108,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1808.95,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2235,
                "id": 2960,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0064,
                "lng": 13.1076,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1818.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2236,
                "id": 2961,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0066,
                "lng": 13.1073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1819.07,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2237,
                "id": 2962,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0066,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1821.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2238,
                "id": 2963,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0065,
                "lng": 13.1062,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1832.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2239,
                "id": 2964,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0066,
                "lng": 13.1059,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1839.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2240,
                "id": 2965,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0068,
                "lng": 13.1058,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1839.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2241,
                "id": 2966,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0069,
                "lng": 13.1059,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1844.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2242,
                "id": 2967,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0071,
                "lng": 13.1065,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1855.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2243,
                "id": 2968,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0072,
                "lng": 13.1067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1863.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2244,
                "id": 2969,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0073,
                "lng": 13.1066,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1868.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2245,
                "id": 2970,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0073,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1873.07,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2246,
                "id": 2971,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0077,
                "lng": 13.1067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1887.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2247,
                "id": 2972,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0079,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1894.75,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2248,
                "id": 2973,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0084,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1907.6,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2249,
                "id": 2974,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0084,
                "lng": 13.107,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1911.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2250,
                "id": 2975,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0087,
                "lng": 13.1072,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1917.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2251,
                "id": 2976,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0092,
                "lng": 13.1073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1933.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2252,
                "id": 2977,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0096,
                "lng": 13.108,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1948.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2253,
                "id": 2978,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0094,
                "lng": 13.1088,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1966.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2254,
                "id": 2979,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0095,
                "lng": 13.109,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1968.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2255,
                "id": 2980,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0098,
                "lng": 13.1091,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1974.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2256,
                "id": 2981,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0103,
                "lng": 13.109,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1988.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2257,
                "id": 2982,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0106,
                "lng": 13.1088,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1994.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2258,
                "id": 2983,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0109,
                "lng": 13.1087,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1995.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2259,
                "id": 2984,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0114,
                "lng": 13.1085,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2002.01,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2260,
                "id": 2985,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0117,
                "lng": 13.1081,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2008.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2261,
                "id": 2986,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.012,
                "lng": 13.1077,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2019.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2262,
                "id": 2987,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0122,
                "lng": 13.1077,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2022.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2263,
                "id": 2988,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0123,
                "lng": 13.1075,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2025.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2264,
                "id": 2989,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0125,
                "lng": 13.1075,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2033.94,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2265,
                "id": 2990,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0129,
                "lng": 13.1079,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2044.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2266,
                "id": 2991,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0133,
                "lng": 13.1081,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2048.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2267,
                "id": 2992,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.1086,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2052.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2268,
                "id": 2993,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0142,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2070.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2269,
                "id": 2994,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.1111,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2090.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2270,
                "id": 2995,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0148,
                "lng": 13.1112,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2099.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2271,
                "id": 2996,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0149,
                "lng": 13.1114,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2105.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2272,
                "id": 2997,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0151,
                "lng": 13.1111,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2111.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2273,
                "id": 2998,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0153,
                "lng": 13.111,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2115.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2274,
                "id": 2999,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0161,
                "lng": 13.1119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2127.21,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2275,
                "id": 3000,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0163,
                "lng": 13.1119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2130.6,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2276,
                "id": 3001,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0164,
                "lng": 13.1124,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2134.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2277,
                "id": 3002,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0168,
                "lng": 13.1133,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2144.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2278,
                "id": 3003,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0173,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2147.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2279,
                "id": 3004,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0176,
                "lng": 13.114,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2148.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2280,
                "id": 3005,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0182,
                "lng": 13.1147,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2158.54,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2281,
                "id": 3006,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0189,
                "lng": 13.1148,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2173.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2282,
                "id": 3007,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0192,
                "lng": 13.1153,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2190.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2283,
                "id": 3008,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0196,
                "lng": 13.1153,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2202.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2284,
                "id": 3009,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0207,
                "lng": 13.1153,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2232.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2285,
                "id": 3010,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.1157,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2248.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2286,
                "id": 3011,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0217,
                "lng": 13.1164,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2272.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2287,
                "id": 3012,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0219,
                "lng": 13.1168,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2283.03,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2288,
                "id": 3013,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0218,
                "lng": 13.1174,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2293.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2289,
                "id": 3014,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0223,
                "lng": 13.1175,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2316.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2290,
                "id": 3015,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1177,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2322.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2291,
                "id": 3016,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1178,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2327.87,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2292,
                "id": 3017,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2331.03,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2293,
                "id": 3018,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2346.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2294,
                "id": 3019,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0225,
                "lng": 13.1193,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2359.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2295,
                "id": 3020,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1199,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2374.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2296,
                "id": 3021,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1204,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2387.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2297,
                "id": 3022,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1207,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2393.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2298,
                "id": 3023,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.121,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2406.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2299,
                "id": 3024,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.1214,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2413.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2300,
                "id": 3025,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0235,
                "lng": 13.1218,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2426.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2301,
                "id": 3026,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0236,
                "lng": 13.1223,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2441.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2302,
                "id": 3027,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0238,
                "lng": 13.1227,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2452.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2303,
                "id": 3028,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.024,
                "lng": 13.1231,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2468.21,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2304,
                "id": 3029,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.024,
                "lng": 13.1236,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2476.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2305,
                "id": 3030,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0239,
                "lng": 13.1242,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2493.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2306,
                "id": 3031,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0238,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2502.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2307,
                "id": 3032,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.1251,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2516.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2308,
                "id": 3033,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2520.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2309,
                "id": 3034,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0238,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2530.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2310,
                "id": 3035,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0243,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2542.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2311,
                "id": 3036,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0244,
                "lng": 13.1258,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2547.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2312,
                "id": 3037,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0251,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2573.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2313,
                "id": 3038,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0251,
                "lng": 13.1257,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2578.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2314,
                "id": 3039,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2604.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2315,
                "id": 3040,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0259,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2609.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2316,
                "id": 3041,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.026,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2616.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2317,
                "id": 3042,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0262,
                "lng": 13.1255,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2625.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2318,
                "id": 3043,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0262,
                "lng": 13.1263,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2643.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2319,
                "id": 3044,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0261,
                "lng": 13.1269,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2658.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2320,
                "id": 3045,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.1272,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2673.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2321,
                "id": 3046,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0267,
                "lng": 13.1271,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2680.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2322,
                "id": 3047,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.027,
                "lng": 13.1268,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2691.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2323,
                "id": 3048,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.027,
                "lng": 13.1261,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2693.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2324,
                "id": 3049,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0269,
                "lng": 13.1264,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2689.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2325,
                "id": 3050,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0269,
                "lng": 13.1267,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2688.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2326,
                "id": 3051,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0268,
                "lng": 13.1267,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2683.77,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2327,
                "id": 3052,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0267,
                "lng": 13.127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2680.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2328,
                "id": 3053,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0266,
                "lng": 13.1269,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2675.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2329,
                "id": 3054,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0265,
                "lng": 13.127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2672.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2330,
                "id": 3055,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0265,
                "lng": 13.1268,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2669.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2331,
                "id": 3056,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0263,
                "lng": 13.127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2666.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2332,
                "id": 3057,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0262,
                "lng": 13.1268,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2660.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2333,
                "id": 3058,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0263,
                "lng": 13.1264,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2653.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2334,
                "id": 3059,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.026,
                "lng": 13.126,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2628.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2335,
                "id": 3060,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.026,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2619.98,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2336,
                "id": 3061,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0259,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2613.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2337,
                "id": 3062,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.1255,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2609.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2338,
                "id": 3063,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0257,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2603.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2339,
                "id": 3064,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0255,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2591.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2340,
                "id": 3065,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0253,
                "lng": 13.1255,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2585.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2341,
                "id": 3066,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0252,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2575.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2342,
                "id": 3067,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0249,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2561.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2343,
                "id": 3068,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0247,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2552.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2344,
                "id": 3069,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0245,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2547.94,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2345,
                "id": 3070,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0244,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2540.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2346,
                "id": 3071,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0243,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2536.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2347,
                "id": 3072,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0242,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2533.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2348,
                "id": 3073,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.024,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2529.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2349,
                "id": 3074,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0239,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2524.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2350,
                "id": 3075,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0236,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2516.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2351,
                "id": 3076,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0235,
                "lng": 13.125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2510.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2352,
                "id": 3077,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0234,
                "lng": 13.125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2504.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2353,
                "id": 3078,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0233,
                "lng": 13.1248,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2498.77,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2354,
                "id": 3079,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2495.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2355,
                "id": 3080,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1246,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2490.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2356,
                "id": 3081,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0229,
                "lng": 13.1246,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2481.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2357,
                "id": 3082,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2474.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2358,
                "id": 3083,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1246,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2473.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2359,
                "id": 3084,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2472.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2360,
                "id": 3085,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2472.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2361,
                "id": 3086,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1241,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2469.15,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2362,
                "id": 3087,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0225,
                "lng": 13.124,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2462.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2363,
                "id": 3088,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1238,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2460.77,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2364,
                "id": 3089,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1235,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2457.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2365,
                "id": 3090,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1233,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2457.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2366,
                "id": 3091,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1233,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2454.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2367,
                "id": 3092,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1231,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2451.54,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2368,
                "id": 3093,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0229,
                "lng": 13.1231,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2448.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2369,
                "id": 3094,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1229,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2444.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2370,
                "id": 3095,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.1228,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2444.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2371,
                "id": 3096,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.1227,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2442.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2372,
                "id": 3097,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1225,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2439.37,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2373,
                "id": 3098,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.1224,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2437.54,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2374,
                "id": 3099,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1222,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2432.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2375,
                "id": 3100,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.122,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2428.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2376,
                "id": 3101,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.023,
                "lng": 13.1219,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2424.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2377,
                "id": 3102,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1221,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2416.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2378,
                "id": 3103,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1219,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2412.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2379,
                "id": 3104,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0225,
                "lng": 13.1215,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2413.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2380,
                "id": 3105,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1214,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2410.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2381,
                "id": 3106,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1212,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2407.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2382,
                "id": 3107,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1209,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2397.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2383,
                "id": 3108,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1209,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2393.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2384,
                "id": 3109,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1205,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2392.95,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2385,
                "id": 3110,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0225,
                "lng": 13.1205,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2391.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2386,
                "id": 3111,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.12,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2378.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2387,
                "id": 3112,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1198,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2368.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2388,
                "id": 3113,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1198,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2363.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2389,
                "id": 3114,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0223,
                "lng": 13.1196,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2357.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2390,
                "id": 3115,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0221,
                "lng": 13.1195,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2352.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2391,
                "id": 3116,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.022,
                "lng": 13.1193,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2351.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2392,
                "id": 3117,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0219,
                "lng": 13.119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2350.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2393,
                "id": 3118,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.022,
                "lng": 13.1188,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2347.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2394,
                "id": 3119,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0219,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2342.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2395,
                "id": 3120,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0221,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2342.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2396,
                "id": 3121,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0221,
                "lng": 13.1184,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2336.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2397,
                "id": 3122,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0222,
                "lng": 13.1181,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2331.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2398,
                "id": 3123,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0222,
                "lng": 13.1179,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2325.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2399,
                "id": 3124,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0222,
                "lng": 13.1177,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2318.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2400,
                "id": 3125,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0222,
                "lng": 13.1176,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2312.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2401,
                "id": 3126,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.022,
                "lng": 13.1176,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2307.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2402,
                "id": 3127,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0221,
                "lng": 13.1171,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2298.45,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2403,
                "id": 3128,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0218,
                "lng": 13.1156,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2281.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2404,
                "id": 3129,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0217,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2272.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2405,
                "id": 3130,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0216,
                "lng": 13.1152,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2269.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2406,
                "id": 3131,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0215,
                "lng": 13.1154,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2258.98,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2407,
                "id": 3132,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0214,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2256.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2408,
                "id": 3133,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0213,
                "lng": 13.1153,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2249.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2409,
                "id": 3134,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2246.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2410,
                "id": 3135,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.021,
                "lng": 13.1152,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2241.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2411,
                "id": 3136,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0209,
                "lng": 13.1145,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2236.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2412,
                "id": 3137,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0204,
                "lng": 13.1133,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2227.91,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2413,
                "id": 3138,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0202,
                "lng": 13.1135,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2225.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2414,
                "id": 3139,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0202,
                "lng": 13.1142,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2222.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2415,
                "id": 3140,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0201,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2216.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2416,
                "id": 3141,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0203,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2213.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2417,
                "id": 3142,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0202,
                "lng": 13.1157,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2211.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2418,
                "id": 3143,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.02,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2210.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2419,
                "id": 3144,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0199,
                "lng": 13.1156,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2206.94,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2420,
                "id": 3145,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0198,
                "lng": 13.1157,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2206.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2421,
                "id": 3146,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0197,
                "lng": 13.1156,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2201.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2422,
                "id": 3147,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0195,
                "lng": 13.1157,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2199.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2423,
                "id": 3148,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0194,
                "lng": 13.1155,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2194.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2424,
                "id": 3149,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0192,
                "lng": 13.1156,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2195.37,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2425,
                "id": 3150,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0191,
                "lng": 13.1154,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2187.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2426,
                "id": 3151,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.019,
                "lng": 13.1155,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2185.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2427,
                "id": 3152,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0189,
                "lng": 13.1151,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2176.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2428,
                "id": 3153,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2170.03,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2429,
                "id": 3154,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.1147,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2166.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2430,
                "id": 3155,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0186,
                "lng": 13.1146,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2162.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2431,
                "id": 3156,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.1143,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2161.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2432,
                "id": 3157,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0186,
                "lng": 13.1142,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2158.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2433,
                "id": 3158,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0185,
                "lng": 13.1137,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2156.75,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2434,
                "id": 3159,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0186,
                "lng": 13.1132,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2155.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2435,
                "id": 3160,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0186,
                "lng": 13.1128,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2153.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2436,
                "id": 3161,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0184,
                "lng": 13.1129,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2146.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2437,
                "id": 3162,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0184,
                "lng": 13.1125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2144.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2438,
                "id": 3163,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0182,
                "lng": 13.1126,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2140.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2439,
                "id": 3164,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0184,
                "lng": 13.1121,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2136.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2440,
                "id": 3165,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0184,
                "lng": 13.1114,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2130.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2441,
                "id": 3166,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0181,
                "lng": 13.1116,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2128.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2442,
                "id": 3167,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0179,
                "lng": 13.1116,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2125.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2443,
                "id": 3168,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0176,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2123.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2444,
                "id": 3169,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0175,
                "lng": 13.1093,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2118.41,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2445,
                "id": 3170,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.017,
                "lng": 13.1078,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2111.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2446,
                "id": 3171,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.017,
                "lng": 13.1074,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2107.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2447,
                "id": 3172,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0169,
                "lng": 13.1073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2104.64,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2448,
                "id": 3173,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0169,
                "lng": 13.1072,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2103.95,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2449,
                "id": 3174,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0167,
                "lng": 13.1071,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2099.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2450,
                "id": 3175,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0167,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2096.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2451,
                "id": 3176,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0166,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2093.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2452,
                "id": 3177,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0166,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2089.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2453,
                "id": 3178,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0165,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2086.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2454,
                "id": 3179,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0164,
                "lng": 13.1066,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2083.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2455,
                "id": 3180,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0162,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2082.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2456,
                "id": 3181,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.016,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2081.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2457,
                "id": 3182,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.016,
                "lng": 13.1067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2077.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2458,
                "id": 3183,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0158,
                "lng": 13.1066,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2070.95,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2459,
                "id": 3184,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.016,
                "lng": 13.1063,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2066.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2460,
                "id": 3185,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0157,
                "lng": 13.1061,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2063.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2461,
                "id": 3186,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0159,
                "lng": 13.1059,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2057.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2462,
                "id": 3187,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0155,
                "lng": 13.1057,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2056.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2463,
                "id": 3188,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0156,
                "lng": 13.1056,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2050.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2464,
                "id": 3189,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0155,
                "lng": 13.1054,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2047.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2465,
                "id": 3190,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0156,
                "lng": 13.1052,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2046.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2466,
                "id": 3191,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0155,
                "lng": 13.1052,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2041.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2467,
                "id": 3192,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0154,
                "lng": 13.1049,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2039.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2468,
                "id": 3193,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0152,
                "lng": 13.1048,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2034.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2469,
                "id": 3194,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0153,
                "lng": 13.1045,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2034.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2470,
                "id": 3195,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.015,
                "lng": 13.104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2029.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2471,
                "id": 3196,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0148,
                "lng": 13.1036,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2023.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2472,
                "id": 3197,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0148,
                "lng": 13.1031,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2022.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2473,
                "id": 3198,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.1027,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2020.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2474,
                "id": 3199,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2012.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2475,
                "id": 3200,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0144,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2005.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2476,
                "id": 3201,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0144,
                "lng": 13.1031,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1999.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2477,
                "id": 3202,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0141,
                "lng": 13.1027,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1993.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2478,
                "id": 3203,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.014,
                "lng": 13.1021,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1986.01,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2479,
                "id": 3204,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0139,
                "lng": 13.1023,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1980.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2480,
                "id": 3205,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.1023,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1975.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2481,
                "id": 3206,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0138,
                "lng": 13.1025,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1972.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2482,
                "id": 3207,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0136,
                "lng": 13.1025,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1966.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2483,
                "id": 3208,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0136,
                "lng": 13.1028,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1964.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2484,
                "id": 3209,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0135,
                "lng": 13.1027,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1960.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2485,
                "id": 3210,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0134,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1956.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2486,
                "id": 3211,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0132,
                "lng": 13.1028,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1951.52,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2487,
                "id": 3212,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.013,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1947.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2488,
                "id": 3213,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0129,
                "lng": 13.1028,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1941.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2489,
                "id": 3214,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0127,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1934.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2490,
                "id": 3215,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0126,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1932.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2491,
                "id": 3216,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0125,
                "lng": 13.1031,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1924.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2492,
                "id": 3217,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0123,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1919.98,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2493,
                "id": 3218,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0122,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1912.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2494,
                "id": 3219,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0119,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1910.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2495,
                "id": 3220,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0117,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1898.09,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2496,
                "id": 3221,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0116,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1892.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2497,
                "id": 3222,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0114,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1885.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2498,
                "id": 3223,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0112,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1879.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2499,
                "id": 3224,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0111,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1871.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2500,
                "id": 3225,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0112,
                "lng": 13.1033,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1869.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2501,
                "id": 3226,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0111,
                "lng": 13.1035,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1865.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2502,
                "id": 3227,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0111,
                "lng": 13.1038,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1862.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2503,
                "id": 3228,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0109,
                "lng": 13.1035,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1860.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2504,
                "id": 3229,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0108,
                "lng": 13.1037,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1857.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2505,
                "id": 3230,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0106,
                "lng": 13.1037,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.64,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2506,
                "id": 3231,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0106,
                "lng": 13.1039,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2507,
                "id": 3232,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0104,
                "lng": 13.1039,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1849.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2508,
                "id": 3233,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0103,
                "lng": 13.104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1848.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2509,
                "id": 3234,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0103,
                "lng": 13.1042,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1848.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2510,
                "id": 3235,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0097,
                "lng": 13.1045,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1844.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2511,
                "id": 3236,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0089,
                "lng": 13.1047,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1838.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2512,
                "id": 3237,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0085,
                "lng": 13.1049,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1835.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2513,
                "id": 3238,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0085,
                "lng": 13.1047,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1830.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2514,
                "id": 3239,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0083,
                "lng": 13.1047,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1825.98,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2515,
                "id": 3240,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0083,
                "lng": 13.1043,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1823.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2516,
                "id": 3241,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0074,
                "lng": 13.1036,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1816.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2517,
                "id": 3242,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.007,
                "lng": 13.1035,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1815.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2518,
                "id": 3243,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0062,
                "lng": 13.1035,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1810.32,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2519,
                "id": 3244,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0064,
                "lng": 13.1036,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1808.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2520,
                "id": 3245,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0059,
                "lng": 13.1039,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1802.94,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2521,
                "id": 3246,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0058,
                "lng": 13.1041,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1803.73,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2522,
                "id": 3247,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0055,
                "lng": 13.1041,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1797.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2523,
                "id": 3248,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0046,
                "lng": 13.1048,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1779.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2524,
                "id": 3249,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0037,
                "lng": 13.1054,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1763.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2525,
                "id": 3250,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0036,
                "lng": 13.1051,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1759.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2526,
                "id": 3251,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0033,
                "lng": 13.1052,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1759.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2527,
                "id": 3252,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0031,
                "lng": 13.1051,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1758.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2528,
                "id": 3253,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0029,
                "lng": 13.1054,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1760.21,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2529,
                "id": 3254,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0023,
                "lng": 13.1065,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1757.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2530,
                "id": 3255,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1748.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2531,
                "id": 3256,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1747.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2532,
                "id": 3257,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0011,
                "lng": 13.1072,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1742.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2533,
                "id": 3258,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1075,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1735.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2534,
                "id": 3259,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.1075,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1730.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2535,
                "id": 3260,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.108,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1723.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2536,
                "id": 3261,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1082,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1721.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2537,
                "id": 3262,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1084,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1718.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2538,
                "id": 3263,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0018,
                "lng": 13.1083,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1717.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2539,
                "id": 3264,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1082,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1705.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2540,
                "id": 3265,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.108,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1698.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2541,
                "id": 3266,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0011,
                "lng": 13.1087,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1690.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2542,
                "id": 3267,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0019,
                "lng": 13.1093,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1677.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2543,
                "id": 3268,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0018,
                "lng": 13.1098,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1671.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2544,
                "id": 3269,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1669.64,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2545,
                "id": 3270,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1648.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2546,
                "id": 3271,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1135,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1638.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2547,
                "id": 3272,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0022,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2548,
                "id": 3273,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1139,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1629.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2549,
                "id": 3274,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1134,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1626.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2550,
                "id": 3275,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0011,
                "lng": 13.1131,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1619.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2551,
                "id": 3276,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1614.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2552,
                "id": 3277,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0005,
                "lng": 13.1127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.73,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2553,
                "id": 3278,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1129,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1609.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2554,
                "id": 3279,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.1136,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1602.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2555,
                "id": 3280,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1598.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2556,
                "id": 3281,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1595.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2557,
                "id": 3282,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0009,
                "lng": 13.1135,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1593.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2558,
                "id": 3283,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1136,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1589.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2559,
                "id": 3284,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1587.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2560,
                "id": 3285,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1141,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2561,
                "id": 3286,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1151,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1569.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2562,
                "id": 3287,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1154,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1565.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2563,
                "id": 3288,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1162,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1559.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2564,
                "id": 3289,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1166,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1555.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2565,
                "id": 3290,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1173,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1547.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2566,
                "id": 3291,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1540.01,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2567,
                "id": 3292,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1536.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2568,
                "id": 3293,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.1188,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1533.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2569,
                "id": 3294,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1187,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1531.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2570,
                "id": 3295,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0022,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1526.64,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2571,
                "id": 3296,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1176,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1521.45,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2572,
                "id": 3297,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.117,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1513.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2573,
                "id": 3298,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1505.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2574,
                "id": 3299,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1502.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2575,
                "id": 3300,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1159,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1500.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2576,
                "id": 3301,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1165,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1497.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2577,
                "id": 3302,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1173,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1489.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2578,
                "id": 3303,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1182,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1483.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2579,
                "id": 3304,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1477.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2580,
                "id": 3305,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1198,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1470.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2581,
                "id": 3306,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1202,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1466.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2582,
                "id": 3307,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1206,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1463.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2583,
                "id": 3308,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1218,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1452.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2584,
                "id": 3309,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1223,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1447.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2585,
                "id": 3310,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1239,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1427.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2586,
                "id": 3311,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1427.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2587,
                "id": 3312,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0027,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1422.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2588,
                "id": 3313,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.1263,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1414.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2589,
                "id": 3314,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0028,
                "lng": 13.1273,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1408.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2590,
                "id": 3315,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0028,
                "lng": 13.1293,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1393.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2591,
                "id": 3316,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1293,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1392.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2592,
                "id": 3317,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1276,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1381.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2593,
                "id": 3318,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1268,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1376.07,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2594,
                "id": 3319,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1258,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1369.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2595,
                "id": 3320,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1363.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2596,
                "id": 3321,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1358.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2597,
                "id": 3322,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1354.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2598,
                "id": 3323,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1352.48,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2599,
                "id": 3324,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1259,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1342.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2600,
                "id": 3325,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1266,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1336.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2601,
                "id": 3326,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1294,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1312.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2602,
                "id": 3327,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1327,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1290.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            },
            {
                "@id": 2603,
                "id": 3328,
                "date": "2024-07-10T16:20:30.441747Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1328,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1288.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2160
            }
        ],
        "tblUser": 1
    },
    {
        "@id": 2604,
        "id": 202,
        "date": "2024-07-10T19:29:33.478279Z",
        "descr": "Vorderer Geißlkopf oder Hohe Geißel 2974mTrack Description",
        "name": "Vorderer Geißlkopf oder Hohe Geißel 2974m",
        "zoomCenterPoint": "lat:47.06115650001463,lng:13.057751000003808",
        "trackDistance": 14.871965284409585,
        "google_encoded_polyline": "esf~GijunAdIbAtDpFzFVrAv@jALhCvDjBCdBh@`N}L`DKdBmA|CLrAkBfDe@dJsI~M_S`KqCbIgHtFoJdBkOnD{I~GiBxEeItAc@IcC|AwBV}FdB?W}HbBqGhHkFlFuPxB{Ms@kFgH}HvG}DdJrBhKcAtCbC}@~G^hIvB|BGzD|BtCbL`CpAvFrKxFfF|NbDcBrBbDpJlGlEhGnFlCTjD`A_AH~BpA`@d@wDe@vDqAa@I_CaA~@UkDoFmCmEiGqJmGsBcDcDbBgF}NsKyFqAwFcLaC}BuCkCOqArA{AW{Al@mAlA[`@qAPFpEsCR}DsAgEhBwChEiHjFcBpGV|HeB?W|F}AvBHbCuAb@yEdI_HhBoDzIeBjOuFnJcIfHaKpC_N~ReJrIgDd@sAjB}CMeBlAaDJaN|LeBi@kBBiCwDkAMsAw@{FWuDqFgIk@",
        "tblgps": [
            {
                "@id": 2605,
                "id": 3352,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0612,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1581.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2606,
                "id": 3353,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0595,
                "lng": 13.0575,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2607,
                "id": 3354,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0586,
                "lng": 13.0563,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1582.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2608,
                "id": 3355,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0574,
                "lng": 13.0561,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2609,
                "id": 3356,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0569,
                "lng": 13.0559,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2610,
                "id": 3357,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0565,
                "lng": 13.0558,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2611,
                "id": 3358,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0559,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1588.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2612,
                "id": 3359,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0553,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1590.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2613,
                "id": 3360,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0548,
                "lng": 13.0547,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1592.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2614,
                "id": 3361,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0524,
                "lng": 13.0569,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1604.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2615,
                "id": 3362,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0516,
                "lng": 13.057,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1606.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2616,
                "id": 3363,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0511,
                "lng": 13.0574,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1608.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2617,
                "id": 3364,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0503,
                "lng": 13.0573,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2618,
                "id": 3365,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0499,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.41,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2619,
                "id": 3366,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.049,
                "lng": 13.058,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2620,
                "id": 3367,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0472,
                "lng": 13.0597,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2621,
                "id": 3368,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0448,
                "lng": 13.0629,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1621.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2622,
                "id": 3369,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0429,
                "lng": 13.0636,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1630.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2623,
                "id": 3370,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0413,
                "lng": 13.0651,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2624,
                "id": 3371,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0401,
                "lng": 13.067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1638.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2625,
                "id": 3372,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0395,
                "lng": 13.0696,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1662.82,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2626,
                "id": 3373,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0387,
                "lng": 13.0713,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1709.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2627,
                "id": 3374,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0372,
                "lng": 13.0719,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1772.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2628,
                "id": 3375,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0361,
                "lng": 13.0735,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2629,
                "id": 3376,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0357,
                "lng": 13.0737,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1880.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2630,
                "id": 3377,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0358,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1902.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2631,
                "id": 3378,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0353,
                "lng": 13.0749,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1937.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2632,
                "id": 3379,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0352,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1991.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2633,
                "id": 3380,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0347,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1999.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2634,
                "id": 3381,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0348,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2047.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2635,
                "id": 3382,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0343,
                "lng": 13.0792,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2112.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2636,
                "id": 3383,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0328,
                "lng": 13.0803,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2126.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2637,
                "id": 3384,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0316,
                "lng": 13.0832,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2165.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2638,
                "id": 3385,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.031,
                "lng": 13.0856,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2209.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2639,
                "id": 3386,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0313,
                "lng": 13.0867,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2239.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2640,
                "id": 3387,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0327,
                "lng": 13.0883,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2316.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2641,
                "id": 3388,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0313,
                "lng": 13.0893,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2380.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2642,
                "id": 3389,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0295,
                "lng": 13.0887,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2371.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2643,
                "id": 3390,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0276,
                "lng": 13.089,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2443.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2644,
                "id": 3391,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0268,
                "lng": 13.0884,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2425.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2645,
                "id": 3392,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0271,
                "lng": 13.0869,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2445.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2646,
                "id": 3393,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.027,
                "lng": 13.0853,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2432.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2647,
                "id": 3394,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0847,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2437.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2648,
                "id": 3395,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0837,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2442.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2649,
                "id": 3396,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.083,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2459.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2650,
                "id": 3397,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.0823,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2534.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2651,
                "id": 3398,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0233,
                "lng": 13.0811,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2601.39,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2652,
                "id": 3399,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.0798,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2657.15,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2653,
                "id": 3400,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0201,
                "lng": 13.0773,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2711.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2654,
                "id": 3401,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0193,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2731.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2655,
                "id": 3402,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.0769,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2752.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2656,
                "id": 3403,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0168,
                "lng": 13.0756,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2796.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2657,
                "id": 3404,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0158,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2831.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2658,
                "id": 3405,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.0736,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2879.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2659,
                "id": 3406,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0145,
                "lng": 13.0727,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2877.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2660,
                "id": 3407,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0142,
                "lng": 13.073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2905.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2661,
                "id": 3408,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0141,
                "lng": 13.0724,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2908.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2662,
                "id": 3409,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.0722,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2935.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2663,
                "id": 3410,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0135,
                "lng": 13.0731,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2972.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2664,
                "id": 3411,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.0722,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2935.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2665,
                "id": 3412,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0141,
                "lng": 13.0724,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2908.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2666,
                "id": 3413,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0142,
                "lng": 13.073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2905.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2667,
                "id": 3414,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0145,
                "lng": 13.0727,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2877.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2668,
                "id": 3415,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.0736,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2879.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2669,
                "id": 3416,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0158,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2831.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2670,
                "id": 3417,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0168,
                "lng": 13.0756,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2796.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2671,
                "id": 3418,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.0769,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2752.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2672,
                "id": 3419,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0193,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2731.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2673,
                "id": 3420,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0201,
                "lng": 13.0773,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2711.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2674,
                "id": 3421,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.0798,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2657.15,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2675,
                "id": 3422,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0233,
                "lng": 13.0811,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2601.39,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2676,
                "id": 3423,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.0823,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2534.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2677,
                "id": 3424,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.083,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2459.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2678,
                "id": 3425,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0837,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2442.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2679,
                "id": 3426,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0271,
                "lng": 13.0838,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2433.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2680,
                "id": 3427,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0275,
                "lng": 13.0834,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2419.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2681,
                "id": 3428,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.028,
                "lng": 13.0835,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2390.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2682,
                "id": 3429,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0284,
                "lng": 13.0833,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2361.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2683,
                "id": 3430,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0288,
                "lng": 13.0829,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2334.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2684,
                "id": 3431,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.029,
                "lng": 13.0827,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2321.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2685,
                "id": 3432,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0294,
                "lng": 13.0826,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2297.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2686,
                "id": 3433,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0293,
                "lng": 13.0816,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2271.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2687,
                "id": 3434,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0301,
                "lng": 13.0815,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2216.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2688,
                "id": 3435,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.031,
                "lng": 13.0819,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2165.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2689,
                "id": 3436,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.032,
                "lng": 13.0813,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2135.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2690,
                "id": 3437,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0328,
                "lng": 13.0803,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2126.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2691,
                "id": 3438,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0343,
                "lng": 13.0792,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2112.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2692,
                "id": 3439,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0348,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2047.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2693,
                "id": 3440,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0347,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1999.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2694,
                "id": 3441,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0352,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1991.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2695,
                "id": 3442,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0353,
                "lng": 13.0749,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1937.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2696,
                "id": 3443,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0358,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1902.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2697,
                "id": 3444,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0357,
                "lng": 13.0737,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1880.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2698,
                "id": 3445,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0361,
                "lng": 13.0735,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2699,
                "id": 3446,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0372,
                "lng": 13.0719,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1772.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2700,
                "id": 3447,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0387,
                "lng": 13.0713,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1709.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2701,
                "id": 3448,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0395,
                "lng": 13.0696,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1662.82,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2702,
                "id": 3449,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0401,
                "lng": 13.067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1638.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2703,
                "id": 3450,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0413,
                "lng": 13.0651,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2704,
                "id": 3451,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0429,
                "lng": 13.0636,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1630.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2705,
                "id": 3452,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0448,
                "lng": 13.0629,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1621.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2706,
                "id": 3453,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0472,
                "lng": 13.0597,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2707,
                "id": 3454,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.049,
                "lng": 13.058,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2708,
                "id": 3455,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0499,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.41,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2709,
                "id": 3456,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0503,
                "lng": 13.0573,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2710,
                "id": 3457,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0511,
                "lng": 13.0574,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1608.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2711,
                "id": 3458,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0516,
                "lng": 13.057,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1606.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2712,
                "id": 3459,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0524,
                "lng": 13.0569,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1604.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2713,
                "id": 3460,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0548,
                "lng": 13.0547,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1592.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2714,
                "id": 3461,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0553,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1590.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2715,
                "id": 3462,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0559,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1588.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2716,
                "id": 3463,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0565,
                "lng": 13.0558,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2717,
                "id": 3464,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0569,
                "lng": 13.0559,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2718,
                "id": 3465,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0574,
                "lng": 13.0561,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2719,
                "id": 3466,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0586,
                "lng": 13.0563,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1582.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2720,
                "id": 3467,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0595,
                "lng": 13.0575,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            },
            {
                "@id": 2721,
                "id": 3468,
                "date": "2024-07-10T19:29:33.478279Z",
                "descr": null,
                "lat": 47.0612,
                "lng": 13.0577,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1581.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2604
            }
        ],
        "tblUser": 1
    },
    {
        "@id": 2722,
        "id": 203,
        "date": "2024-07-10T19:33:44.503868Z",
        "descr": "Vorderer Geißlkopf oder Hohe Geißel 2974mTrack Description",
        "name": "Vorderer Geißlkopf oder Hohe Geißel 2974m",
        "zoomCenterPoint": "lat:47.06115650001463,lng:13.057751000003808",
        "trackDistance": 14.871965284409585,
        "google_encoded_polyline": "esf~GijunAdIbAtDpFzFVrAv@jALhCvDjBCdBh@`N}L`DKdBmA|CLrAkBfDe@dJsI~M_S`KqCbIgHtFoJdBkOnD{I~GiBxEeItAc@IcC|AwBV}FdB?W}HbBqGhHkFlFuPxB{Ms@kFgH}HvG}DdJrBhKcAtCbC}@~G^hIvB|BGzD|BtCbL`CpAvFrKxFfF|NbDcBrBbDpJlGlEhGnFlCTjD`A_AH~BpA`@d@wDe@vDqAa@I_CaA~@UkDoFmCmEiGqJmGsBcDcDbBgF}NsKyFqAwFcLaC}BuCkCOqArA{AW{Al@mAlA[`@qAPFpEsCR}DsAgEhBwChEiHjFcBpGV|HeB?W|F}AvBHbCuAb@yEdI_HhBoDzIeBjOuFnJcIfHaKpC_N~ReJrIgDd@sAjB}CMeBlAaDJaN|LeBi@kBBiCwDkAMsAw@{FWuDqFgIk@",
        "tblgps": [
            {
                "@id": 2723,
                "id": 3469,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0612,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1581.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2724,
                "id": 3470,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0595,
                "lng": 13.0575,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2725,
                "id": 3471,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0586,
                "lng": 13.0563,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1582.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2726,
                "id": 3472,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0574,
                "lng": 13.0561,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2727,
                "id": 3473,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0569,
                "lng": 13.0559,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2728,
                "id": 3474,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0565,
                "lng": 13.0558,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2729,
                "id": 3475,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0559,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1588.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2730,
                "id": 3476,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0553,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1590.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2731,
                "id": 3477,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0548,
                "lng": 13.0547,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1592.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2732,
                "id": 3478,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0524,
                "lng": 13.0569,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1604.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2733,
                "id": 3479,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0516,
                "lng": 13.057,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1606.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2734,
                "id": 3480,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0511,
                "lng": 13.0574,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1608.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2735,
                "id": 3481,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0503,
                "lng": 13.0573,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2736,
                "id": 3482,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0499,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.41,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2737,
                "id": 3483,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.049,
                "lng": 13.058,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2738,
                "id": 3484,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0472,
                "lng": 13.0597,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2739,
                "id": 3485,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0448,
                "lng": 13.0629,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1621.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2740,
                "id": 3486,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0429,
                "lng": 13.0636,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1630.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2741,
                "id": 3487,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0413,
                "lng": 13.0651,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2742,
                "id": 3488,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0401,
                "lng": 13.067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1638.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2743,
                "id": 3489,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0395,
                "lng": 13.0696,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1662.82,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2744,
                "id": 3490,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0387,
                "lng": 13.0713,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1709.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2745,
                "id": 3491,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0372,
                "lng": 13.0719,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1772.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2746,
                "id": 3492,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0361,
                "lng": 13.0735,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2747,
                "id": 3493,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0357,
                "lng": 13.0737,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1880.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2748,
                "id": 3494,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0358,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1902.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2749,
                "id": 3495,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0353,
                "lng": 13.0749,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1937.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2750,
                "id": 3496,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0352,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1991.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2751,
                "id": 3497,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0347,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1999.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2752,
                "id": 3498,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0348,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2047.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2753,
                "id": 3499,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0343,
                "lng": 13.0792,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2112.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2754,
                "id": 3500,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0328,
                "lng": 13.0803,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2126.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2755,
                "id": 3501,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0316,
                "lng": 13.0832,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2165.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2756,
                "id": 3502,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.031,
                "lng": 13.0856,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2209.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2757,
                "id": 3503,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0313,
                "lng": 13.0867,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2239.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2758,
                "id": 3504,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0327,
                "lng": 13.0883,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2316.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2759,
                "id": 3505,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0313,
                "lng": 13.0893,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2380.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2760,
                "id": 3506,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0295,
                "lng": 13.0887,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2371.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2761,
                "id": 3507,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0276,
                "lng": 13.089,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2443.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2762,
                "id": 3508,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0268,
                "lng": 13.0884,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2425.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2763,
                "id": 3509,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0271,
                "lng": 13.0869,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2445.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2764,
                "id": 3510,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.027,
                "lng": 13.0853,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2432.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2765,
                "id": 3511,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0847,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2437.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2766,
                "id": 3512,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0837,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2442.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2767,
                "id": 3513,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.083,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2459.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2768,
                "id": 3514,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.0823,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2534.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2769,
                "id": 3515,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0233,
                "lng": 13.0811,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2601.39,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2770,
                "id": 3516,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.0798,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2657.15,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2771,
                "id": 3517,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0201,
                "lng": 13.0773,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2711.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2772,
                "id": 3518,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0193,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2731.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2773,
                "id": 3519,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.0769,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2752.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2774,
                "id": 3520,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0168,
                "lng": 13.0756,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2796.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2775,
                "id": 3521,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0158,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2831.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2776,
                "id": 3522,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.0736,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2879.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2777,
                "id": 3523,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0145,
                "lng": 13.0727,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2877.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2778,
                "id": 3524,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0142,
                "lng": 13.073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2905.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2779,
                "id": 3525,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0141,
                "lng": 13.0724,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2908.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2780,
                "id": 3526,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.0722,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2935.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2781,
                "id": 3527,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0135,
                "lng": 13.0731,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2972.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2782,
                "id": 3528,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.0722,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2935.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2783,
                "id": 3529,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0141,
                "lng": 13.0724,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2908.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2784,
                "id": 3530,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0142,
                "lng": 13.073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2905.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2785,
                "id": 3531,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0145,
                "lng": 13.0727,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2877.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2786,
                "id": 3532,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.0736,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2879.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2787,
                "id": 3533,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0158,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2831.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2788,
                "id": 3534,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0168,
                "lng": 13.0756,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2796.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2789,
                "id": 3535,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.0769,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2752.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2790,
                "id": 3536,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0193,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2731.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2791,
                "id": 3537,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0201,
                "lng": 13.0773,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2711.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2792,
                "id": 3538,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.0798,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2657.15,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2793,
                "id": 3539,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0233,
                "lng": 13.0811,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2601.39,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2794,
                "id": 3540,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.0823,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2534.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2795,
                "id": 3541,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.083,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2459.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2796,
                "id": 3542,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.0837,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2442.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2797,
                "id": 3543,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0271,
                "lng": 13.0838,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2433.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2798,
                "id": 3544,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0275,
                "lng": 13.0834,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2419.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2799,
                "id": 3545,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.028,
                "lng": 13.0835,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2390.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2800,
                "id": 3546,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0284,
                "lng": 13.0833,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2361.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2801,
                "id": 3547,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0288,
                "lng": 13.0829,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2334.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2802,
                "id": 3548,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.029,
                "lng": 13.0827,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2321.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2803,
                "id": 3549,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0294,
                "lng": 13.0826,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2297.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2804,
                "id": 3550,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0293,
                "lng": 13.0816,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2271.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2805,
                "id": 3551,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0301,
                "lng": 13.0815,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2216.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2806,
                "id": 3552,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.031,
                "lng": 13.0819,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2165.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2807,
                "id": 3553,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.032,
                "lng": 13.0813,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2135.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2808,
                "id": 3554,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0328,
                "lng": 13.0803,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2126.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2809,
                "id": 3555,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0343,
                "lng": 13.0792,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2112.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2810,
                "id": 3556,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0348,
                "lng": 13.0778,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2047.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2811,
                "id": 3557,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0347,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1999.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2812,
                "id": 3558,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0352,
                "lng": 13.0762,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1991.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2813,
                "id": 3559,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0353,
                "lng": 13.0749,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1937.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2814,
                "id": 3560,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0358,
                "lng": 13.0743,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1902.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2815,
                "id": 3561,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0357,
                "lng": 13.0737,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1880.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2816,
                "id": 3562,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0361,
                "lng": 13.0735,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2817,
                "id": 3563,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0372,
                "lng": 13.0719,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1772.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2818,
                "id": 3564,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0387,
                "lng": 13.0713,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1709.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2819,
                "id": 3565,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0395,
                "lng": 13.0696,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1662.82,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2820,
                "id": 3566,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0401,
                "lng": 13.067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1638.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2821,
                "id": 3567,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0413,
                "lng": 13.0651,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2822,
                "id": 3568,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0429,
                "lng": 13.0636,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1630.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2823,
                "id": 3569,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0448,
                "lng": 13.0629,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1621.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2824,
                "id": 3570,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0472,
                "lng": 13.0597,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2825,
                "id": 3571,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.049,
                "lng": 13.058,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2826,
                "id": 3572,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0499,
                "lng": 13.0578,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.41,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2827,
                "id": 3573,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0503,
                "lng": 13.0573,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1607.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2828,
                "id": 3574,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0511,
                "lng": 13.0574,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1608.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2829,
                "id": 3575,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0516,
                "lng": 13.057,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1606.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2830,
                "id": 3576,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0524,
                "lng": 13.0569,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1604.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2831,
                "id": 3577,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0548,
                "lng": 13.0547,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1592.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2832,
                "id": 3578,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0553,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1590.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2833,
                "id": 3579,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0559,
                "lng": 13.0549,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1588.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2834,
                "id": 3580,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0565,
                "lng": 13.0558,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2835,
                "id": 3581,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0569,
                "lng": 13.0559,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2836,
                "id": 3582,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0574,
                "lng": 13.0561,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1583.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2837,
                "id": 3583,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0586,
                "lng": 13.0563,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1582.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2838,
                "id": 3584,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0595,
                "lng": 13.0575,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            },
            {
                "@id": 2839,
                "id": 3585,
                "date": "2024-07-10T19:33:44.503868Z",
                "descr": null,
                "lat": 47.0612,
                "lng": 13.0577,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1581.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2722
            }
        ],
        "tblUser": 1
    },
    {
        "@id": 2840,
        "id": 204,
        "date": "2024-07-10T19:43:02.459504Z",
        "descr": "Rameterspitz oder Romatenspitz 2696mTrack Description",
        "name": "Rameterspitz oder Romatenspitz 2696m",
        "zoomCenterPoint": "lat:47.00098400000015,lng:13.132766999999552",
        "trackDistance": 18.485583513710154,
        "google_encoded_polyline": "c{z}Gw~coAs@DgAtSEjP^vCdBnC?l@m@?eBiAeA}AOcEw@}Ck@oIYG@~Jj@rEe@vEZbCGfCvF`IX~A{@|EPxAOzA^xCEzCnArDb@dDp@fBMd@_@EcBqFsAuBaBwAk@iCSKM^Z|BFfCnArCF`A[`DLlAhD~DnA`ANf@_@LaAaAc@APn@nCdCHb@UF_BmAiB}@gAyA[FLhAjBvD{AdMOHAq@a@[a@fAy@Gm@[]l@k@o@k@A{BbAuA`CiAJuBjB_BnDoAhA]z@IlAXlCOx@a@Rc@QWqBUq@YVIo@gA\\g@_@}ANMk@o@g@cBUkAmCV}CKo@}@M_BZ_Ab@w@D_Bp@gAfAw@nAg@HS\\]LoA}AoAe@qA}AoBcJ_AmCs@[Yc@c@v@c@NuCmDs@CWwAqAwD{AgB{@g@{BkC}BUgAyAmAOcFJ}AgA_BqC]wAFkBkBQCm@MOLs@s@uBTgC{@sBJ_BCkA_Ay@MkA}@oAUcBk@kA_@yAI_BTsBXcANkB?o@]o@}AKK_@mCbAC_AaCbAY`@]?Wq@?_DRaCcA{@cAVm@hAGhCX_AC{@TGJq@PTPWLb@Xa@Vb@CfAl@lACpAZCJT`@U^z@b@]`@^z@K\\^^[`@NXUPP`@ELZ|@?Nf@ZFJb@h@MBl@d@ALTZWA`@RAUfA`@ROj@Nv@Gh@W@Id@e@BYj@UDAd@X^ULDn@Mb@d@RvB[L\\[dARZcAl@Ep@XN@|@TJa@|ATh@h@FFh@l@DHt@\\t@O\\Df@]F?l@Yl@Jr@Of@PR^AKzAh@nH`@e@H|B`@s@LnAX}@JjA`@k@^jCfBdFZm@?kCXmCm@eD\\RTSV`@\\WR^f@QP`@ZUXd@\\INlAZRD|@ZJU~@RVH`BMbBBjAd@QCbAd@MWlBCdCp@c@f@@z@pF^nEzApHEvAZ@B`@\\B@f@LFF^VEJd@n@{@j@HC`@ZNg@~@`AZg@n@pA\\W^LZOx@`@GHx@`@LGbAt@vAb@pAFtB^~@No@^TDg@l@`AV~BLg@d@BAs@^C@g@ZBRe@XLf@a@Xd@^]VTTk@^R^Ip@`@f@U`@LZQd@ZPc@O{@ZYBgAf@z@Tk@^FBg@d@FTU?g@rBeAvC_@lAm@An@x@A?jAnDfClAX`D?a@]rAi@Re@|@ClD{CzDuB^z@j@Mn@Rj@{@tBqE~ByAVkAlAZk@cAPGaBeB{@e@AYl@R~BDzAd@sAyBmC}BJkBe@sAzAgMmB{DOcA^KbA|AlBz@xAlAVGGa@qCgCOo@b@?`A`A\\MKe@qAaAkD_EMoAZ}CEeAqAqCGeC[_CL_@TLj@jC|AtArApBfBtF^@Ja@q@gBc@gDmAsD@yC[{CN}AQsAz@_FYcByF_IDeCYcCd@{Ek@mE?eKTDn@pIr@|CNdEfA|AdBhAj@@?o@cBqC_@sCDoPfAoSp@I",
        "tblgps": [
            {
                "@id": 2841,
                "id": 3586,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1328,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1288.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2842,
                "id": 3587,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1327,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1289.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2843,
                "id": 3588,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1294,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1312.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2844,
                "id": 3589,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1266,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1336.32,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2845,
                "id": 3590,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1259,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1342.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2846,
                "id": 3591,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1352.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2847,
                "id": 3592,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1354.12,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2848,
                "id": 3593,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1358.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2849,
                "id": 3594,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1363.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2850,
                "id": 3595,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1258,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1368.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2851,
                "id": 3596,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1267,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1375.52,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2852,
                "id": 3597,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1275,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1381.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2853,
                "id": 3598,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1292,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1392.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2854,
                "id": 3599,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0028,
                "lng": 13.1293,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1393.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2855,
                "id": 3600,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0028,
                "lng": 13.1273,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1408.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2856,
                "id": 3601,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.1263,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1414.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2857,
                "id": 3602,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0027,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1422.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2858,
                "id": 3603,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1427.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2859,
                "id": 3604,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1239,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1427.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2860,
                "id": 3605,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1223,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1448.03,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2861,
                "id": 3606,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1218,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1452.48,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2862,
                "id": 3607,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1207,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1463.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2863,
                "id": 3608,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1202,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1467.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2864,
                "id": 3609,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1197,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1470.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2865,
                "id": 3610,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1477.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2866,
                "id": 3611,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1182,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1484.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2867,
                "id": 3612,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1173,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1489.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2868,
                "id": 3613,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1165,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1497.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2869,
                "id": 3614,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1159,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1500.87,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2870,
                "id": 3615,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1502.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2871,
                "id": 3616,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1505.6,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2872,
                "id": 3617,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.117,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1513.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2873,
                "id": 3618,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1176,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1521.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2874,
                "id": 3619,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0022,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1526.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2875,
                "id": 3620,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1187,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1531.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2876,
                "id": 3621,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.1188,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1533.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2877,
                "id": 3622,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1536.75,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2878,
                "id": 3623,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1539.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2879,
                "id": 3624,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1173,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1547.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2880,
                "id": 3625,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1166,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1555.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2881,
                "id": 3626,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1162,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1559.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2882,
                "id": 3627,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1154,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1565.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2883,
                "id": 3628,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1569.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2884,
                "id": 3629,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1141,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2885,
                "id": 3630,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1137,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1587.32,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2886,
                "id": 3631,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1136,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1589.32,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2887,
                "id": 3632,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0009,
                "lng": 13.1135,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1594.6,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2888,
                "id": 3633,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1596.09,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2889,
                "id": 3634,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1599.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2890,
                "id": 3635,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.1136,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1602.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2891,
                "id": 3636,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1129,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1610.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2892,
                "id": 3637,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0005,
                "lng": 13.1127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2893,
                "id": 3638,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1614.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2894,
                "id": 3639,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0011,
                "lng": 13.1131,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1620.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2895,
                "id": 3640,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1134,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1626.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2896,
                "id": 3641,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1630.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2897,
                "id": 3642,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0022,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2898,
                "id": 3643,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1134,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1640.21,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2899,
                "id": 3644,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1648.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2900,
                "id": 3645,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1669.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2901,
                "id": 3646,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1671.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2902,
                "id": 3647,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1672.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2903,
                "id": 3648,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0023,
                "lng": 13.1106,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1675.37,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2904,
                "id": 3649,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1685.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2905,
                "id": 3650,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0027,
                "lng": 13.1103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1684.12,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2906,
                "id": 3651,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.003,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1693.45,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2907,
                "id": 3652,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0031,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1702.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2908,
                "id": 3653,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0033,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1709.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2909,
                "id": 3654,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0035,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1718.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2910,
                "id": 3655,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0042,
                "lng": 13.1101,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1740.9,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2911,
                "id": 3656,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0046,
                "lng": 13.1094,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1765.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2912,
                "id": 3657,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.005,
                "lng": 13.1094,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1772.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2913,
                "id": 3658,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0056,
                "lng": 13.1088,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1788.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2914,
                "id": 3659,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.006,
                "lng": 13.108,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1808.95,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2915,
                "id": 3660,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0064,
                "lng": 13.1076,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1818.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2916,
                "id": 3661,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0066,
                "lng": 13.1073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1819.07,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2917,
                "id": 3662,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0066,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1821.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2918,
                "id": 3663,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0065,
                "lng": 13.1062,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1832.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2919,
                "id": 3664,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0066,
                "lng": 13.1059,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1839.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2920,
                "id": 3665,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0068,
                "lng": 13.1058,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1839.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2921,
                "id": 3666,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0069,
                "lng": 13.1059,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1844.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2922,
                "id": 3667,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0071,
                "lng": 13.1065,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1855.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2923,
                "id": 3668,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0072,
                "lng": 13.1067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1863.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2924,
                "id": 3669,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0073,
                "lng": 13.1066,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1868.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2925,
                "id": 3670,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0073,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1873.07,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2926,
                "id": 3671,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0077,
                "lng": 13.1067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1887.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2927,
                "id": 3672,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0079,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1894.75,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2928,
                "id": 3673,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0084,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1907.6,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2929,
                "id": 3674,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0084,
                "lng": 13.107,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1911.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2930,
                "id": 3675,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0087,
                "lng": 13.1072,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1917.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2931,
                "id": 3676,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0092,
                "lng": 13.1073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1933.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2932,
                "id": 3677,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0096,
                "lng": 13.108,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1948.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2933,
                "id": 3678,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0094,
                "lng": 13.1088,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1966.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2934,
                "id": 3679,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0095,
                "lng": 13.109,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1968.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2935,
                "id": 3680,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0098,
                "lng": 13.1091,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1974.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2936,
                "id": 3681,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0103,
                "lng": 13.109,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1988.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2937,
                "id": 3682,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0106,
                "lng": 13.1088,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1994.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2938,
                "id": 3683,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0109,
                "lng": 13.1087,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1995.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2939,
                "id": 3684,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0114,
                "lng": 13.1085,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2002.01,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2940,
                "id": 3685,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0117,
                "lng": 13.1081,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2008.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2941,
                "id": 3686,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.012,
                "lng": 13.1077,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2019.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2942,
                "id": 3687,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0122,
                "lng": 13.1077,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2022.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2943,
                "id": 3688,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0123,
                "lng": 13.1075,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2025.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2944,
                "id": 3689,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0125,
                "lng": 13.1075,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2033.94,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2945,
                "id": 3690,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0129,
                "lng": 13.1079,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2044.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2946,
                "id": 3691,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0133,
                "lng": 13.1081,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2048.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2947,
                "id": 3692,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.1086,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2052.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2948,
                "id": 3693,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0142,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2070.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2949,
                "id": 3694,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.1111,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2090.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2950,
                "id": 3695,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0148,
                "lng": 13.1112,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2099.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2951,
                "id": 3696,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0149,
                "lng": 13.1114,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2105.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2952,
                "id": 3697,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0151,
                "lng": 13.1111,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2111.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2953,
                "id": 3698,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0153,
                "lng": 13.111,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2115.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2954,
                "id": 3699,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0161,
                "lng": 13.1119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2127.21,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2955,
                "id": 3700,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0163,
                "lng": 13.1119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2130.6,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2956,
                "id": 3701,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0164,
                "lng": 13.1124,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2134.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2957,
                "id": 3702,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0168,
                "lng": 13.1133,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2144.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2958,
                "id": 3703,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0173,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2147.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2959,
                "id": 3704,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0176,
                "lng": 13.114,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2148.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2960,
                "id": 3705,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0182,
                "lng": 13.1147,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2158.54,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2961,
                "id": 3706,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0189,
                "lng": 13.1148,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2173.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2962,
                "id": 3707,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0192,
                "lng": 13.1153,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2190.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2963,
                "id": 3708,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0196,
                "lng": 13.1153,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2202.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2964,
                "id": 3709,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0207,
                "lng": 13.1153,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2232.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2965,
                "id": 3710,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.1157,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2248.24,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2966,
                "id": 3711,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0217,
                "lng": 13.1164,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2272.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2967,
                "id": 3712,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0219,
                "lng": 13.1168,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2283.03,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2968,
                "id": 3713,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0218,
                "lng": 13.1174,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2293.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2969,
                "id": 3714,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0223,
                "lng": 13.1175,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2316.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2970,
                "id": 3715,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1177,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2322.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2971,
                "id": 3716,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1178,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2327.87,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2972,
                "id": 3717,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2331.03,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2973,
                "id": 3718,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2346.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2974,
                "id": 3719,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0225,
                "lng": 13.1193,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2359.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2975,
                "id": 3720,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1199,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2374.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2976,
                "id": 3721,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1204,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2387.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2977,
                "id": 3722,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1207,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2393.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2978,
                "id": 3723,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.121,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2406.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2979,
                "id": 3724,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.1214,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2413.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2980,
                "id": 3725,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0235,
                "lng": 13.1218,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2426.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2981,
                "id": 3726,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0236,
                "lng": 13.1223,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2441.8,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2982,
                "id": 3727,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0238,
                "lng": 13.1227,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2452.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2983,
                "id": 3728,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.024,
                "lng": 13.1231,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2468.21,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2984,
                "id": 3729,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.024,
                "lng": 13.1236,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2476.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2985,
                "id": 3730,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0239,
                "lng": 13.1242,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2493.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2986,
                "id": 3731,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0238,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2502.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2987,
                "id": 3732,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.1251,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2516.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2988,
                "id": 3733,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0237,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2520.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2989,
                "id": 3734,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0238,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2530.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2990,
                "id": 3735,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0243,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2542.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2991,
                "id": 3736,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0244,
                "lng": 13.1258,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2547.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2992,
                "id": 3737,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0251,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2573.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2993,
                "id": 3738,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0251,
                "lng": 13.1257,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2578.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2994,
                "id": 3739,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2604.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2995,
                "id": 3740,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0259,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2609.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2996,
                "id": 3741,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.026,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2616.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2997,
                "id": 3742,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0262,
                "lng": 13.1255,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2625.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2998,
                "id": 3743,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0262,
                "lng": 13.1263,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2643.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 2999,
                "id": 3744,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0261,
                "lng": 13.1269,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2658.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3000,
                "id": 3745,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0264,
                "lng": 13.1272,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2673.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3001,
                "id": 3746,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0267,
                "lng": 13.1271,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2680.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3002,
                "id": 3747,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.027,
                "lng": 13.1268,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2691.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3003,
                "id": 3748,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.027,
                "lng": 13.1261,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2693.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3004,
                "id": 3749,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0269,
                "lng": 13.1264,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2689.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3005,
                "id": 3750,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0269,
                "lng": 13.1267,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2688.67,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3006,
                "id": 3751,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0268,
                "lng": 13.1267,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2683.77,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3007,
                "id": 3752,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0267,
                "lng": 13.127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2680.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3008,
                "id": 3753,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0266,
                "lng": 13.1269,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2675.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3009,
                "id": 3754,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0265,
                "lng": 13.127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2672.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3010,
                "id": 3755,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0265,
                "lng": 13.1268,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2669.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3011,
                "id": 3756,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0263,
                "lng": 13.127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2666.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3012,
                "id": 3757,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0262,
                "lng": 13.1268,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2660.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3013,
                "id": 3758,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0263,
                "lng": 13.1264,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2653.92,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3014,
                "id": 3759,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.026,
                "lng": 13.126,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2628.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3015,
                "id": 3760,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.026,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2619.98,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3016,
                "id": 3761,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0259,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2613.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3017,
                "id": 3762,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0258,
                "lng": 13.1255,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2609.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3018,
                "id": 3763,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0257,
                "lng": 13.1256,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2603.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3019,
                "id": 3764,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0255,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2591.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3020,
                "id": 3765,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0253,
                "lng": 13.1255,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2585.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3021,
                "id": 3766,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0252,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2575.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3022,
                "id": 3767,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0249,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2561.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3023,
                "id": 3768,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0247,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2552.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3024,
                "id": 3769,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0245,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2547.94,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3025,
                "id": 3770,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0244,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2540.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3026,
                "id": 3771,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0243,
                "lng": 13.1254,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2536.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3027,
                "id": 3772,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0242,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2533.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3028,
                "id": 3773,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.024,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2529.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3029,
                "id": 3774,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0239,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2524.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3030,
                "id": 3775,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0236,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2516.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3031,
                "id": 3776,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0235,
                "lng": 13.125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2510.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3032,
                "id": 3777,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0234,
                "lng": 13.125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2504.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3033,
                "id": 3778,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0233,
                "lng": 13.1248,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2498.77,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3034,
                "id": 3779,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2495.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3035,
                "id": 3780,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1246,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2490.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3036,
                "id": 3781,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0229,
                "lng": 13.1246,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2481.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3037,
                "id": 3782,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2474.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3038,
                "id": 3783,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1246,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2473.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3039,
                "id": 3784,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2472.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3040,
                "id": 3785,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2472.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3041,
                "id": 3786,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1241,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2469.15,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3042,
                "id": 3787,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0225,
                "lng": 13.124,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2462.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3043,
                "id": 3788,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1238,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2460.77,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3044,
                "id": 3789,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1235,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2457.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3045,
                "id": 3790,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1233,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2457.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3046,
                "id": 3791,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1233,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2454.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3047,
                "id": 3792,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1231,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2451.54,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3048,
                "id": 3793,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0229,
                "lng": 13.1231,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2448.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3049,
                "id": 3794,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1229,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2444.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3050,
                "id": 3795,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.1228,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2444.99,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3051,
                "id": 3796,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.1227,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2442.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3052,
                "id": 3797,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1225,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2439.37,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3053,
                "id": 3798,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.1224,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2437.54,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3054,
                "id": 3799,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0231,
                "lng": 13.1222,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2432.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3055,
                "id": 3800,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0232,
                "lng": 13.122,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2428.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3056,
                "id": 3801,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.023,
                "lng": 13.1219,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2424.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3057,
                "id": 3802,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1221,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2416.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3058,
                "id": 3803,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1219,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2412.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3059,
                "id": 3804,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0225,
                "lng": 13.1215,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2413.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3060,
                "id": 3805,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1214,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2410.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3061,
                "id": 3806,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.1212,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2407.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3062,
                "id": 3807,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0228,
                "lng": 13.1209,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2397.58,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3063,
                "id": 3808,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1209,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2393.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3064,
                "id": 3809,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1205,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2392.95,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3065,
                "id": 3810,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0225,
                "lng": 13.1205,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2391.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3066,
                "id": 3811,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0227,
                "lng": 13.12,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2378.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3067,
                "id": 3812,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0226,
                "lng": 13.1198,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2368.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3068,
                "id": 3813,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0224,
                "lng": 13.1198,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2363.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3069,
                "id": 3814,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0223,
                "lng": 13.1196,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2357.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3070,
                "id": 3815,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0221,
                "lng": 13.1195,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2352.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3071,
                "id": 3816,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.022,
                "lng": 13.1193,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2351.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3072,
                "id": 3817,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0219,
                "lng": 13.119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2350.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3073,
                "id": 3818,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.022,
                "lng": 13.1188,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2347.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3074,
                "id": 3819,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0219,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2342.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3075,
                "id": 3820,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0221,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2342.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3076,
                "id": 3821,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0221,
                "lng": 13.1184,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2336.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3077,
                "id": 3822,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0222,
                "lng": 13.1181,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2331.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3078,
                "id": 3823,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0222,
                "lng": 13.1179,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2325.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3079,
                "id": 3824,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0222,
                "lng": 13.1177,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2318.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3080,
                "id": 3825,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0222,
                "lng": 13.1176,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2312.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3081,
                "id": 3826,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.022,
                "lng": 13.1176,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2307.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3082,
                "id": 3827,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0221,
                "lng": 13.1171,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2298.45,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3083,
                "id": 3828,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0218,
                "lng": 13.1156,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2281.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3084,
                "id": 3829,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0217,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2272.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3085,
                "id": 3830,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0216,
                "lng": 13.1152,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2269.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3086,
                "id": 3831,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0215,
                "lng": 13.1154,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2258.98,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3087,
                "id": 3832,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0214,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2256.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3088,
                "id": 3833,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0213,
                "lng": 13.1153,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2249.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3089,
                "id": 3834,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0212,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2246.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3090,
                "id": 3835,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.021,
                "lng": 13.1152,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2241.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3091,
                "id": 3836,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0209,
                "lng": 13.1145,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2236.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3092,
                "id": 3837,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0204,
                "lng": 13.1133,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2227.91,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3093,
                "id": 3838,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0202,
                "lng": 13.1135,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2225.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3094,
                "id": 3839,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0202,
                "lng": 13.1142,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2222.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3095,
                "id": 3840,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0201,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2216.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3096,
                "id": 3841,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0203,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2213.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3097,
                "id": 3842,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0202,
                "lng": 13.1157,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2211.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3098,
                "id": 3843,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.02,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2210.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3099,
                "id": 3844,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0199,
                "lng": 13.1156,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2206.94,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3100,
                "id": 3845,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0198,
                "lng": 13.1157,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2206.23,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3101,
                "id": 3846,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0197,
                "lng": 13.1156,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2201.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3102,
                "id": 3847,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0195,
                "lng": 13.1157,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2199.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3103,
                "id": 3848,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0194,
                "lng": 13.1155,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2194.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3104,
                "id": 3849,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0192,
                "lng": 13.1156,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2195.37,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3105,
                "id": 3850,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0191,
                "lng": 13.1154,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2187.25,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3106,
                "id": 3851,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.019,
                "lng": 13.1155,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2185.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3107,
                "id": 3852,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0189,
                "lng": 13.1151,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2176.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3108,
                "id": 3853,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.115,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2170.03,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3109,
                "id": 3854,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.1147,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2166.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3110,
                "id": 3855,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0186,
                "lng": 13.1146,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2162.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3111,
                "id": 3856,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0187,
                "lng": 13.1143,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2161.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3112,
                "id": 3857,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0186,
                "lng": 13.1142,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2158.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3113,
                "id": 3858,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0185,
                "lng": 13.1137,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2156.75,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3114,
                "id": 3859,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0186,
                "lng": 13.1132,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2155.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3115,
                "id": 3860,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0186,
                "lng": 13.1128,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2153.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3116,
                "id": 3861,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0184,
                "lng": 13.1129,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2146.36,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3117,
                "id": 3862,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0184,
                "lng": 13.1125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2144.46,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3118,
                "id": 3863,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0182,
                "lng": 13.1126,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2140.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3119,
                "id": 3864,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0184,
                "lng": 13.1121,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2136.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3120,
                "id": 3865,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0184,
                "lng": 13.1114,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2130.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3121,
                "id": 3866,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0181,
                "lng": 13.1116,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2128.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3122,
                "id": 3867,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0179,
                "lng": 13.1116,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2125.69,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3123,
                "id": 3868,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0176,
                "lng": 13.1104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2123.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3124,
                "id": 3869,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0175,
                "lng": 13.1093,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2118.41,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3125,
                "id": 3870,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.017,
                "lng": 13.1078,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2111.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3126,
                "id": 3871,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.017,
                "lng": 13.1074,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2107.74,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3127,
                "id": 3872,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0169,
                "lng": 13.1073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2104.64,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3128,
                "id": 3873,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0169,
                "lng": 13.1072,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2103.95,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3129,
                "id": 3874,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0167,
                "lng": 13.1071,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2099.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3130,
                "id": 3875,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0167,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2096.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3131,
                "id": 3876,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0166,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2093.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3132,
                "id": 3877,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0166,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2089.65,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3133,
                "id": 3878,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0165,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2086.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3134,
                "id": 3879,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0164,
                "lng": 13.1066,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2083.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3135,
                "id": 3880,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0162,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2082.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3136,
                "id": 3881,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.016,
                "lng": 13.1068,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2081.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3137,
                "id": 3882,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.016,
                "lng": 13.1067,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2077.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3138,
                "id": 3883,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0158,
                "lng": 13.1066,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2070.95,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3139,
                "id": 3884,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.016,
                "lng": 13.1063,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2066.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3140,
                "id": 3885,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0157,
                "lng": 13.1061,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2063.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3141,
                "id": 3886,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0159,
                "lng": 13.1059,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2057.34,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3142,
                "id": 3887,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0155,
                "lng": 13.1057,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2056.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3143,
                "id": 3888,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0156,
                "lng": 13.1056,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2050.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3144,
                "id": 3889,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0155,
                "lng": 13.1054,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2047.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3145,
                "id": 3890,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0156,
                "lng": 13.1052,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2046.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3146,
                "id": 3891,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0155,
                "lng": 13.1052,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2041.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3147,
                "id": 3892,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0154,
                "lng": 13.1049,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2039.04,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3148,
                "id": 3893,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0152,
                "lng": 13.1048,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2034.18,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3149,
                "id": 3894,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0153,
                "lng": 13.1045,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2034.26,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3150,
                "id": 3895,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.015,
                "lng": 13.104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2029.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3151,
                "id": 3896,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0148,
                "lng": 13.1036,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2023.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3152,
                "id": 3897,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0148,
                "lng": 13.1031,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2022.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3153,
                "id": 3898,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.1027,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2020.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3154,
                "id": 3899,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0146,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2012.1,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3155,
                "id": 3900,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0144,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 2005.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3156,
                "id": 3901,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0144,
                "lng": 13.1031,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1999.42,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3157,
                "id": 3902,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0141,
                "lng": 13.1027,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1993.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3158,
                "id": 3903,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.014,
                "lng": 13.1021,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1986.01,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3159,
                "id": 3904,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0139,
                "lng": 13.1023,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1980.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3160,
                "id": 3905,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0137,
                "lng": 13.1023,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1975.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3161,
                "id": 3906,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0138,
                "lng": 13.1025,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1972.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3162,
                "id": 3907,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0136,
                "lng": 13.1025,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1966.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3163,
                "id": 3908,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0136,
                "lng": 13.1028,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1964.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3164,
                "id": 3909,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0135,
                "lng": 13.1027,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1960.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3165,
                "id": 3910,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0134,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1956.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3166,
                "id": 3911,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0132,
                "lng": 13.1028,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1951.52,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3167,
                "id": 3912,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.013,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1947.02,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3168,
                "id": 3913,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0129,
                "lng": 13.1028,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1941.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3169,
                "id": 3914,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0127,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1934.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3170,
                "id": 3915,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0126,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1932.47,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3171,
                "id": 3916,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0125,
                "lng": 13.1031,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1924.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3172,
                "id": 3917,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0123,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1919.98,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3173,
                "id": 3918,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0122,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1912.62,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3174,
                "id": 3919,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0119,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1910.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3175,
                "id": 3920,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0117,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1898.09,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3176,
                "id": 3921,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0116,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1892.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3177,
                "id": 3922,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0114,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1885.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3178,
                "id": 3923,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0112,
                "lng": 13.1029,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1879.4,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3179,
                "id": 3924,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0111,
                "lng": 13.103,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1871.53,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3180,
                "id": 3925,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0112,
                "lng": 13.1033,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1869.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3181,
                "id": 3926,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0111,
                "lng": 13.1035,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1865.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3182,
                "id": 3927,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0111,
                "lng": 13.1038,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1862.5,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3183,
                "id": 3928,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0109,
                "lng": 13.1035,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1860.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3184,
                "id": 3929,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0108,
                "lng": 13.1037,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1857.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3185,
                "id": 3930,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0106,
                "lng": 13.1037,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.64,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3186,
                "id": 3931,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0106,
                "lng": 13.1039,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1853.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3187,
                "id": 3932,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0104,
                "lng": 13.1039,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1849.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3188,
                "id": 3933,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0103,
                "lng": 13.104,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1848.38,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3189,
                "id": 3934,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0103,
                "lng": 13.1042,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1848.76,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3190,
                "id": 3935,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0097,
                "lng": 13.1045,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1844.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3191,
                "id": 3936,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0089,
                "lng": 13.1047,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1838.17,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3192,
                "id": 3937,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0085,
                "lng": 13.1049,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1835.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3193,
                "id": 3938,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0085,
                "lng": 13.1047,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1830.97,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3194,
                "id": 3939,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0083,
                "lng": 13.1047,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1825.98,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3195,
                "id": 3940,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0083,
                "lng": 13.1043,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1823.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3196,
                "id": 3941,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0074,
                "lng": 13.1036,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1816.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3197,
                "id": 3942,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.007,
                "lng": 13.1035,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1815.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3198,
                "id": 3943,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0062,
                "lng": 13.1035,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1810.32,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3199,
                "id": 3944,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0064,
                "lng": 13.1036,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1808.86,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3200,
                "id": 3945,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0059,
                "lng": 13.1039,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1802.94,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3201,
                "id": 3946,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0058,
                "lng": 13.1041,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1803.73,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3202,
                "id": 3947,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0055,
                "lng": 13.1041,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1797.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3203,
                "id": 3948,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0046,
                "lng": 13.1048,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1779.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3204,
                "id": 3949,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0037,
                "lng": 13.1054,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1763.49,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3205,
                "id": 3950,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0036,
                "lng": 13.1051,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1759.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3206,
                "id": 3951,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0033,
                "lng": 13.1052,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1759.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3207,
                "id": 3952,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0031,
                "lng": 13.1051,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1758.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3208,
                "id": 3953,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0029,
                "lng": 13.1054,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1760.21,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3209,
                "id": 3954,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0023,
                "lng": 13.1065,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1757.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3210,
                "id": 3955,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1069,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1748.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3211,
                "id": 3956,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1073,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1747.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3212,
                "id": 3957,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0011,
                "lng": 13.1072,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1742.93,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3213,
                "id": 3958,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1075,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1735.81,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3214,
                "id": 3959,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.1075,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1730.89,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3215,
                "id": 3960,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.108,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1723.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3216,
                "id": 3961,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1082,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1721.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3217,
                "id": 3962,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1084,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1718.14,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3218,
                "id": 3963,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0018,
                "lng": 13.1083,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1717.08,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3219,
                "id": 3964,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1082,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1705.85,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3220,
                "id": 3965,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.108,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1698.96,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3221,
                "id": 3966,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0011,
                "lng": 13.1087,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1690.31,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3222,
                "id": 3967,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0019,
                "lng": 13.1093,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1677.84,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3223,
                "id": 3968,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0018,
                "lng": 13.1098,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1671.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3224,
                "id": 3969,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1102,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1669.64,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3225,
                "id": 3970,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1125,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1648.3,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3226,
                "id": 3971,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1135,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1638.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3227,
                "id": 3972,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0022,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1633.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3228,
                "id": 3973,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1139,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1629.68,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3229,
                "id": 3974,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1134,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1626.16,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3230,
                "id": 3975,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0011,
                "lng": 13.1131,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1619.71,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3231,
                "id": 3976,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1614.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3232,
                "id": 3977,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0005,
                "lng": 13.1127,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1611.73,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3233,
                "id": 3978,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1129,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1609.88,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3234,
                "id": 3979,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.1136,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1602.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3235,
                "id": 3980,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1598.7,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3236,
                "id": 3981,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1595.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3237,
                "id": 3982,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0009,
                "lng": 13.1135,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1593.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3238,
                "id": 3983,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0007,
                "lng": 13.1136,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1589.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3239,
                "id": 3984,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1138,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1587.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3240,
                "id": 3985,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1141,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1584.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3241,
                "id": 3986,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1151,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1569.28,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3242,
                "id": 3987,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1154,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1565.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3243,
                "id": 3988,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1162,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1559.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3244,
                "id": 3989,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.002,
                "lng": 13.1166,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1555.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3245,
                "id": 3990,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1173,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1547.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3246,
                "id": 3991,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1540.01,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3247,
                "id": 3992,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1186,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1536.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3248,
                "id": 3993,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.1188,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1533.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3249,
                "id": 3994,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1187,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1531.56,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3250,
                "id": 3995,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0022,
                "lng": 13.118,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1526.64,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3251,
                "id": 3996,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1176,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1521.45,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3252,
                "id": 3997,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0013,
                "lng": 13.117,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1513.78,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3253,
                "id": 3998,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1505.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3254,
                "id": 3999,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1158,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1502.59,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3255,
                "id": 4000,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0006,
                "lng": 13.1159,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1500.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3256,
                "id": 4001,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0008,
                "lng": 13.1165,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1497.57,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3257,
                "id": 4002,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1173,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1489.51,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3258,
                "id": 4003,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1182,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1483.27,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3259,
                "id": 4004,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.119,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1477.72,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3260,
                "id": 4005,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1198,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1470.2,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3261,
                "id": 4006,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1202,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1466.79,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3262,
                "id": 4007,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1206,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1463.61,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3263,
                "id": 4008,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1218,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1452.05,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3264,
                "id": 4009,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0014,
                "lng": 13.1223,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1447.44,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3265,
                "id": 4010,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1239,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1427.63,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3266,
                "id": 4011,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1245,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1427.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3267,
                "id": 4012,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0027,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1422.13,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3268,
                "id": 4013,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0025,
                "lng": 13.1263,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1414.66,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3269,
                "id": 4014,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0028,
                "lng": 13.1273,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1408.43,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3270,
                "id": 4015,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0028,
                "lng": 13.1293,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1393.22,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3271,
                "id": 4016,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0026,
                "lng": 13.1293,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1392.35,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3272,
                "id": 4017,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0024,
                "lng": 13.1276,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1381.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3273,
                "id": 4018,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1268,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1376.07,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3274,
                "id": 4019,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0021,
                "lng": 13.1258,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1369.33,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3275,
                "id": 4020,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0017,
                "lng": 13.1253,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1363.83,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3276,
                "id": 4021,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1358.55,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3277,
                "id": 4022,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1249,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1354.19,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3278,
                "id": 4023,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1252,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1352.48,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3279,
                "id": 4024,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0015,
                "lng": 13.1259,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1342.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3280,
                "id": 4025,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1266,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1336.0,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3281,
                "id": 4026,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0016,
                "lng": 13.1294,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1312.11,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3282,
                "id": 4027,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.0012,
                "lng": 13.1327,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1290.06,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            },
            {
                "@id": 3283,
                "id": 4028,
                "date": "2024-07-10T19:43:02.459504Z",
                "descr": null,
                "lat": 47.001,
                "lng": 13.1328,
                "bearing": 0.0,
                "accuracy": 0.0,
                "altitude": 1288.29,
                "verticalAccuracy": 0.0,
                "speedAccuracy": 0.0,
                "speed": 0.0,
                "provider": null,
                "deviceID": null,
                "type": null,
                "tbltrack": 2840
            }
        ],
        "tblUser": 1
    }
],
"appInstall": "[webuser]",
"tblTracks": [
    2,
    120,
    564,
    682,
    800,
    1244,
    1362,
    1480,
    1924,
    2042,
    2160,
    2604,
    2722,
    2840
]
};
 const initialState = {
  status: "idle",
  user: user,
  error: null
};

function AuthProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
   

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {props.children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}
  function removeTrack(trackidx,dispatch)
 {
    try {
      
        console.debug(usercurr);
        console.debug(dispatch);
     // delete user.tbltracks[trackidx]
          /* dispatch({ 
            status: "resolved" ,
            user:JSON.stringify(user),
            error:null 
                    }); */
        
        } catch (error) {
         // dispatch({ status: "rejected", error, user:null });
        }
      

 }
function doLogin(user, dispatch) {
  try {
  
  console.debug(user);
  console.debug(dispatch);

    dispatch({ 
      status: "resolved" ,
      user:JSON.stringify(user),
      error:null 
              });
  
  } catch (error) {
    dispatch({ status: "rejected", error, user:null });
  }
}

function doLogout(dispatch) {
  try {
    dispatch({initialState})
  } catch (error) {
    dispatch({ status: "rejected", error: error, user:null });
  }
 
  
//navigate('/home');
  
}

export { AuthProvider, useAuthState, useAuthDispatch, doLogin, doLogout,removeTrack };