
import React from 'react';
import  { useState } from "react";
// Import React FilePond
import { FilePond , registerPlugin } from "react-filepond";
import  setOptions  from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {getMultipleFileUploadUrl, getRestAPiUrl} from "../Utils.js"
// import Form from 'react-bootstrap';
// import InputGroup from 'react-bootstrap';
import {InputGroup,FloatingLabel,Form} from 'react-bootstrap';


// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function handleInit()
{}




function onerror()
{
}

function ondata()
{
}

function onload()
{
}

export default function FileMailUpload() {
  const [files, setFiles] = useState([]);
 /* 
  FilePond.setOptions({
    server: {
        url: 'http://192.168.0.100',
        process: {
            url: './process',
            method: 'POST',
            withCredentials: false,
            headers: {},
            timeout: 7000,
            onload: onload,
            onerror: onerror,
            ondata: ondata,
        },
    },
});
   setOptions({
    server: {
        url: getRestAPiUrl,
        process: {
            url: getMultipleFileUploadUrl,
            method: 'POST',
            withCredentials: false,
            headers: {},
            timeout: 7000,
            onload: null,
            onerror: null,
            ondata: null,
        },
    },
}); */
  
    return (
    
      <div>
        <h2>send mail with FileLink</h2> 

      <InputGroup className="mb-3">
     
          <FloatingLabel
            controlId="strRecipients"
            label="Email address"
            className="mb-3"   >
            <Form.Control type="email" placeholder="name@example.com" />
      </FloatingLabel>

      <FloatingLabel
            controlId="strSubject"
            label="Email Subject"
            className="mb-3"
        >
            <Form.Control type="text" placeholder="enter Subject here" />
      </FloatingLabel>
      </InputGroup>

  

      <InputGroup>
              <Form.Control type="textarea" as="strBody" aria-label="With textarea" />
      </InputGroup>
    
    
        <FilePond
          allowMultiple={true}
          instantUpload={false}
          files={files}
          formdata={formdata}
          maxFiles={5}
          allowReorder={true}
          server={getRestAPiUrl+"upload/mailupload/"}
          name="files"
          oninit={() => this.handleInit()}
          onupdatefiles={fileItems => {
            // Set currently active file objects to this.state
            this.setState({
              files: fileItems.map(fileItem => fileItem.file)
            });
          }}
                />
      
  
      </div>
    )
}
