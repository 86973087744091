import {useAuthState, useAuthDispatch, doLogout, doLogin } from "./context/AuthContext.js";
import React from 'react';
import  { useState , useRef } from "react";

import { FilePond , registerPlugin, File } from "react-filepond";
import  setOptions  from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import {getMultipleGPXFileUploadUrl, getRestAPiUrl} from "../Utils.js"
import {Container,Row,Col} from 'react-bootstrap'
// import {APIProvider, Map,useMap,Marker,AdvancedMarker} from '@vis.gl/react-google-maps';
//import {Polyline} from './gmapcomponents/polyline.tsx';
import Tracklist from './Tracklist.js'
import  MyMap from './Map.jsx'
import {useBeforeUnload} from "react-router-dom";

export default function MyTracks() {
  const fileRef= useRef('FilePond')
    const [files, setFiles] = useState([]);
    const {user}= useAuthState();
    const dispatch = useAuthDispatch();

  useBeforeUnload(
    React.useCallback(()=>{
      localStorage.setItem('user', JSON.stringify(user))
      console.debug('onbefore unload'+ JSON.stringify(user))
    },[user])
  )
    const ondata=(formData) => {
        console.log('ondata()');
        formData.append('userid', user.id);
        return formData;
      }
      
      const oninit=() => {
       console.log('FilePond oninit()');
       console.log(getMultipleGPXFileUploadUrl);
      }
      
      const onprocessfiles=() => {
        console.log('FilePond onprocessfiles()');
       }
       const onChangefiles=(files) => {
        console.log('FilePond onChangefiles()');
      
       }
       const onaddFile=(files) => {
        console.log('FilePond onAddFile()');
        
       }
      
     const setRegion=(decodedPath) =>(
        
        new google.maps.Polyline({
        path: decodedPath,
        levels: decodedLevels,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2,
        map: Map
     })
     
    )

    return (
      <Container>
            <Container>
                <FilePond
                ref={fileRef}
                oninit={()=>oninit()}
                // onprocessfiles={()=>onprocessfiles()}
                // onaddfile={()=>onaddFile()}
                updatefiles={setFiles}
                allowMultiple={true}
                maxFiles={3}
                name="files"
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                files={files}
                // onupdatefiles={(files) => onChangefiles(files)}
                server={{
                    process: {
                      withCredentials: false,
                      url: getMultipleGPXFileUploadUrl,
                  
                  ondata: (formData) => { // Before sending the files to the server, append size of the current file and the order of the file; order to delete all previous files if the current file is the first, even though the session ID didn't change, and size to validate against total size of files uploaded.
                  
                    formData.append('userid', user.id);
                    return formData;
                  },
                  onload: (response) => { // Once response is received, pushed new value to Final Form value variable, and populate through the onChange handler. 
                    //value.push(JSON.parse(response));
                  // onChange( value.map(file => file));
                  dispatch({ 
                    status: "resolved" ,
                    user: JSON.parse(response),
                    error:null 
                            });
                    return response;
                  },
                  onerror: (response) => { // If error transpires, add error to value with error message.
                    // value.push({"error": response})
                    // onChange( value.map(file => file) );
                    return response;
                  }
                }
              }
            }
       
      />
    
            </Container>
           <Container> <div id='myMapContainer'>
      
      <MyMap  tracks={user.tbltracks}>

      
      </MyMap>
 
</div>
</Container>
     
</Container>
    )  
}
