import React,{useState} from 'react';
import {googleLogout, useGoogleLogin} from '@react-oauth/google';
import axios from 'axios';
import {useNavigate } from 'react-router-dom';
import {getRestAPiUrl}  from '../Utils.js'
import {useAuthState, useAuthDispatch, doLogout, doLogin } from "./context/AuthContext.js"
import {Button,Container,Row,Col,FloatingLabel,Accordion,Form} from 'react-bootstrap';


function GLogin() {

    const [retypePassword, setRetypePassword] = useState('');
    const [password, setPassword] = useState('');
    const [strength, setStrength] = useState('');
    const [strengthColor, setStrengthColor] = useState('black');

    const [guser, setGuser] = useState();
    const {user}= useAuthState();
    const dispatch = useAuthDispatch();

    let navigate =useNavigate();
    let lUser;
    let guserPicture;

const login = useGoogleLogin(
   { 
     onSuccess: (codeResponse) =>       
        axios
            .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
                headers: {
                    Authorization: `Bearer ${codeResponse.access_token}`,
                    Accept: 'application/json'
                }
            })
            .then(async (res) => {
                 guserPicture =  res.data.picture;

                 lUser=await axios.get(getRestAPiUrl + 'user/getByEmail?email='+ res.data.email)
                 .then(response => {
                    
                           console.debug(response.data);
                           response.data.avatarPicture=guserPicture;
                        dispatch({ 
                            status: "resolved" ,
                            user:response.data,
                            error:null 
                                    });
                              });   
                          } ),
                             
             onError: (error) => console.log('Login Failed:', error)},
               
            )

            const guestlogin= () =>
                {                       
                    axios
                    .get(
                        getRestAPiUrl + 'user/getByEmail?email=guest@lazycodelabs.com'
                    )
                    .then(response => {
                        console.debug(response.data);
                                     
                        dispatch({ 
                            status: "resolved" ,
                            user:response.data,
                            error:null 
                                    });
                      
                    })
                    .catch(error => {
                        this.setState({
                            error: error,
                            loading: false
                        });
                    });
            }
                    
                  

    // log out function to log the user out of google and set the profile array to null
    const logOut = () => {
        dispatch({ 
            status: "idle" ,
            user:{},
            error:null 
                    });;
        googleLogout();
       
     

    };
    const forgotpwd = async (event) => {

        event.preventDefault(); // Prevent default submission
    }
    const register = async (event) => {

        event.preventDefault(); // Prevent default submission
    }

   

  function evaluatePasswordStrength(password) {
    let score = 0;

    if (!password) return '';

    // Check password length
    if (password.length > 8) score += 1;
    // Contains lowercase
    if (/[a-z]/.test(password)) score += 1;
    // Contains uppercase
    if (/[A-Z]/.test(password)) score += 1;
    // Contains numbers
    if (/\d/.test(password)) score += 1;
    // Contains special characters
    if (/[^A-Za-z0-9]/.test(password)) score += 1;

    switch (score) {
      case 0:
      case 1:
      case 2:
        setStrengthColor('red');
        return 'Weak';
      case 3:
        setStrengthColor('orange');
        return 'Medium';
      case 4:
      case 5:
        setStrengthColor('green');
        return 'Strong';
      default:
        return '';
    }
  }

  const handlePwChange = (event) => {
    const { value } = event.target;

    setPassword(value);
    setStrength(evaluatePasswordStrength(value));
  };
  const handleRetypePwChange = (event) => {
    const { value } = event.target;

    setRetypePassword(value);
   if (value==password)
    {document.getElementById('btnRegister').disabled=false;}
  };
    return (
        <Container fluid>
           <Row><Col> <h2> Login</h2></Col></Row>
           
            {user.loginid ? (
              <Row>
                <Col>       
                 
                    <h3>User Logged in</h3>
                    <img src={user.avatarPicture} alt={user.name +''+user.surname + 'logged in'}/> 
                    <p>ID:{user.id}</p>
                    <p>Name: {user.name}{user.surname}</p>
                    <p>Email Address: {user.loginid}</p>
                    
                    <Button onClick={logOut} variant="outline-light">Log out</Button>
                    </Col></Row>
            ) : (
                <>
                <Row className="justify-content-md-center">
                <Col>  
                <div className="d-grid gap-2">
                <Button onClick={(e) =>login()}  variant="outline-light" >Sign in with Google 🚀 </Button>
               {/*  </Col></Row>
                <Row><Col>   */}
                <Button onClick={(e) =>guestlogin()}  variant="outline-light"> proceed as guest🚀 </Button>
                </div>
                </Col></Row>
                
                
                
                
  
    <Accordion defaultActiveKey="0" >
      <Accordion.Item eventKey="0">
        <Accordion.Header>Forgot password</Accordion.Header>
        <Accordion.Body>
        <Form onSubmit={forgotpwd} className="was-validated">
      <Row>
             
              <Col>
              <div className="d-grid gap-2">
                    <FloatingLabel
                        controlId="email"
                        name="email"
                        label="email"  >

                        <Form.Control type="text" placeholder="...enter your email here" name="email" required  />
                  </FloatingLabel>
                        
                                 <Button variant='outline-light' type="submit"  >recover password</Button>
                                 </div>
                         </Col>
                  </Row>
             
                  </Form>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Register</Accordion.Header>
        <Accordion.Body>
        <Form onSubmit={register} className="was-validated">
      <Row>
              <Col>
              <div className="d-grid gap-2">                 
              <FloatingLabel
            controlId="floatingInput"
            label="email address"  >
            <Form.Control type="email" required placeholder="name@example.com" />
      </FloatingLabel>

      <FloatingLabel controlId="floatingPassword" label="password">
            <Form.Control type="password" placeholder="password" required value={password}  onChange={handlePwChange}/>
      </FloatingLabel>
     
              
                    <FloatingLabel controlId="floatingPasswordretype" label="retype Password">
            <Form.Control type="password" placeholder="enter password" required value={retypePassword}  onChange={handleRetypePwChange}/>
      </FloatingLabel>
   
                
      <small>
        Password strength:{' '}
        <span
          style={{
            fontWeight: 'bold',
            color: strengthColor,
          }}
        >
          {strength}
        </span>
      </small>
                     
                                 <Button variant='outline-light' type="submit" onSubmit={register}  disabled id='btnRegister' >register</Button>
                                 </div>
                         </Col>
                  </Row>
                  </Form>
        </Accordion.Body>
      </Accordion.Item>
        
    
    </Accordion>   
  
               
                
                
                </>
            )}
        </Container>
    
    );
}
export default GLogin;