import React from "react";
import Navigation from "./components/Navigation.js";
import Cookie from "./components/CookieConsent.js";
import {Outlet} from "react-router-dom";
import {useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import {loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
import Intro from "./intro.js"
import Footer from './components/Footer.js'


const App = () => {
    
    const [init, setInit] = useState(false);

  // this should be run only once per application lifetime
  
  useEffect(() => {

    initParticlesEngine(async (engine) => {

      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size

      //await loadAll(engine);
      //await loadFull(engine);
      //await loadBasic(engine);

      await loadSlim(engine);
           

    }).then(() => {

      setInit(true);   

    });

  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

   const options = useMemo(
    () => ({
      background: {
        color: {
          value: '#1d2224'
        },
        fullScreen: {
          enable: true
        }
      },
      fpsLimit: 240,
      interactivity: {
        events: {
          onClick: {
            enable: false,
            mode: "push",
          },
          onHover: {
            enable: false,
            mode: "repulse",
          },
        },
        modes: {
          push: {
            quantity: 8,
          },
          repulse: {
            distance: 200,
            duration: 0.3,
          },
        },
      },
      particles: {
        color: {
          value: "#ac1717b2",
        },
        links: {
          color: "#b3b3b3b2",
          distance: 150,
          enable: true,
          opacity: 0.7,
          width: 1,
          triangles: {
            enable: true,
            opacity: 0.1,
        },
        },
        move: {
          direction: "right",
          enable: true,
          outModes: {
            default: "out",
          },
          random: true,
          speed: 1,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 90,
        },
        opacity: {
          value: 0.3,
        },
        shape: {
          type: "polygon",
        },
        size: {
          value: { min: 1, max: 3 },
        },
      },
      detectRetina: true,
    }),
    [],
  ); 

    return (      
   
        <div>
           <div id='navbar'> 
                <Navigation/>
            
                <div id='content' >         
                    <Outlet /> 
                   
                </div>
                
            </div>
            <div id='footer'>         
         <Footer /> 
          </div> 
            <div id='particles'>
            <Particles id="tsparticles" particlesLoaded={particlesLoaded} options={options} />
            
         
          {/*   <div id="intro">           
                <Intro iterations="10" width="600" height="600" maxDepth="5" lineWidth="1" lineColor="red" />   
            </div>  */}
    </div>
    

          <div id='cookie'>         
         <Cookie /> 
          </div> 
     </div>
   );
}
export default App;
