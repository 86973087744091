//This component will deal with state blocks 
import Loading from '../Loading.js'
import Error from '../Error.js'

 export const StateHandler = (props) =>{
    const {
    showLoader = false,
    showError = false,
    showEmpty = false,
     } = props;
    
    if (showLoader) {
        return <Loading />;
    }
    if (showError) {
        return <Error/>
    }
    if (showEmpty) {
        return <Loading /> // 'No data available';
    }
    return props.children;
    }