import  { Component } from "react";
import axios from "axios";
import {Table} from 'react-bootstrap';

const datarow= (repo) => (
    <tr key={repo.id} 
    onClick={() => window.open(repo.html_url)}
    >
        <td>{repo.id}</td>
        <td>{repo.name}</td>
        <td>{repo.full_name}</td>
    </tr>
);


export default class GitHubRepos extends Component {
   
    constructor(props) {
        super(props);

        this.state = {
            repos:[],
            loading: true,
            error: null
        };
    }
   
    componentDidMount() {
     
        axios
            .get(
                    'https://api.github.com/users/kamokaze71/repos'
            )
            .then(response => {
              const  repos = response.data
                this.setState({
                    repos,
                    loading: false
                });
            })
            .catch(error => {
                this.setState({
                    error: error,
                    loading: false
                });
            });
    }

    renderLoading() {
        return <div>Loading...</div>;
    }

    renderError() {
        return (
            <div>
                <div>
                    Sorry, an error occurred: {this.state.error.response.data.message}
                </div>
            </div>
        );
    }

    renderList() {
        const { error, repos } = this.state;

        if (error) {
            console.log(error);
            return this.renderError();
        }

        return (
            <Table className="hidden table table-bordered table-striped table-hover" >
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Repo Name</th>
                    <th>Full Name</th>
                 
                </tr>
                </thead>
                <tbody>
              {repos.map((repo) => (datarow(repo)))}
                </tbody>
            </Table>
        );
    }

    render() {
        return this.state.loading ? this.renderLoading() : this.renderList();
    }
}
