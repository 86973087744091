import React, { useEffect, useState,useRef, useCallback } from "react";
import axios from 'axios';
import {ProgressBar,Row, Col, Form,FormLabel, Stack, InputGroup, Container, Button } from "react-bootstrap";
import {getFileUploadUrl,getFileUploadProgressUrl,generateGUID}  from "../Utils.js";
import {useAuthState} from "./context/AuthContext.js";
import BasicAlert from "./Alert.js";
import 'bootstrap/dist/css/bootstrap-grid.css';
import {useBeforeUnload} from "react-router-dom";
import { flushSync } from "react-dom";

export default function SpringUpload() {
   

  const [currentUpload,setCurrentUpload]=useState('');  
  const [uploads,setUploads]=useState(new Map([])); 
  const {user}= useAuthState();
  const refFile=useRef();
  const [abortController,setAbortController]=useState(new AbortController());
  const  abortRef = React.useRef();
  const  uploadsRef = React.useRef();
  
  const getUploads = useCallback((t)=> {
    setUploads(uploads)
    }, [uploads])
   
    
  async function newUpload (file,e) {
    

    let bodyFormData = new FormData();
    let guid=generateGUID();

    bodyFormData.append("userid",user.id)
    bodyFormData.append("file", file)
    bodyFormData.append("guid",guid )

    console.log('Posting to server:'+getFileUploadUrl+" : "+ guid + user.id) 
      
    abortRef.current=new AbortController();
    uploads.set(guid,{status:'running', progress:'0',message:'', 'ref': abortRef})   
    getUploads(uploads);
    uploadsRef.current=uploads;
    console.log('Posting to server:'+uploads.size+'  '+getFileUploadUrl+" : "+ guid + user.id) 

      axios.post(getFileUploadUrl, bodyFormData,
        {
        signal:abortRef.current.signal
     })
     .then(response =>
       console.log("Axios response data:"+ response.data)
         )
     .catch(error => {
      console.log(error);
      uploads.set(guid,{status:'error',progress:'0',message:error});
       })
       console.log('post done'); 
  }

  useBeforeUnload(
    React.useCallback(()=>{
      localStorage.setItem('uploads', JSON.stringify(uploads))
      console.debug('onbefore unload'+ JSON.stringify(uploads))
    },[uploads])
  )

  
 useEffect(() => {
  const eventSource =new EventSource(getFileUploadProgressUrl);
        eventSource.onmessage=(event)=> {
         
          const result = JSON.parse(event.data);
            //const cuploads = uploadsRef.current
              let percent = result.split(':')[1];
              let tguid = result.split(':')[0];

              console.log(tguid+ " : " + percent)
            const myref=uploads.get(tguid).ref
            // uploads.set(tguid,percent);
             uploads.set(tguid,{status:'running', progress:percent,message:'', 'ref': myref})
 
           if (percent==100)
           {
            uploads.set(tguid,{status:'finished', progress:percent, message:'', 'ref': myref})  
           }
 getUploads(uploads);
                        
           setUploads(new Map(uploads.set(tguid,{status:'running', progress:percent,message:'', 'ref': myref})));
        }
         eventSource.onerror = (event) => {
              if (event.target.readyState === EventSource.CLOSED) {
                console.log("SSE closed (" + event.target.readyState + ")");
              }
              console.log("Ebentsource error");
                   eventSource.close();
        };
        eventSource.onopen = () => {
          console.log("connection opened");
        };   

  }, [uploads]);
 
  const handleSelecteFile = (event) => {
     event.preventDefault()
   
    console.log('starte async upload ');
    newUpload(event.target.files[0],event)
    console.log('upload ferig upload ');
  };
  
  function newAbortSignal(timeoutMs) {
    const abortController = new AbortController();
    setTimeout(() => abortController.abort(), timeoutMs || 0);
  
    return abortController.signal;
  }
  const handleCancel = (guid) => 
  {
    
    //uploads.get(guid).ref.abortRef.current.abort()
  
  
    

  };
  const handleDelete = (guid) => 
  {
    
  };
  
  let array2=[]
  if(uploads.size>0){
   array2 = Array.from(uploads.keys());
  }
  return (
   
     <>
    <div className='row align-items-start g-5'>
    <div className="col align-items-start">
       
          <FormLabel htmlFor="file" className='form-control'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-plus-square" viewBox="0 0 16 16">
              <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
          </svg> 
          add new File
         
    <div>
        <input ref={refFile}  type="file" name={'file_'} id={'file_'} onChange={handleSelecteFile}  />
    </div>
          </FormLabel>
          </div>
    
    </div>
    { 
    array2.map((key)=>(
   
     <div key={key} className="row align-items-center g-2">
          <div className="col" width='80%'>
            <div>
           
                {/* <div style={{postition:'relative', display:'inline-block', left:'5',zIndex:'101'}}> */}
              <ProgressBar striped animated  id={'progressBar_'+ key}   now={uploads.get(key).progress} wdth='100%' />
              {/* </div>  */}
              <FormLabel id={'statusLabel_'+ key} className="form_control">{uploads.get(key).progress}/ 100%</FormLabel>
              </div>
          </div>
       <div className="col shrink align-items-end">

       {uploads.get(key).progress=='running' ? (
    <Button id={'btnDelete'+ key} onClick={handleDelete.bind(this,key)}>delete file </Button>
  ) : (
    <Button id={'btnCancel'+ key} onClick={handleCancel.bind(this,key)}>cancel upload</Button>
   
  )}
            
       </div>
     </div>
   
     ))}</>)} 
          
/* const getUploads = useCallback(()=> {
setUploads(uploads)
}, [uploads])
console.log(getFileUploadProgressUrl)
  if((uploads.size===0)&&(localStorage.getItem('uploads')==='[object Map]'))
    {
      {setUploads(new Map())}
    }


*/
