import {useState,useEffect} from 'react';
import {Button} from 'react-bootstrap';
import {Offcanvas} from 'react-bootstrap';
import {useAuthState,useAuthDispatch, removeTrack}  from "./context/AuthContext.js";
import {ListGroup} from 'react-bootstrap';
import {Easing, Tween, Group,update } from "@tweenjs/tween.js";
import {useMap} from '@vis.gl/react-google-maps';

export default function Tracklist(props) {
  
  const {user}= useAuthState();
  const dispatch = useAuthDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const tweenGroup = new Group()
  let map=useMap();
 
  useEffect(()=>
  {
        
        const centerControlDiv = document.createElement('div');
        // Create the control.
        const controlButton = document.createElement('button');
        controlButton.className='btn btn-danger'
        controlButton.addEventListener('click',handleShow)
        controlButton.textContent = 'Tracks';
        controlButton.title = 'Click to show my tracks';
        controlButton.type = 'button';
        centerControlDiv.appendChild(controlButton);
       
         map.controls[google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv);
       
      
      
  },[])
  //const overlay = useMemo(() => new DeckOverlay(props));

const deleteTrack=(trackid)=>{

  const idx= user.tbltracks.findIndex(track => track.id === trackid);
   user.tbltracks.splice(idx,1);
   dispatch({ 
    status: "resolved" ,
    user:user,
    error:null 
            });
  } 
// Setup the click event listeners: simply set the map to Chicago.
const animateOnClick = (latlng) => {
  //console.log('animate onClick:'+ destination)
  //let test= new google.maps.LatLng(ev.currentTarget.dataset.lat,ev.currentTarget.dataset.lng)
  const cameraOptions = {
    tilt: 0,
    heading: 0,
    zoom: 13,
    center: latlng }

  const tw = new Tween(cameraOptions)

   // Create a new tween that modifies 'cameraOptions'.
 tw.to({ tilt: 65, heading: 90, zoom: 18 }, 15000) // Move to destination in 15 second.
tw.easing(Easing.Quadratic.Out) // Use an easing function to make the animation smooth.

 tw.onUpdate(() => {
  console.log('onupdate:'+ cameraOptions.center.lat )
  map.moveCamera(cameraOptions);
  
 }
)
tweenGroup.add(tw);


//tw.start()
map.moveCamera(cameraOptions);
//map.panTo(cameraOptions);
//requestAnimationFrame(animate());
 
}

function animate(time) {
  console.log('animate frame:'+ time)
  tweenGroup.update();
  requestAnimationFrame(animate(time));
  
 
  
  
}



  return (
    <>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>  
    <ListGroup>
   
      {user.tbltracks.map((track) => (
    
        <ListGroup.Item key={track.id} data-lat={parseFloat(track.tblgps[0].lat)} data-lng={parseFloat(track.tblgps[0].lng)} action onClick={()=>animateOnClick(new google.maps.LatLng(parseFloat(track.tblgps[0].lat),parseFloat(track.tblgps[0].lng)))}> {track.name}  
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16"onClick={() =>deleteTrack(track.id)}>
        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
      </svg>

        </ListGroup.Item>
      ))}
     
  
    </ListGroup>


        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
