import {Container,Table,Row,Col,Spinner} from 'react-bootstrap'

export default function Loading() {
    
  return (
   <Container>
    <Row><Col>
      <h1>Loading...</h1>
      <p>Bitte warten Sie, während die Anwendung geladen wird.</p>
      <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
      Loading..
      </Col></Row>
    </Container>
  );
}
