
import {Container} from 'react-bootstrap';
import {Row} from 'react-bootstrap';
import {Col} from 'react-bootstrap';
//import { Suspense } from 'react';
//import Loading from '../Loading.js'

export default function Error() {
     
    return (
     <Container>
          <Row>
            <Col> 
          <h2>Error</h2>  
          </Col>
          </Row>
      </Container>
    );
  }