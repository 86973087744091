import {Nav, Navbar,Image} from "react-bootstrap";
import {Link,NavLink } from "react-router-dom";
import {Container} from 'react-bootstrap';

function Footer() {
    return (
    
 
      <Navbar  sticky="bottom" id='footernav'data-bs-theme="dark" bg="dark" >
   
       <Nav className="navbar navbar-default" >
       <Container fluid>
        <Nav.Item>
            <Nav.Link to="/dsvgo"as={Link}>DSVGO</Nav.Link>
        </Nav.Item>
        <Nav.Item>
        <Nav.Link to="/" as={Link}>Home</Nav.Link>
        </Nav.Item>
        <div className="float-end">© Lazycodelabs.com 2024</div>
        </Container>
       </Nav>
   
       
    
    </Navbar>
  


    );
  }
  export default Footer;