import {useAuthState} from './context/AuthContext.js'
import {useState,useEffect} from 'react';
import {StateHandler} from './StateHandler.js'
//import { Row, Col} from "react-bootstrap";
import {Table} from 'react-bootstrap'
//import GoogleMaps from './GoogleMaps.js'
import {
  MapContainer,
  TileLayer,
  useMap,
  Circle,
  CircleMarker,
  Polyline,
  Rectangle,
  Polygon,
  Popup
}from 'react-leaflet'
  //} from 'https://cdn.esm.sh/react-leaflet'



const datarow= (track) => (
 
    <tr key={track.id}>
        <td >{track.descr}</td>
        <td>{track.name}</td>
        <td><MapContainer center={ [51.505, -0.09]} zoom={13} scrollWheelZoom={false}>
    <TileLayer
      attribution='<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
 {/*    <Circle center={center} pathOptions={fillBlueOptions} radius={200} />
    <CircleMarker center={[51.51, -0.12]} pathOptions={redOptions} radius={20}>
      <Popup>Popup in CircleMarker</Popup>
    </CircleMarker>
    <Polyline pathOptions={limeOptions} positions={polyline} />
    <Polyline pathOptions={limeOptions} positions={multiPolyline} />
    <Polygon pathOptions={purpleOptions} positions={polygon} />
    <Polygon pathOptions={purpleOptions} positions={multiPolygon} />
    <Rectangle bounds={rectangle} pathOptions={blackOptions} /> */}
  </MapContainer></td>
         {/* <td><GoogleMaps track={track.google_encoded_polyline}/></td>  */}
    </tr>
  );
  
export default function UserData() {
 

  const polyline = [
    [51.505, -0.09],
    [51.51, -0.1],
    [51.51, -0.12],
  ]
  
  const multiPolyline = [
    [
      [51.5, -0.1],
      [51.5, -0.12],
      [51.52, -0.12],
    ],
    [
      [51.5, -0.05],
      [51.5, -0.06],
      [51.52, -0.06],
    ],
  ]
  
  const polygon = [
    [51.515, -0.09],
    [51.52, -0.1],
    [51.52, -0.12],
  ]
  
  const multiPolygon = [
    [
      [51.51, -0.12],
      [51.51, -0.13],
      [51.53, -0.13],
    ],
    [
      [51.51, -0.05],
      [51.51, -0.07],
      [51.53, -0.07],
    ],
  ]
  
  const rectangle = [
    [51.49, -0.08],
    [51.5, -0.06],
  ]
  const fillBlueOptions = { fillColor: 'blue' }
  const blackOptions = { color: 'black' }
  const limeOptions = { color: 'lime' }
  const purpleOptions = { color: 'purple' }
  const redOptions = { color: 'red' }

  const user = useAuthState();

    const [state, setState] = useState({
    loading: true,
    error: null
    });
     useEffect(()=>{
        try {
      
                setState({
                    loading: false,
                    error:null
                    })
            } catch (error) {
             
                setState({
                    loading: false,
                    error:error
                    })
            }
     },[]);
 
    return( 
        <>
        <StateHandler showError={state.error} showLoader={state.loading} showEmpty={!user.user.loginid}>
            <Table striped bordered hover >
               <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Decrt</th>
                    <th>Track</th>
                  </tr>
                </thead>
                <tbody>
                {user.user.loginid && user.user.tbltracks.map(track =>datarow(track))}
            </tbody>
            </Table >
        </StateHandler>
        </>
  /*  
    <div><h3>Display users:</h3>
         {user.user.tbltracks.map(track =>datarow(track))}
    </div>
    
     */
    );
    }