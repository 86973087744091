// About.js
import React, {Component} from 'react';
import RepoCards from './GithubRepos.js';
import {Container} from 'react-bootstrap';
import {Row} from 'react-bootstrap';
import {Col} from 'react-bootstrap';
import Loading from '../Loading.js'

class About extends Component {
    render() {
        return (
     
            <Container>
              <Row>
                <Col> 
             
                <h2>About</h2>
             
        
              </Col>
          </Row>
          
         <Row>
            <Col>             
                       <a href="https://stackexchange.com/users/1867600"><img src="https://stackexchange.com/users/flair/1867600.png" alt="profile for kamokaze on Stack Exchange, a network of free, community-driven Q&amp;A sites" title="profile for kamokaze on Stack Exchange, a network of free, community-driven Q&amp;A sites"/></a>
              
            

                       </Col>
          </Row>
          <Row>
            <Col>
                 <RepoCards></RepoCards>         
          </Col>
          </Row>
        </Container>
    
        );
    }
}

export default About;