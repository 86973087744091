

const LOCAL_DOMAINS = ["localhost", "127.0.0.1"];
const getServerUrl_DEV="http://localhost:8080/";
const getServerUrl_PROD="https://lazycodelabs.com/";

export const getRestAPiUrl=getServerUrl() +"rest/"

export const getFileUploadUrl = getRestAPiUrl+"upload/singleFileTest/";
export const getFileUploadProgressUrl = getRestAPiUrl+"upload/progress/";

export const getMultipleFileUploadUrl = getRestAPiUrl +"uploadM";
export const  getMultipleGPXFileUploadUrl = getRestAPiUrl + "uploadMGPX";
export const getMailuploadUrl = getRestAPiUrl +"upload/mailupload/";

export const getShellCommandSSE=getRestAPiUrl+"shell/commandProgress/";
                        
export const googleClientID ="752286971079-s7q8jgrp1537m4atrn193v7loo5rh9kq.apps.googleusercontent.com"



 function isProduction ()
{ if (LOCAL_DOMAINS.includes(window.location.hostname))
    return false
  else return true }

function getServerUrl()
{ if (isProduction())
  return getServerUrl_PROD
 else
  return getServerUrl_DEV
}

function isPostBack() { //function to check if page is a postback-ed one
    return document.getElementById('_ispostback').value == 'True';
  }

  export function generateGUID() {
    
    let guid= "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
     
    );
   return guid;
  }
  
 